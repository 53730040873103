import React  from 'react';
import {NewApplicantLogin} from "@Features/client/login/components/new-applicant";
import {SignIn} from "@Features/client/login/components/sign-in";



export const LoginIndex  = (props: any) => {




    return (
        <>
        <div className='content-row'>
           <NewApplicantLogin/>
            <SignIn/>
            </div>
        </>
    )
}
