import ApiService from "@Src/services/bookstore/ApiService";
import { LOGIN_CONSTANT } from "./login.constant";



export const Login = async(data:any) => {
  return await ApiService.fetchData({
    url:LOGIN_CONSTANT.LOGIN,
    method:'post',
    data
  })
}