import React, { useState, useEffect } from "react";
import "./login.scss"
import { useFormik } from "formik";
import { AdminLoginInitialform, AdminLoginSchema } from "../schema/adminlogin.schema";
import { Login } from "@Src/features/client/login/service/login.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import { toast } from "react-hot-toast";
import { InputText } from "primereact/inputtext";
import CrossEyeSvg from '@Src/assets/svg/commonsvg/crosseye.svg';
import EyeSvg from '@Src/assets/svg/commonsvg/eye.svg';
import { useAppDispatch } from "@Src/app/hooks/hooks";
import { setUserUID } from "@Src/app/reducers/auth/registerslice";
import { setEmailid, setFirstName, setLastname, setToken, setisAdmin } from "@Src/app/reducers/auth/loginslice";
import { API_STATUS_MSG_CONSTANT, ApiStatusMessage, setDataOnLocalStorage } from "@Src/utils/globalUtilities";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import { useNavigate } from "react-router-dom";
import { hideSpinner, showSpinner } from "@Src/app/reducers/global/globalSlice";


export const AdminLogin = () => {
  const [showpass, setShowpass] = useState(true);
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
    initialValues: AdminLoginInitialform,
    validationSchema: AdminLoginSchema,
    onSubmit: (e) => {
      AdminloginSubmit(e)
    },
  });

  const AdminloginSubmit = (e: any) => {
    let payload = {
      "UID": e.UID,
      "password": e.password
    }
    Login(payload).then((resp: APIResponse) => {
      dispatch(showSpinner())
      if (resp.ResponseType === APIStatus.SUCCESS) {
        dispatch(hideSpinner())
        if (resp.data[0].IsAdmin === 1) {
          dispatch(setUserUID(resp.data[0].UID))
          dispatch(setToken(resp.data[0].token))
          dispatch(setFirstName(resp.data[0].Fname))
          dispatch(setLastname(resp.data[0].Lname))
          dispatch(setEmailid(resp.data[0].EmailId))
          dispatch(setisAdmin(resp.data[0].IsAdmin))
          setDataOnLocalStorage('authorization' , resp.data[0].token)
          navigate(`/${AppRouteURL.SUBMISSION_DETAILS}`)
          
        }
        if(resp.data[0].IsAdmin === 0){
          dispatch(hideSpinner())
          toast.error('UnAthorised User')
        }
      }else if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.INCORRECT_PASSWORD){
        dispatch(hideSpinner())
        toast.error(ApiStatusMessage.INCORRECT_PASSWORD)

      }else{
        dispatch(hideSpinner())
      }
    }).catch((error) => {
      dispatch(hideSpinner())
      console.log(error)
    })

  }

  return (
    <div>
      <div className='content-auto'>
        <div className='content-heading-1 pb-3'>
          Chairman Login
        </div>
        <div className='content-form'>
          <form onSubmit={handleSubmit}>
            <div className='group-form col-row'>
              <div className='group-form-row'>
                <div className='group-form-colls'>
                  <label className='form-label-1'> UID No. </label>
                  <div>
                    <InputText
                      value={values.UID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='UID' className='form-control-t1'
                      placeholder='Please enter your UID Number' />
                  </div>
                </div>
              </div>
              <div className='group-form-row'>
                <div className='group-form-colls'>
                  <label className='form-label-1'> Password </label>
                  <div className='form-fixed-buttons'>
                    <div className='form-fixed-buttons'>
                      <InputText
                        type={showpass ? 'password' : 'text'}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='password' className='form-control-t1'
                        placeholder='Please enter your Password' />
                      <span className='form-fixed-colls' onClick={() => setShowpass(!showpass)}>
                        {showpass && <a href='javascript:void(0)' className='form-fixed-links'>
                          <img src={CrossEyeSvg} alt='CrossEyeSvg' />
                        </a>}
                        {!showpass && <a href='javascript:void(0)' className='form-fixed-links'>
                          <img src={EyeSvg} alt='EyeSvg' />
                        </a>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>


              <div className='group-form-row'>
                <div className='group-form-footer flex align-items-center mt-4'>
                  <button className="button-primary active" type="submit">Sign In <i className="pi pi-angle-right ml-1"></i> </button>

                </div>
              </div>
            </div>
          </form>

        </div>

      </div>
    </div>
  );
};
