export interface APIResponse {
    ResponseType: string
    StatusMsg: string
    data: any
    success: boolean
}

export const APIStatus  = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    DATA_NOT_FOUND: 'DATA_NOT_FOUND',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    PAYMENT_ERROR: 'PAYMENT_ERROR'
}

export enum APIEnv {
    NODE_BOOKSTORE,
    NODE_DASHBOARD,
    DOT_NET
}