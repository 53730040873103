// import toast from "react-hot-toast";
import {APIEnv, APIResponse} from "@Services/bookstore/baseInterfaces";
import {NodeBaseService} from "@Src/services/bookstore/base-services/NodeBaseService";


const ApiService = {
    fetchData(param:any) {
        return new Promise<APIResponse>((resolve, reject) => {
            NodeBaseService(param).then(response => {
                resolve(response?.data)
            }).catch(errors => {
                reject(errors)
            })
        })
    },
}

export default ApiService