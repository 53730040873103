import React, { useState, useEffect } from "react";
import {AdminLogin} from "@Features/admin/login/components/login";

export const AdminLoginIndex = () => {


    return (
        <div>
            <AdminLogin/>
        </div>
    );
};
