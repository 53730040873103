import { useState } from "react";
import { UpdatepasswordInitialform, UpdatepasswordSchema } from "../../schema/resetpassword.schema";
import { InputText } from "primereact/inputtext";
import CrossEyeSvg from '@Src/assets/svg/commonsvg/crosseye.svg';
import EyeSvg from '@Src/assets/svg/commonsvg/eye.svg';
import RightCarrotSvg from "@Src/assets/svg/commonsvg/rightcarrot.svg";
import { useFormik } from 'formik';
import { updatepassword } from "../../service/resetpassword.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import { ApiStatusMessage , API_STATUS_MSG_CONSTANT} from "@Src/utils/globalUtilities";
import { useAppDispatch } from "@Src/app/hooks/hooks";
import { hideSpinner, showSpinner } from "@Src/app/reducers/global/globalSlice";
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"


export const UpdatePasswordIndex = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showpass, setShowpass] = useState(true);
  const [showpassUpdatedStaus, setShowpassUpdatedStaus] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('emailId');

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
    initialValues: UpdatepasswordInitialform,
    validationSchema: UpdatepasswordSchema,
    onSubmit: (e: any) => {
      console.log(e)
      updatepasswordtoapi(e)
    }
  })

  function updatepasswordtoapi(e: any) {
    dispatch(showSpinner())
    let payload = {
      "login_token": paramValue,
      "password": e.Password
    }

    updatepassword(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setShowpassUpdatedStaus(true)
        dispatch(hideSpinner())
        if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.PASSWORD_CHANGED_SUCCESFULLY){
          dispatch(hideSpinner())
          toast.success(ApiStatusMessage.PASSWORD_CHANGED_SUCCESFULLY)
        }
       
      } else if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.SUPPLIED_LOGIN_TOKEN_INVALID){
        dispatch(hideSpinner())
        toast.error(ApiStatusMessage.SUPPLIED_LOGIN_TOKEN_INVALID)
      }
      else if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.LOGIN_TOKEN_NOT_RECEIVED){
        dispatch(hideSpinner())
        toast.error(ApiStatusMessage.LOGIN_TOKEN_NOT_RECEIVED)
      }
      else if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.PASSWORD_NOT_RECEIVED){
        dispatch(hideSpinner())
        toast.error(ApiStatusMessage.PASSWORD_NOT_RECEIVED)
      }
      else if(resp.StatusMsg == API_STATUS_MSG_CONSTANT.USER_NOT_FOUND){
        dispatch(hideSpinner())
        toast.error(ApiStatusMessage.USER_NOT_FOUND)
      }else{
        dispatch(hideSpinner())
      }
    }).catch((error) => {
      dispatch(hideSpinner())
      console.log(error)
    })

  }


  return (
    <>
      {!showpassUpdatedStaus?<div className='content-cols new-sign-in-cols md:tw-w-1/2 tw-m-auto'>
        <div className='content-heading-1 pb-3'>
        Update Password
        </div>
        <div className='content-form'>
          <form onSubmit={handleSubmit}>
            <div className='group-form col-row'>

              <div className='group-form-row'>
                <div className='group-form-colls'>
                  <label className='form-label-1'> Password<span className='tw-text-red-500'>*</span> </label>
                  <div className='form-fixed-buttons'>
                    <InputText
                      type={showpass ? 'password' : 'text'}
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='Password' className='form-control-t1'
                      placeholder='Please enter new password' />
                    <span className='form-fixed-colls' onClick={() => setShowpass(!showpass)}>
                      {showpass && <a href='javascript:void(0)' className='form-fixed-links'>
                        <img src={CrossEyeSvg} alt='CrossEyeSvg' />
                      </a>}
                      {!showpass && <a href='javascript:void(0)' className='form-fixed-links'>
                        <img src={EyeSvg} alt='EyeSvg' />
                      </a>}
                    </span>
                  </div>
                  {(errors.Password && touched.Password) ?
                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Password}</p> : null}
                </div>
              </div>
              <div className='group-form-row'>
                <div className='group-form-colls'>
                  <label className='form-label-1'> Confirm Password<span className='tw-text-red-500'>*</span>. </label>
                  <div>
                    <InputText
                      value={values.conformpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='conformpassword' className='form-control-t1'
                      placeholder='Please enter new password again' />
                    {(errors.conformpassword && touched.conformpassword) ?
                      <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.conformpassword}</p> : null}
                  </div>
                </div>
              </div>


              <div className='group-form-row'>
                <div className='group-form-footer flex align-items-center mt-4'>
                  <button className="button-primary active" type='submit' >Reset Password
                    <img className='tw-ml-2' src={RightCarrotSvg} alt='RightCarrotSvg' />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>:
      <div className="empty-data">
                <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
        <div className="empty-data-details">
          <h2 className="empty-data-h1"> Password has been updated</h2>
          <div> <button className="button-primary active" onClick={() => navigate(AppRouteURL.LOGIN)}> Continue to Login <i className="pi pi-angle-right ml-1"></i> </button> </div>
        </div>
      </div>}


    </>
  )
}




