import React, { useEffect, useRef, useState } from 'react'
import 'primeicons/primeicons.css';
import "./index.scss"
import { useAppDispatch, useAppSelector } from '@Src/app/hooks/hooks';
import { hideSpinner, setPaymentStatus, showSpinner } from '@Src/app/reducers/global/globalSlice';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { postProductOrder } from '../services/client.service';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import toast from 'react-hot-toast';

export const PaymentStatusIndex = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const tempOrderDetails:any = useAppSelector(state => state.global.tempOrderDetails)
    const [timesRun, setTimesRun]  = useState<number>(0);
    const interval:any = useRef();

    useEffect(() => {
        checkPaymentStatus();
    }, [])

    function checkPaymentStatus() {
        dispatch(showSpinner());
        const payload = {
            "OrderNo": tempOrderDetails?.merchantTransactionId,
           // "TransactionId": tempOrderDetails?.transactionId,
            "PaymentMode": 1,
            "IsPayment": 1
        }
        postProductOrder(payload).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                dispatch(setPaymentStatus(1));
                navigate(AppRouteURL.FEE_SUBMISSION)

            }else{
                if(resp.StatusMsg == APIStatus.PAYMENT_PENDING){
                    // dispatch(setPaymentStatus(0));
                    // toast.error("Payment Pending")
                    // navigate(AppRouteURL.FEE_SUBMISSION) 
                     interval.current =  setTimeout(() => {
                        let times:number  = timesRun +1;
                        setTimesRun(times);
                        if(timesRun == 90){                    
                        clearTimeout(interval.current);
                           dispatch(setPaymentStatus(0));
                           toast.error("Payment Pending")
                           navigate(AppRouteURL.FEE_SUBMISSION) 
                        }else{
                            checkPaymentStatus();
                        }
                    }, 10000);   
                }else if(resp.StatusMsg == APIStatus.PAYMENT_ERROR){
                    dispatch(hideSpinner())
                    dispatch(setPaymentStatus(2));
                    toast.error("Payment Failed")
                    navigate(AppRouteURL.FEE_SUBMISSION)    
                }else{
                    dispatch(hideSpinner())
                dispatch(setPaymentStatus(2));
                toast.error("Payment Failed")
                navigate(AppRouteURL.FEE_SUBMISSION)
                }
            }
        }).catch(error => {
            dispatch(hideSpinner())
            console.log(error)
            toast.error("Payment Faled")
            dispatch(setPaymentStatus(2));
            navigate(AppRouteURL.FEE_SUBMISSION)
        })

    }

    return (
        <>
                    <div className="empty-data">
                <div className="empty-data-details">
                    <h2 className="empty-data-h1"> Checking Your Payment Status....... </h2>
                    <p className="empty-data-text-2"> Please wait while updating your payment status.</p>
                </div>
            </div>
        </>
    )
}