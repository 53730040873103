
export const Salutationenum = [
    { name: 'Mr.', id: 1 },
    { name: 'Miss.', id: 2 },
    { name: 'Mrs.', id: 3 },
    { name: 'Dr.', id: 4 },
    { name: 'Prof.', id: 5 },
    { name: 'Senior Prof.', id: 6 },
  ];

 export const Genderenum = [
    { name: 'Male', id: 1 },
    { name: 'Female', id: 2 },
    { name: 'Other', id: 3 },
]