import { useAppSelector } from "@Src/app/hooks/hooks";
import { useEffect, useRef } from "react"
import { Toast } from 'primereact/toast';
import { offlineFeePaymentStatusEnum } from "@Src/app/constants/app.constant";



export const PaperSubmissionToastIndex = () => {
    const toaster: any = useRef(null);
    const payment = useAppSelector(state => state.global.paymentstatus)
    const offlineFeePaymentStatus = useAppSelector(state => state.global.offlineFeePaymentStatus)

   
    useEffect(() => {
        if(!(payment == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED )){
        setTimeout(() => {
            showSuccess()
        }, 100)
    }
    }, [payment])

    const showSuccess = () => {
        toaster.current.show({ severity: 'info', summary: 'Message', detail: 'Please submit your Fees to Proceed.', life: 3000 });
    }



    return (
        <>
        <Toast ref={toaster} position="top-center" />
        </>
    )
}