export const VALIDATION_REGEX_PATTERN = {
  mobileNumber: /^[1-9]{1}[0-9]{9}$/,
  emailPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  mobile: /^[1-9]{1}[0-9]*$/,
  alphabet: /^[a-zA-Z ]*$/,
  linkedin_profile_url:
    /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
  url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  floatNumbers: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
  validMarks: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
  names: /^[a-zA-Z .]*$/,
};

export enum HeightAdjustElementType {
  HTML_ELEMENT = 1,
  HTML_ID,
  HTML_CLASS,
}

export enum Currency {
  INR = "INR",
  USD = "USD",
}
export enum PaymentModes {
  RAZORPAY = 1,
  CHEQUE_DD,
  NEFT,
  COD,
  WALLET_NO_PAY,
  SHOPSE,
  RAZORPAY_RECURRING,
}