import ApiService from "@Src/services/bookstore/ApiService";
import { TRAVEL_DETAILS_CONSTANT } from "./traveldetails.constant";




export const traveldetails = async (data:any) => {
    return await ApiService.fetchData({
        url:TRAVEL_DETAILS_CONSTANT.TRAVEL_DETAILS,
        method:'post',
        data
    })
}