import { ValidFields } from '@Src/utils/messages/validation.contant';
import { globalRegex } from '@Src/utils/regex/regex.constant';
import * as Yup from 'yup';


export const RegisterSchema = Yup.object({
    date:Yup.string().required('Select arrival Date'),


})

export const TravelDetailsInitialform = {
    arrivalDetails: {
        place: '',
        date: '',
        terminal: '',
        travelType: '',
        travelTypeNumber: '',
        time: ''
    },
    diparturDetails: {
        place: '',
        date: '',
        terminal: '',
        travelType: '',
        travelTypeNumber: '',
        time: ''
    }
}