import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import "./list.scss";
import { adminpaperDetails, adminPaperDownload, adminPaperRemark, getPayemntproofAPi, offlinePaymentChangeStatus, offlinePaymentDetails, offlinepaymentdetailscontpost } from "../service/submission.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { offlineFeePaymentStatusdropdown, paperDetailsStatus, paperStatusdropdown } from "../service/submission.constant";
import DownloadSvg from "@Src/assets/svg/commonsvg/download.svg"
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-hot-toast";
import RightCarrotSvg from '@Src/assets/svg/commonsvg/rightcarrot.svg'
import { useNavigate } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import { useAppDispatch, useAppSelector } from "@Src/app/hooks/hooks";
import { hideSpinner, setTableDropdownpaperStatus, showSpinner } from "@Src/app/reducers/global/globalSlice";
import { store } from "@Src/app/store";
import RigthArrowSvg from "@Src/assets/svg/commonsvg/rightarrow.svg"
import { InputText } from "primereact/inputtext";

export const SubmissionList = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);
  const [adminPaperDetails, setAdminPaperDetails] = useState<any>();
  const [addcommenttextarea, setAddcommenttextarea] = useState<any>();
  const [commentRowData, setCommentRowData] = useState<any>();
  const [dropdownstatusvaleu, setDropdownstatusvaleu] = useState<any>();
  const tabledropdownstatus = useAppSelector((state) => state.global.tabledropdownpaperStatus)
  const [searchuser, setSearchUser] = useState('');
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offlinepayemntreportcountUID, setofflinepayemntreportcountUID] = useState();
  const dispatch = useAppDispatch()

  useEffect(() => {
    getOfflinePaymentDetails()
    postofflinepaymentdetailscount()
  }, [])

  useEffect(() => {
    getOfflinePaymentDetails()
    postofflinepaymentdetailscount()
  }, [searchuser , currentPage, rows,])

  function getOfflinePaymentDetails() {
    dispatch(showSpinner());
    let payload = {
      filters: {
        "emailOrUsername":  searchuser.trim()
      },
      pageNo: currentPage,
      pageSize: rows
    }
    offlinePaymentDetails(payload).then((resp: APIResponse) => {
      dispatch(hideSpinner());
      if (resp.ResponseType === APIStatus.SUCCESS) {
        resp.data;
        setAdminPaperDetails(resp.data)
      }
    }).catch((error: any) => {
      dispatch(hideSpinner());
      console.log(error)
    })
  }

  function postofflinepaymentdetailscount() {

    let payload = {
      "filters": {
        "emailOrUsername":  searchuser.trim()
      },
      pageNo: currentPage,
      pageSize: rows
    }
    offlinepaymentdetailscontpost(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setTotalRecords1(resp?.data?.countData)
        setofflinepayemntreportcountUID(resp?.data?.countUID)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  /*-------------------------start-data-table-body-template-------------------*/

  function changeStoreValue(value: any, index: any, rowData: any) {
    // let storedata = store.getState().global.tabledropdownpaperStatus

    // let storeupdateStatus: Array<any> = storedata.map(item => {
    //   return Object.assign({}, item);
    // });

    // if (storeupdateStatus?.length > 0) {
    //   storeupdateStatus[index]['status'] = value.Name
    // }
    // dispatch(setTableDropdownpaperStatus(storeupdateStatus))
    offlinePayStatusChange(rowData, value)

  }
  function statusBodyTemplate(rowData: any, rowDetails: any) {
    return (
      <>
        <Dropdown
          value={rowData.IsValid}
          name="PaperStatus"
          onChange={(inputEvent: any) => {
            changeStoreValue(inputEvent?.target?.value, rowDetails?.rowIndex, rowData)
          }}
          options={offlineFeePaymentStatusdropdown}
          optionLabel="label"
          optionValue="value"
          className='md:tw-w-44'
          placeholder="Select Status"
          disabled={rowData.IsValid != 0}
        />

      </>
    )
  }

  function offlinePayStatusChange(value: any, status: any) {
    dispatch(showSpinner())
    let data = {
      "uid": value.UID,
      "status": status
    }
    offlinePaymentChangeStatus(data).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        dispatch(hideSpinner())
        // setDropdownstatusvaleu('')
        getOfflinePaymentDetails()
        toast.success('Payment Status Submited Successfully')
      } else {
        dispatch(hideSpinner())
      }
    }).catch(error => {
      console.log(error)
      dispatch(hideSpinner())
    })

  }
  function NameBodyTemplate(rowData: any) {
    return (
      <>
        {rowData?.Salutation == 1 && 'Mr. '}
        {rowData?.Salutation == 2 && 'Miss. '}
        {rowData?.Salutation == 3 && 'Mrs. '}
        {rowData?.Salutation == 4 && 'Dr. '}
        {rowData?.Salutation == 5 && 'Prof. '}
        {rowData?.Salutation == 6 && 'Senior Prof. '}
        {rowData?.UserName}

      </>
    )
  }


  function paymentProofBodyTemplate(rowData: any) {
    return (
      <span style={{ cursor: 'pointer' }} onClick={() => downloadPaymentProof(rowData)}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2118_5236)">
            <path d="M10.8333 8.33333H15L9.99996 13.3333L4.99996 8.33333H9.16663V2.5H10.8333V8.33333ZM3.33329 15.8333H16.6666V10H18.3333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V10H3.33329V15.8333Z" fill="#1C7CED" />
          </g>
          <defs>
            <clipPath id="clip0_2118_5236">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </span>
    )
  }


  function downloadPaymentProof(data: any) {

    if (data) {
      let payload = {
        "url": data?.PaymentProof
      }
      getPayemntproofAPi(payload).then((response: APIResponse) => {
        if (response.ResponseType == APIStatus.SUCCESS) {
          if (response?.data.startsWith('/9j/')) {
            const linkSource = `data:image/jpeg;base64,${response?.data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Payment_Proof_${data?.UID}`;
            downloadLink.click();
            downloadLink.remove();

          }

          // Check if data starts with the PNG magic number
          if (response?.data.startsWith('iVBORw0KGgo')) {
            const linkSource = `data:image/png;base64,${response?.data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Payment_Proof_${data?.UID}`;
            downloadLink.click();
            downloadLink.remove();
          }

        }
      })
    }
  }

  const onPageInputChange = (event: any) => {
    if (event) {
      setFirst(event.first);
      setRows(event.rows);
      setCurrentPage(event.page + 1);
    }
  };






  return (
    <div>
      <div className="tw-flex tw-items-center tw-mb-8">
        <img className="tw-mr-1 tw-cursor-pointer" onClick={() => navigate(`/${AppRouteURL.SUBMISSION_DETAILS}`)} src={RigthArrowSvg} alt="RigthArrowSvg" />
        <div className='page-header-1'>
          <h2 className='master-head'>
            Offline Fee Pay Report
          </h2>
        </div>
        <div >
        </div>
      </div>

      <div className="submis-search-card">
        <InputText
          value={searchuser}
          onChange={(e) => setSearchUser(e.target.value)}
          name='search'
          className='form-control-t1 md:tw-w-40  tw-w-full'
          placeholder='Search User'
          autoComplete='off'
        />

        {offlinepayemntreportcountUID && <span className="unique-num">Unique Fee Submission : {offlinepayemntreportcountUID}</span>
        }      </div>

      <div className='page-content'>
        <div className='table-format table-auto'>
          {adminPaperDetails && <DataTable
            paginator
            style={{ height: "auto" }}
            rows={rows}
            first={first}
            totalRecords={totalRecords1}
            onPage={onPageInputChange}
            rowHover
            lazy
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50, 100]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            showGridlines
            scrollable
            scrollHeight="400px" className="admin-paper-details" value={adminPaperDetails} tableStyle={{ minWidth: '50rem' }}>
            <Column field="RowNum" header="SNo." />
            <Column field="UID" header="UID" />
            <Column field="IcaMembershipNumber" header="ICA Life Membership no" />
            <Column field="UserName" header="Name" body={NameBodyTemplate} />
            <Column field="Mobile" header="Mobile" />
            <Column field="PaymentDate" header="Date Of Payment" />
            <Column field="TransactionNo" header="Transaction No." />
            <Column header="Proof" body={paymentProofBodyTemplate} />
            <Column field="BankName" header="Bank Name" />
            <Column body={statusBodyTemplate} header="Mark As" />
          </DataTable>}
        </div>
      </div>
    </div>
  );
};


