import ApiService from "@Src/services/bookstore/ApiService";
import { ADMIN_SUBMISSION_CONSTANT } from "./submission.constant";


export const adminpaperDetails = async () => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_DETAILS,
        method:'get',
        params:''
    })
}


export const adminPaperRemark = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_REMARK,
        method:'put',
        data
    })
}

export const adminPaperDownload = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_DOWNLOAD,
        method:'post',
        data
    })
}

export const offlinePaymentDetails = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.OFFLINE_FEE_PAYMENT_DETAILS  +`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'post',
        data
    })
}

export const offlinePaymentChangeStatus = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.OFFLINE_FEE_PAYMENT_CHANGE_STATUS,
        method:'put',
        data
    })
}


export const getPayemntproofAPi = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.DOWNLOAD_PAYEMENT_PROOF,
        method:'post',
        data
    })
}

export const offlinepaymentdetailscontpost = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.OFFLINE_PAYMENT_DATA_COUNT +`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'post',
        data
    })
}

