import { Navigate } from "react-router-dom";
import { useAppSelector } from "@Src/app/hooks/hooks";
import { AppRouteURL } from "@Src/routes/app-route-urls";

interface Props {
  component: React.ComponentType
  path?: string
}


export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const isLoggedIn = useAppSelector(state => state?.login.Token);
  if (isLoggedIn) {
    return <RouteComponent />
  }
  return <Navigate to={AppRouteURL.LOGIN} />
}

export const UnAuthorisedRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const isLoggedIn = useAppSelector(state => state?.login.Token);
  if (!isLoggedIn) {
    return <RouteComponent />
  } 
  return  <Navigate to={AppRouteURL.DASHBOARD} />
}


export const AdminPrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const isLoggedIn = useAppSelector(state => state?.login.Token);
  const isAdmin = useAppSelector(state => state?.login.isAdmin);
    if (isLoggedIn && (isAdmin === 1)) {

    return <RouteComponent />
  }
  return <Navigate to={AppRouteURL.ADMIN_LOGIN} />
}
