
import { getthankingYouPageDetails } from "@Src/features/client/services/client.service"
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces"
import { useEffect, useState } from "react"
import './index.scss'





export const ThankingYouPage = () => {
    const params = new URLSearchParams(window.location.search);
    const uid = params.get('UID');
    const emailId = params.get('EmailId');
    const [thankdata, setthankdata] = useState<any>()

    useEffect(() => {
        thnakingpageapi()
    }, [])


    function thnakingpageapi() {
        let data = {
            'UID': uid,
            "EmailId": emailId
        }
        getthankingYouPageDetails(data).then((resp: APIResponse) => {
            if (resp.ResponseType == APIStatus.SUCCESS) {
                setthankdata(resp?.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            {thankdata && thankdata.map((list: any) => {
                return (
                    <div className="thnak-you-page">
                        <div className="thank-text">
                            <span className="text-span">Name:</span><span className="text-value">{list?.Salutation == 1 && 'Mr. '}
                                {list?.Salutation == 2 && 'Miss. '}
                                {list?.Salutation == 3 && 'Mrs. '}
                                {list?.Salutation == 4 && 'Dr. '}
                                {list?.Salutation == 5 && 'Prof. '}
                                {list?.Salutation == 6 && 'Senior Prof. '} {list.FirstName} {list.MiddleName} {list.LastName}</span>
                        </div>
                        {list.IcaMembershipNumber && <div className="thank-text">
                            <span className="text-span">ICA Life Membership no:</span><span className="text-value">{list.IcaMembershipNumber}</span>
                        </div>}
                        <div className="thank-text">
                            <span className="text-span">UID:</span><span className="text-value">{list.UID}</span>
                        </div>
                        <div className="thank-text">
                            <span className="text-span">Email:</span><span className="text-value">{list.EmailId}</span>
                        </div>
                        <div className="thank-text">
                            <span className="text-span">Mobile Number:</span><span className="text-value">{list.PrMobileNumber}</span>
                        </div>
                        <div className="thank-text">
                            <span className="text-span">Designation:</span><span className="text-value">{list.Designation}</span>
                        </div>



                    </div>
                )
            })}


        </>
    )

}