import { useFormik } from 'formik';
import { NewApplicantLogin } from '../login/components/new-applicant';
import './index.scss';
import { ResetPasswordInitialform, ResetPasswordSchema } from './schema/resetpassword.schema';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import ReloadSvg from '@Src/assets/svg/commonsvg/reload.svg'
import RightCarrotSvg from "@Src/assets/svg/commonsvg/rightcarrot.svg";
import { passwordresetlink } from './service/resetpassword.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { toast } from 'react-hot-toast';
import RigthArrowSvg from "@Src/assets/svg/commonsvg/rightarrow.svg"
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import { useAppDispatch } from '@Src/app/hooks/hooks';
import { hideSpinner, showSpinner } from '@Src/app/reducers/global/globalSlice';
import { ApiStatusMessage, API_STATUS_MSG_CONSTANT } from '@Src/utils/globalUtilities';




export const ResetPasswordIndex = () => {
    const navigate = useNavigate()
    const [captch, setCaptch] = useState('');
    const [sentlink, setSentLink] = useState(false);
    const dispatch = useAppDispatch()

    useEffect(() => {
        generateRandomText()
    }, [])

    // Function to generate random text for CAPTCHA challenge
    function generateRandomText() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const length = 6; // You can adjust the length of the challenge text

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptch(result)
    }

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
        initialValues: ResetPasswordInitialform,
        validationSchema: ResetPasswordSchema,
        onSubmit: (e) => {
            resetpasswordlinktoapi(e)
        }
    })



    function resetpasswordlinktoapi(e: any) {
        dispatch(showSpinner())
        let payload = {
            "EmailId": e.Email
        }

        passwordresetlink(payload).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                setSentLink(true)
                toast.success('Password Reset link sent to your Mail id')
            }
            else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.ACCOUNT_NOT_FOUND) {
                dispatch(hideSpinner())
                toast.error(ApiStatusMessage.ACCOUNT_NOT_FOUND)
            }
            else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.EMAILID_NOT_RECEIVED) {
                dispatch(hideSpinner())
                toast.error(ApiStatusMessage.EMAILID_NOT_RECEIVED)
            }
            else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.INVALID_EMAIL_RECEIVED) {
                dispatch(hideSpinner())
                toast.error(ApiStatusMessage.INVALID_EMAIL_RECEIVED)
            }
            else {
                dispatch(hideSpinner())
            }
        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error)
        })

    }




    return (
        <>
            <div className='content-row'>
                <NewApplicantLogin />
                <div className='content-cols new-sign-in-cols'>
                    <div className='tw-flex tw-items-center pb-3 '>
                        <img src={RigthArrowSvg} onClick={() => navigate(AppRouteURL.LOGIN)} alt='RigthArrowSvg' className='tw-mr-2 tw-cursor-pointer' />
                        <div className='reset-passwd'>
                            Reset Password
                        </div>
                    </div>

                    {!sentlink ? <div className='content-form'>
                        <form onSubmit={handleSubmit}>
                            <div className='group-form col-row'>
                                <div className='group-form-row'>
                                    <div className='group-form-colls'>
                                        <label className='form-label-1'> Email<span className='tw-text-red-500'>*</span>. </label>
                                        <div>
                                            <InputText
                                                value={values.Email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name='Email' className='form-control-t1'
                                                placeholder='Please enter your email' />
                                            {(errors.Email && touched.Email) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Email}</p> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='group-form-row'>
                                    <div className='group-form-colls'>
                                        <label className='form-label-1'> Security Code as shown below (Case Sensitive)<span className='tw-text-red-500'>*</span> </label>
                                        <div>
                                            <InputText
                                                value={values.captch}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={6}
                                                name='captch' className='form-control-t1'
                                                placeholder='Please enter security pin' />
                                        </div>
                                        {(errors.captch && touched.captch) ?
                                            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.captch}</p> : null}
                                        {(values.captch.length === 6 && values.captch != captch) ?
                                            <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Incorrect Security Code</p> : null}
                                    </div>
                                </div>
                                <div className='group-form-row'>
                                    <div className='group-form-colls'>
                                        <label className='form-label-1'> Security Code </label>
                                        <div className='captch-card'>
                                            <div className='captcha-col' onCopy={(e) => e.preventDefault()}>
                                                {captch}
                                            </div>
                                            <span className='reload-span' onClick={() => generateRandomText()}>
                                                <img src={ReloadSvg} alt='ReloadSvg' />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className='group-form-row'>
                                    <div className='group-form-footer flex align-items-center mt-4'>
                                        <button className="button-primary active" type='submit' >Send Password Reset Link
                                            <img className='tw-ml-2' src={RightCarrotSvg} alt='RightCarrotSvg' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> :
                        <div className='reset-passwd-para'>
                            Password reset link has been sent to your email.<br />
                            Please check your inbox to update your password.
                        </div>
                    }



                </div>

            </div>
        </>
    )
}