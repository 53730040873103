export const PaperSubmissionInitialform = {
  abstract: "",
  fullpaper: "",
  session: "",
  paperTitle: "",
  similarityIndex: 0,
};

export enum FileUploadType {
  UPLOAD_FILE_TYPE = "application/pdf",
}

export const FileSize = {
  TWENTY_MB: 20971520,
};

export enum FileCategory {
  ABSTRACT = "Abstract",
  FULL_PAPER = "Full Paper",
}

export const PaperSession = [
  { name: "Technical Session I", id: 1 },
  { name: "Technical Session II", id: 2 },
  { name: "Technical Session III", id: 3 },
  { name: "Technical Session IV", id: 4 },
];

export enum PaperStatus {
  SUBMITTED = "Submitted",
  REJECTED = "Rejected",
  REVESION = "Revision",
  ACCEPTED = "Accepted",
  RE_SUBMITTED = 'ReSubmitted'
}
