import ApiService from "@Src/services/bookstore/ApiService";
import { CLIENT_CONSTANT } from "./client.constant";


export const userFeeList = async() => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.USER_FEE_LIST,
    method:'get',
    params:''
  })
}


export const deleteUser = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.DELETE_USER,
    method:'delete',
    data

  })
}

export const addUser = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.ADD_USER,
    method:'post',
    data
  })
}

export const getinvoice = async () => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.DOWNLOAD_INVOICE,
    method:'get',
    params:''
  })
}

export const getCertificateDownload = async () => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.DOWNLOAD_CERTIFICATE,
    method:'get',
    params:''
  })
}

export const saveTempOrder = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.SAVE_TEMP_ORDER,
    method:'post',
    data
  })
}
export const postProductOrder = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.POST_PRODUCT_ORDER,
    method:'post',
    data
  })
}

export const postDonationProductOrder = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.POST_DONATION_PRODUCT_ORDER,
    method:'post',
    data
  })
}

export const ipAddress = async () => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.USER_IP_ADDRESS,
    method:'get',
    params:''
  })
}

export const userStatus = async () => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.USER_STATUS,
    method:'get',
    params:''
  })
}

export const getthankingYouPageDetails = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.THANKIN_YOU_PAGE_DETAILS+`?UID=${data.UID}&EmailId=${data.EmailId}`,
    method:'get',
    params:''
  })
}

export const getQrcode = async () => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.QR_CODE,
    method:'get',
    params:''
  })
}

export const addVerifyPayment = async (data:any) => {
  return await ApiService.fetchData({
    url:CLIENT_CONSTANT.ADD_FEE_PAYMENT_TRANSACTION_DETAILS,
    method:'post',
    data
  })
}
