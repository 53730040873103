import { createSlice } from "@reduxjs/toolkit"; 

const initialState = {
    userUID:'',
    UserDetails : [],
    icaMembershipVerified:false
}

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setUserUID: (state,action) => {
            state.userUID = action.payload;
       },
        setUserDetails: (state,action) => {
            state.UserDetails = action.payload;
       },
       setICAVerified: (state,action) => {
        state.icaMembershipVerified = action.payload;
   },
    }
});

export default registerSlice.reducer;
export const {  setUserDetails , setUserUID , setICAVerified} = registerSlice.actions;