import React, { useState, useEffect } from "react";
import { OfflineFeePayReportIndex } from "@Src/features/admin/offline-fee-pay-report";

export const OfflineFeePayReportPage = () => {


    return (
        <div>
            <OfflineFeePayReportIndex/>
        </div>
    );
};
