import { PaymentStatusIndex } from "@Src/features/client/payment-status";
import React  from "react";

export const PaymentStatusPage = () => {


  return (
    <div>
       <PaymentStatusIndex/>
    </div>
  );
};
