export const ValidFields = {
  FIRST_NAME: {
    MIN: 2,
    MAX: 20,
    SHORT_MESSAGE: "First Name is too short , atleast 2 characters required",
    LONG_MESSAGE: "First Name is too long , maximum 20 characters accepted",
    REQUIRED: "First Name is required",
    MATCH_MESSAGE: "Please Enter Valid First Name",
  },
  LAST_NAME: {
    MIN: 2,
    MAX: 20,
    SHORT_MESSAGE: "Last Name is too short , atleast 2 characters required",
    LONG_MESSAGE: "Last Name is too long , maximum 20 characters accepted",
    REQUIRED: "Last Name is required",
    MATCH_MESSAGE: "Please Enter Valid Last Name",
  },
  PASSWORD: {
    MIN: 6,
    MAX: 16,
    SHORT_MESSAGE: "Password must be at least 6 charaters",
    LONG_MESSAGE: "",
    REQUIRED: "Password is required",
  },
  EMAIL: {
    ERROR_MESSAGE: "Email is Invalid",
    REQUIRED: "Email is required",
  },
  OFFICE_EMAIL: {
    SAME: "Official email must be different from personal email",
    ERROR_MESSAGE: "Official Email is Invalid",
    REQUIRED: "Official Email is required",
  },
  T_AND_C: {
    ERROR_MESSAGE: "You must accept the terms and conditions",
  },
  MOBILE: {
    MIN: 10,
    MAX: 10,
    SHORT_MESSAGE: "Mobile Number must be 10 digits long",
    LONG_MESSAGE: "Mobile Number must be 10 digits long",
    REQUIRED: "Mobile Number is required",
  },
  SECONDARY_MOBIEL: {
    REQUIRED:
      "Primary Mobile Number and Secondary Mobile Number Should be defferent",
  },
  COUNTRY_CODE: {
    REQUIRED: "Country Code is required",
  },
  OTP: {
    MIN: 6,
    MAX: 6,
    SHORT_MESSAGE: "OTP Number must be 6 digits long",
    LONG_MESSAGE: "OTP Number must be 6 digits long",
    REQUIRED: "OTP is required",
  },
  NEW_PASSWORD: {
    MIN: 6,
    MAX: 16,
    SHORT_MESSAGE: "Password must be at least 6 charaters",
    LONG_MESSAGE: "",
    REQUIRED: "New Password is required",
  },
  ConForm_PASSWORD: {
    MIN: 6,
    MAX: 16,
    SHORT_MESSAGE: "Password must be at least 6 charaters",
    LONG_MESSAGE: "",
    REQUIRED: "Confirm Password is required",
  },
  DATE_OF_BIRTH: {
    REQUIRED: "Date of Birth is required",
  },
  DESIGNATION: {
    REQUIRED: "Designation is required",
  },
  DEPARTMENT: {
    REQUIRED: "Designation is required",
  },
  CITY: {
    REQUIRED: "City Name is required",
  },
  STATE: {
    REQUIRED: "State/Province is required",
  },
  COUNTRY: {
    REQUIRED: "Country is required",
  },
  AGE: {
    REQUIRED: "Age is required",
  },
  GENDER: {
    REQUIRED: "Gender is required",
  },
  RELATION: {
    REQUIRED: "Relation is required",
  },
  UNIVERSITY: {
    REQUIRED: "university/college/institute Name is required",
  },
  ZIPCODE: {
    REQUIRED: "Zip/Postal Code is required",
  },
  NAME: {
    MIN: 2,
    MAX: 50,
    SHORT_MESSAGE: "Name is too short , atleast 2 characters required",
    LONG_MESSAGE: "Name is too long , maximum 50 characters accepted",
    REQUIRED: "Name is required",
    MATCH_MESSAGE: "Please Enter Valid Name",
  },
  SUBJECT: {
    MIN: 2,
    SHORT_MESSAGE: "Subject is too short , atleast 2 characters required",
    REQUIRED: "Subject is required",
    MATCH_MESSAGE: "Please Enter Valid Subject",
  },
  DESCRIPTION: {
    MIN: 2,
    SHORT_MESSAGE: "Description is too short , atleast 2 characters required",
    REQUIRED: "Description is required",
    MATCH_MESSAGE: "Please Enter Valid Description",
  },
  REMARKS: {
    MIN: 2,
    SHORT_MESSAGE: "Remarks is too short , atleast 2 characters required",
    REQUIRED: "Remarks is required",
    MATCH_MESSAGE: "Please Enter Valid Remarks",
  },
  TRANSACTION_NO: {
    REQUIRED: "Transaction Number is required",
  },
  PAYMENT_DATE: {
    REQUIRED: "Payment Date is required",
  },
  BANK_NAME: {
    REQUIRED: "Bank Name is required",
  },
};
