import React, { useEffect, useRef, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import 'primeicons/primeicons.css';
import "./index.scss"
import { useAppSelector, useAppDispatch } from '@Src/app/hooks/hooks';
import { Toast } from 'primereact/toast';
import { traveldetails } from './service/traveldetails.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { useFormik } from 'formik';
import { TravelDetailsInitialform } from './schema/traveldetails.schema';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { TravelTerminals, TravelPlace, TimeTravel, TrainTravelPlace } from './service/traveldetails.constant';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import { hideSpinner, setSubmitedTravelDetails, showSpinner } from '@Src/app/reducers/global/globalSlice';
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"
import { offlineFeePaymentStatusEnum } from '@Src/app/constants/app.constant';



export const TravelDetailsIndex = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const payment = useAppSelector(state => state.global.paymentstatus)
    const offlineFeePaymentStatus = useAppSelector(state => state.global.offlineFeePaymentStatus)
    const Submitedtraveldetails = useAppSelector(state => state.global.submitedtraveldetails)
    const [arrivialTravelType, setArrivialTravelType] = useState<any>('Air')
    const [departTravelType, setDepartTravelType] = useState('Air')
    const [arrivaldata, setArrivalDate] = useState<any>()
    const [depaturedata, setDeparturedat] = useState<any>()
    const [onnext, setOnNext] = useState(0)
    const [submitsuccess, setsubmitsuccess] = useState(false)
    const [arrivalerror, setArrivalError] = useState(false)
    const [depatureerror, setDepatureError] = useState(false)
    const toast: any = useRef(null);
    const [arrivaltraveldropdown, setArrivaltraveldropdown] = useState<any>()
    const [depaturetraveldropdown, setDepaturetraveldropdown] = useState<any>()


    useEffect(() => {
        if (!(payment == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) {
            setTimeout(() => {
                showSuccess()
            }, 100)
        }
    }, [payment])
    const showSuccess = () => {
        toast.current.show({ severity: 'info', summary: 'Message', detail: 'Please submit your Fees to Proceed.', life: 3000 });
    }
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
        initialValues: TravelDetailsInitialform,
        validationSchema: '',
        onSubmit: (e) => {
            if (depaturedata) {
                submittraveldetails(e)
            } else if (!depaturedata) {
                setDepatureError(true)
            }
        },
    });

    useEffect(() => {
        setFieldValue('arrivalDetails.travelTypeNumber', '')
        setFieldValue('arrivalDetails.terminal', '')
    }, [arrivialTravelType])

    useEffect(() => {
        setFieldValue('diparturDetails.travelTypeNumber', '')
        setFieldValue('diparturDetails.terminal', '')
    }, [departTravelType])

    function extractDateFromString(dateString: any, value: any) {
        const dateObject = new Date(dateString);
        // Get the year, month, and day from the Date object
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
        const day = dateObject.getDate();
        // Format the date in 'YYYY-MM-DD' format
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
        if (value === 'arrival') {
            return setArrivalDate(formattedDate)
        }
        if (value === 'departure') {
            return setDeparturedat(formattedDate)
        }


    }
    const submittraveldetails = (e: any) => {
        dispatch(showSpinner())
        let payload = {
            "arrivalDetails": {
                "place": e.arrivalDetails.place,
                "date": arrivaldata,
                "terminal": e.arrivalDetails.terminal,
                "travelType": arrivialTravelType,
                "travelTypeNumber": e.arrivalDetails.travelTypeNumber,
                "time": e.arrivalDetails.time
            },
            "diparturDetails": {
                "place": e.diparturDetails.place,
                "date": depaturedata,
                "terminal": e.diparturDetails.terminal,
                "travelType": departTravelType,
                "travelTypeNumber": e.diparturDetails.travelTypeNumber,
                "time": e.diparturDetails.time
            }
        }
        console.log(payload)
        traveldetails(payload).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                setsubmitsuccess(true)
                dispatch(setSubmitedTravelDetails(1))
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            else {
                dispatch(hideSpinner())
            }
        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error)
        })
    }

    const nexthandler = () => {
        if (arrivaldata) {
            setOnNext(1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (!arrivaldata) {
            setArrivalError(true)
        }
    }

    const submithandler = () => {
        handleSubmit()
    }

    const continuehandler = () => {
        navigate(AppRouteURL.PAPER_SUBMISSION)
    }

    useEffect(() => {
        if (arrivialTravelType == 'Train') {
            setArrivaltraveldropdown(TrainTravelPlace)

        } else {
            setArrivaltraveldropdown(TravelPlace)
        }
    }, [arrivialTravelType])

    useEffect(() => {
        if (departTravelType == 'Train') {
            setDepaturetraveldropdown(TrainTravelPlace)

        } else {
            setDepaturetraveldropdown(TravelPlace)
        }
    }, [departTravelType])



    return (
        <>
            {Submitedtraveldetails === 0 ?
                <div>
                    {(payment == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)
                        ? <div className=''>
                            <div className='page-header-1'>
                                <h2 className='content-heading-1'> Travel Details </h2>
                            </div>
                            <div className='page-content'>
                                <TabView activeIndex={onnext} className='tabView-ui' onTabChange={(e) => setOnNext(e.index)}>
                                    <TabPanel header="Arrival" key={0}>
                                        <div className='group-form col-row'>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Date of arrival to Delhi<span className='tw-text-red-500'>*</span>  </label>
                                                    <div>
                                                        <Calendar
                                                            value={values.arrivalDetails.date}
                                                            onChange={(e) => extractDateFromString(e.target.value, 'arrival')}
                                                            onBlur={handleBlur}
                                                            name='arrivalDetails.date'
                                                            className="w-full  custom-calendar"
                                                            minDate={new Date()}
                                                            placeholder='Please select your arrival date' showIcon />
                                                    </div>
                                                    {(arrivalerror && !arrivaldata) ?
                                                        <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Arrival date is required</p> : null}
                                                </div>
                                            </div>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'> Mode of Arrival </label>
                                                    <div className='inline-list-row'>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='arrivialTravelType'
                                                                value={arrivialTravelType}
                                                                onChange={(e) => setArrivialTravelType('Air')}
                                                                checked={arrivialTravelType === 'Air'} /> Air
                                                        </label>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='arrivialTravelType'
                                                                value={arrivialTravelType}
                                                                onChange={(e) => setArrivialTravelType('Train')}
                                                                checked={arrivialTravelType === 'Train'} />Train
                                                        </label>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='arrivialTravelType'
                                                                value={arrivialTravelType}
                                                                onChange={(e) => setArrivialTravelType('Road')}
                                                                checked={arrivialTravelType === 'Road'} /> Road
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {arrivialTravelType != 'Road' && <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>{arrivialTravelType === 'Air' && 'Flight No.'}
                                                        {arrivialTravelType === 'Train' && 'Train No.'}</label>
                                                    <div>
                                                        <InputText
                                                            value={values.arrivalDetails.travelTypeNumber}
                                                            onChange={handleChange}
                                                            maxLength={20}
                                                            autoComplete='off'
                                                            name='arrivalDetails.travelTypeNumber' className='form-control-t1'
                                                            placeholder={`Please enter your ${arrivialTravelType === 'Air' ? 'flight' : 'train'} number`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Time of Arrival </label>
                                                    <div>
                                                        <Dropdown value={values.arrivalDetails.time}
                                                            onChange={(e) => setFieldValue('arrivalDetails.time', e.value)}
                                                            options={TimeTravel}
                                                            optionLabel="Name"
                                                            optionValue='Name'
                                                            name='arrivalDetails.time'
                                                            placeholder="Select Time of Arrival" className="w-full" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Place of Arrival ({arrivialTravelType === 'Air' && 'from air'}
                                                        {arrivialTravelType === 'Train' && 'from Train'}
                                                        {arrivialTravelType === 'Road' && 'from Road'}
                                                        )
                                                    </label>
                                                    <div>
                                                        <Dropdown value={values.arrivalDetails.place}
                                                            onChange={(e) => setFieldValue('arrivalDetails.place', e.value)}
                                                            options={arrivaltraveldropdown}
                                                            optionLabel="Name"
                                                            optionValue='Name'
                                                            name='arrivalDetails.place'
                                                            placeholder="Please select your place of arrival" className="w-full" />
                                                    </div>
                                                </div>
                                            </div>

                                            {arrivialTravelType === 'Air' && <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Arrival Terminal </label>
                                                    <div>
                                                        <Dropdown value={values.arrivalDetails.terminal}
                                                            onChange={(e) => setFieldValue('arrivalDetails.terminal', e.value)}
                                                            options={TravelTerminals}
                                                            optionLabel="Name"
                                                            optionValue='id'
                                                            name='arrivalDetails.terminal'
                                                            placeholder=" Please select your arrival terminal " className="w-full" />
                                                    </div>
                                                </div>
                                            </div>}



                                            <div className='group-form-row mt-4'>

                                                <div className='group-form-footer mt-0'>
                                                    <a href='javascript:void(0)' className='button-primary active' onClick={() => nexthandler()}> Next <i className="pi pi-angle-right ml-1"></i> </a>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Departure" key={1} disabled={onnext === 0}>
                                        <div className='group-form col-row'>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Date of departure to Delhi<span className='tw-text-red-500'>*</span></label>
                                                    <div>
                                                        <Calendar
                                                            value={values.diparturDetails.date}
                                                            onChange={(e) => extractDateFromString(e.target.value, 'departure')}
                                                            name='diparturDetails.date'
                                                            minDate={new Date()}
                                                            className="w-full custom-calendar"
                                                            placeholder='Please select your departure date' showIcon />

                                                    </div>
                                                    {(depatureerror && !depaturedata) ?
                                                        <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">departure date is required</p> : null}
                                                </div>
                                            </div>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'> Mode of Departure </label>
                                                    <div className='inline-list-row'>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='departTravelType'
                                                                value={departTravelType}
                                                                onChange={(e) => setDepartTravelType('Air')}
                                                                checked={departTravelType === 'Air'} /> Air
                                                        </label>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='departTravelType'
                                                                value={departTravelType}
                                                                onChange={(e) => setDepartTravelType('Train')}
                                                                checked={departTravelType === 'Train'} />Train
                                                        </label>
                                                        <label className='radio-label inline-list-coll'>
                                                            <input type='radio'
                                                                name='departTravelType'
                                                                value={departTravelType}
                                                                onChange={(e) => setDepartTravelType('Road')}
                                                                checked={departTravelType === 'Road'} /> Road
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {departTravelType != 'Road' && <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>{departTravelType === 'Air' && 'Flight No.'}
                                                        {departTravelType === 'Train' && 'Train No.'}</label>
                                                    {departTravelType === 'Road' && 'Road No.'}
                                                    <div>
                                                        <InputText
                                                            value={values.diparturDetails.travelTypeNumber}
                                                            onChange={handleChange}
                                                            maxLength={20}
                                                            autoComplete='off'
                                                            name='diparturDetails.travelTypeNumber' className='form-control-t1'
                                                            placeholder={`Please enter your ${departTravelType === 'Air' ? 'flight' : 'train'} number`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Time of Departure </label>
                                                    <div>
                                                        <Dropdown value={values.diparturDetails.time}
                                                            onChange={(e) => setFieldValue('diparturDetails.time', e.value)}
                                                            options={TimeTravel}
                                                            optionLabel="Name"
                                                            optionValue='Name'
                                                            name='diparturDetails.time'
                                                            placeholder="Select Time of Departure" className="w-full" />                                        </div>
                                                </div>
                                            </div>
                                            <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Place of Departure ({departTravelType === 'Air' && 'from air'}
                                                        {departTravelType === 'Train' && 'from Train'}
                                                        {departTravelType === 'Road' && 'from Road'}) </label>
                                                    <div>
                                                        <Dropdown value={values.diparturDetails.place}
                                                            onChange={(e) => setFieldValue('diparturDetails.place', e.value)}
                                                            options={depaturetraveldropdown}
                                                            optionLabel="Name"
                                                            optionValue='Name'
                                                            name='diparturDetails.place'
                                                            placeholder="Please select your place of Departure" className="w-full" />                                        </div>
                                                </div>
                                            </div>

                                            {departTravelType === 'Air' && <div className='group-form-row'>
                                                <div className='group-form-colls'>
                                                    <label className='form-label-1'>Departure Terminal </label>
                                                    <div>
                                                        <Dropdown value={values.diparturDetails.terminal}
                                                            onChange={(e) => setFieldValue('diparturDetails.terminal', e.value)}
                                                            options={TravelTerminals}
                                                            optionLabel="Name"
                                                            optionValue='id'
                                                            name='diparturDetails.terminal'
                                                            placeholder=" Please select your departure terminal " className="w-full" />                                        </div>
                                                </div>
                                            </div>}



                                            <div className='group-form-row mt-4'>

                                                <div className='group-form-footer mt-0'>

                                                    <a href='javascript:void(0)' onClick={() => submithandler()} className='button-primary active'> Submit <i className="pi pi-angle-right ml-1"></i> </a>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div> : <Toast ref={toast} position="top-center" />
                    }
                </div> :
                <div className="empty-data">
                    <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
                    <div className="empty-data-details">
                        <h2 className="empty-data-h1"> Travel Details Submitted </h2>
                        <p className="empty-data-text-2"> Your travel details has been submitted successfully. <br />
                            Please proceed to add Paper Details. </p>
                        <div> <button className="button-primary active" onClick={() => continuehandler()}> Continue to Paper Details <i className="pi pi-angle-right ml-1"></i> </button> </div>
                    </div>
                </div>}

        </>
    )
}