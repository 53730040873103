export const CLIENT_CONSTANT = {
    USER_FEE_LIST:'ica/feepayment/userFeeList',
    DELETE_USER:'ica/user/deleteAccompanying',
    ADD_USER:'ica/user/addAccompanying',
    SAVE_TEMP_ORDER:'ica/feepayment/saveTempOrder',
    POST_PRODUCT_ORDER:'ica/feepayment/postProductOrder',
    USER_IP_ADDRESS:'/ica/user/ipAddress',
    USER_STATUS:'ica/user/checkStatus',
    THANKIN_YOU_PAGE_DETAILS:'ica/user/thankyouPageDetails',
    QR_CODE:'ica/user/qrcode',
    ADD_FEE_PAYMENT_TRANSACTION_DETAILS:'ica/feepayment/feePaymentTransactionDetail',
    DOWNLOAD_INVOICE:'ica/feepayment/downloadInvoice',
    DOWNLOAD_CERTIFICATE:'ica/feepayment/downloadCertificate',
    POST_DONATION_PRODUCT_ORDER:'ica/donationsponsorship/postProductOrder',


}