export const ADMIN_SUBMISSION_CONSTANT = {
    ADMIN_PAPER_DETAILS:'ica-admin/submission/paperDetails',
    ADMIN_PAPER_REMARK:'ica-admin/submission/submitPaperRemarks',
    ADMIN_PAPER_DOWNLOAD:'ica-admin/submission/downloadPaper',
    OFFLINE_FEE_PAYMENT_DETAILS:'ica-admin/masterDetails/transactionDetails',
    OFFLINE_FEE_PAYMENT_CHANGE_STATUS:'ica-admin/submission/transactionStatus',
    DOWNLOAD_PAYEMENT_PROOF:'ica-admin/masterDetails/downloadImage',
    OFFLINE_PAYMENT_DATA_COUNT:"ica-admin/masterDetails/transactionDetailsCount"
}


export const paperStatusdropdown = [
   {Name:'Revision'},
   {Name:'Rejected'},
   {Name:'Accepted'},
]

export enum paperDetailsStatus {
  RE_SUBMITTED ="ReSubmitted",
  REVESION ='Revision',
  ACCEPTED='Accepted',
  REJECTED='Rejected',
  SUBMITTED= 'Submitted'
}

export const offlineFeePaymentStatusdropdown = [
  {label:'Accepted',value:1},
  {label:'Rejected', value:2},
]
