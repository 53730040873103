export const REGISTER_CONSTANT_API_ROUTES ={
    GET_STATE:'ica/home/state',
    GET_COUNTRY:'ica/home/country',
    GET_DESIGNATION:'ica/home/designation',
    GET_COUNTRY_CODE:'ica/home/countryCode',
    GET_DEPARTMENT:'ica/home/department',
    GET_USER_REGISTER:'ica/user/registerUser',
    GET_RELATION:'ica/home/relation',
    VERIFY_ICA_MEMBERSHIP:'ica/home/validateIcaId',
    EMAIL_VERIFY:'ica/user/checkUniqueness',
    GET_VERIFY_RESEARCH_SCHOLAR:'ica/researchScholar/verifyStatus',
    POST_VERIFY_RESEARCHS_SCHOLAR:'ica/researchScholar/setStatus',
    DONATION_SPONSOR_SHIP_TYPE:'ica/donationsponsorship/sponsorshipType',
    POST_DONATION_SPONSORSHIP_API:'ica/donationsponsorship/sponsorshipData'
}