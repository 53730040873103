import React from 'react'
import "./Loader.scss"
import { store } from "@Src/app/store";

const mindlerIcon = `${process.env.REACT_APP_MINDLER_PRODUCT_IMAGES_URL}/profile-builder/icons/mindler.png`;

const Loader = () => {
    return (
        <>
       {store.getState().global.loading && <div className="spinner">
            <div className="spinner-icon">
                <img src={mindlerIcon} alt="" />
            </div>
        </div>}
        </>
        
    )
}

export default Loader