import ApiService from "@Src/services/bookstore/ApiService";
import { PAPER_SUBMISSION_CONSTANT } from "./papersubmission.constant";




export const submitpaper = async(data:any) => {
 return await ApiService.fetchData({
    url:PAPER_SUBMISSION_CONSTANT.SUBMIT_PAPER,
    method:'post',
    data
 })
}

export const paperDetails = async() => {
   return await ApiService.fetchData({
      url:PAPER_SUBMISSION_CONSTANT.PAPER_DETAILS,
      method:'get',
      params:''
   })
  }

  export const paperSubmissionAllowed = async() => {
   return await ApiService.fetchData({
      url:PAPER_SUBMISSION_CONSTANT.PAPER_SUBMISSION_ALLOWED,
      method:'get',
      params:''
   })
  }

  export const getpaperSession = async() => {
   return await ApiService.fetchData({
      url:PAPER_SUBMISSION_CONSTANT.PAPER_SESSION_DROPDOWN,
      method:'get',
      params:''
   })
  }
