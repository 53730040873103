import ApiService from "@Src/services/bookstore/ApiService";
import { RESET_PASSWORD_CONATSTANT } from "./resetpassword.constant";




export const passwordresetlink = async(data:any) => {
    return await ApiService.fetchData({
      url:RESET_PASSWORD_CONATSTANT.PASSWORD_LINK,
      method:'post',
      data
    })
  }

  export const updatepassword = async(data:any) => {
    return await ApiService.fetchData({
      url:RESET_PASSWORD_CONATSTANT.CHANGE_PASSWORD,
      method:'post',
      data
    })
  }