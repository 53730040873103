import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import globalSlice from './reducers/global/globalSlice';
import registerslice from './reducers/auth/registerslice';
import loginslice from './reducers/auth/loginslice';
import {persistReducer, persistStore} from 'redux-persist';
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'


const persistConfig: any = {
    key: 'root',
    version: 1,
    storage
}


const  reducer = combineReducers ({
	global:globalSlice,
	register:registerslice,
	login:loginslice
})

const persistedReducer = persistReducer(persistConfig, reducer)


export const store = configureStore({
	reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
