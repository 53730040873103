import React, { useState, useEffect } from "react";
import { RegisterationSuccessIndex } from "@Src/features/client/register/components/registerSuccesful";

export const RegisterSuccessPage = () => {


    return (
        <div>
            <RegisterationSuccessIndex/>
        </div>
    );
};
