import { ValidFields } from "@Src/utils/messages/validation.contant";
import { globalRegex } from "@Src/utils/regex/regex.constant";
import * as Yup from "yup";

export const AccompanyingSchema = Yup.object({
  Name: Yup.string()
    .min(ValidFields?.FIRST_NAME?.MIN, ValidFields?.FIRST_NAME?.SHORT_MESSAGE)
    .max(ValidFields?.FIRST_NAME?.MAX, ValidFields?.FIRST_NAME?.LONG_MESSAGE)
    .required(ValidFields?.FIRST_NAME?.REQUIRED)
    .matches(
      globalRegex?.ONLY_ALBHABET?.REGEX,
      globalRegex?.ONLY_ALBHABET?.MESSAGE
    ),
  Age: Yup.string().required(ValidFields?.AGE?.REQUIRED),
  Gender: Yup.string().required(ValidFields?.GENDER?.REQUIRED),
  Relation: Yup.string().required(ValidFields?.RELATION?.REQUIRED),
});

export const InitialAccompanyingUser = {
  IcaMembershipNumber:'',
  Salutation:"",
  Name: "",
  Age: "",
  Gender: "",
  Relation: "",
};

export const VerifyFeePaymentSchema = Yup.object({
  UserName: Yup.string()
    .min(ValidFields?.NAME?.MIN, ValidFields?.NAME?.SHORT_MESSAGE)
    .max(ValidFields?.NAME?.MAX, ValidFields?.NAME?.LONG_MESSAGE)
    .required(ValidFields?.NAME?.REQUIRED)
    .matches(
      globalRegex?.ONLY_ALBHABET?.REGEX,
      globalRegex?.ONLY_ALBHABET?.MESSAGE
    ),
    Mobile: Yup.string().min(ValidFields?.MOBILE.MIN, ValidFields?.MOBILE?.SHORT_MESSAGE).max(ValidFields?.MOBILE.MAX, ValidFields?.MOBILE?.LONG_MESSAGE).required(ValidFields?.MOBILE?.REQUIRED)
    .matches(globalRegex?.MOBILE_NUMBER?.REGEX, globalRegex?.MOBILE_NUMBER?.MESSAGE),
    TransactionNo: Yup.string().required(ValidFields?.TRANSACTION_NO?.REQUIRED),
    BankName: Yup.string().required(ValidFields?.BANK_NAME?.REQUIRED),
    PaymentDate: Yup.string().required(ValidFields?.PAYMENT_DATE?.REQUIRED),
});

export const InitialVerifyFeePayment = {
  Salutation:"",
  UserName: "",
  Mobile: "",
  TransactionNo: "",
  BankName: "",
  PaymentDate:""
};
