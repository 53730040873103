
import { ValidFields } from '@Src/utils/messages/validation.contant';
import * as Yup from 'yup';



export const AdminLoginSchema = Yup.object({
    UID: Yup.string().required(ValidFields?.EMAIL?.REQUIRED),
    password: Yup.string().required(ValidFields?.PASSWORD?.REQUIRED),
})

export const AdminLoginInitialform = {
    UID: "",
    password: "",
}