import React, { useState, useEffect } from "react";
import {LoginIndex} from "@Features/client/login";

export const LoginPage = () => {


  return (
    <div>
       <LoginIndex/>
    </div>
  );
};
