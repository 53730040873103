import './index.scss';
import { paperDetails } from '../../service/papersubmission.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { useEffect, useState } from 'react';
import { PaperStatus } from '../../schema/papersubmission.schema';
import { useAppDispatch } from '@Src/app/hooks/hooks';
import { setAgainSubmitpaper, setRevisionPaperSubmit } from '@Src/app/reducers/global/globalSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import toast from 'react-hot-toast';
import RigthArrowSvg from "@Src/assets/svg/commonsvg/rightarrow.svg"
import { store } from '@Src/app/store';




export const PaperDetailsList = () => {
    const [paperdata, setPaperData] = useState<any>()
    const [visiblecoumn, setvisiblecoumn] = useState<any>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        getPaperDetails()
    }, [])

    function getPaperDetails() {
        paperDetails().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setPaperData(statusformatdata(resp.data)
                )
            }
        }).catch(error => {
            console.log(error)
        })

    }

    function statusformatdata(data: any) {
        data?.forEach((d: any) => {
            if (d?.Status && (d?.Status == PaperStatus.REVESION)) {
                setvisiblecoumn(true)
            } return data
        })

        return data;

    }

    function StatusBodyTemplate(rowData: any) {
        return (
            <>
                <span className='block tw-mb-1'>
                    <span
                        className={`button-primary active 
                      ${rowData.Status === PaperStatus.SUBMITTED && 'b-completed'}
                      ${rowData.Status === PaperStatus.RE_SUBMITTED && 'b-completed'}
                      ${rowData.Status === PaperStatus.REJECTED && 'b-rejected'} 
                      ${rowData.Status === PaperStatus.REVESION && 'b-revision'} 
                      ${rowData.Status === PaperStatus.ACCEPTED && 'b-completed'}  `}> {rowData.Status} </span>
                </span>
                {(rowData.Status === PaperStatus.SUBMITTED || rowData.Status === PaperStatus.RE_SUBMITTED) && <span className='table-title-2'>Waiting for approval </span>}
            </>
        )
    }

    function revesionBodyTemplate(rowData: any) {
        return (
            <>
                {rowData.Status === PaperStatus.REVESION && <a href='javascript:void(0)' className='button-primary active'
                    onClick={() => submitrevesionpaper(rowData)}
                >Submit Revised Paper
                    <i className="pi pi-angle-right ml-3"></i></a>}

            </>
        )
    }

    function submitrevesionpaper(data: any) {
        if (store.getState().global.allowPaperSubmissionFlag) {
            dispatch(setRevisionPaperSubmit(data))
            dispatch(setAgainSubmitpaper(false))
        }else{
            toast.success('Paper Submission is closed')
        }


    }
    function SubmitNewPaper (){
       if(store.getState().global.allowPaperSubmissionFlag){
        dispatch(setAgainSubmitpaper(false))
       }else{
        toast.success('Paper Submission is closed')
    }
    }


    function sessionDetailsBodyTemplate(rowData: any) {
        return (
            <>
                <span>{rowData?.SessionName && rowData?.SessionName}</span>
            </>
        )
    }

    function commentBodyTemplate(rowData: any) {
        return (
            <>
                {(rowData.Comments && rowData.Comments != 'undefined') && <span>{rowData.Comments}</span>}
            </>
        )

    }
    return (
        <>
            <div className=''>
                {/* <div className='page-info-details'> You have successfully registered! </div> */}
                <div className='page-header-1'>
                    <div className="tw-flex  ">
                        <div className='tw-mt-1'>
                            <img className="tw-mr-1 tw-cursor-pointer" onClick={() => dispatch(setAgainSubmitpaper(false))} src={RigthArrowSvg} alt="RigthArrowSvg" />
                        </div>
                        <h2 className='content-heading-1'> Paper Details </h2>
                    </div>
                </div>
                <div className='page-content'>
                    <div className='table-format'>
                        {paperdata && <DataTable
                            value={paperdata}
                            showGridlines
                            scrollable
                            scrollHeight="400px"
                            className="admin-paper-details" >
                            <Column field="TitleOfPaper" header="Paper TItle" />
                            <Column field="Status" header="Status" body={StatusBodyTemplate} />
                            <Column field="Comments" header="COmments" body={commentBodyTemplate} />
                            <Column field="Session" header="Session Detail" body={sessionDetailsBodyTemplate} />
                            {visiblecoumn && <Column field="Status" header=" " body={revesionBodyTemplate} />}

                        </DataTable>}





                        <table className='table-format-ui tw-hidden' cellPadding="0" cellSpacing="0" width="100%" border={1}>
                            <thead>
                                <tr className='table-format-head'>
                                    <th >Paper TItle  </th>
                                    <th> Status </th>
                                    <th> COmments </th>
                                    <th> Session Detail </th>

                                </tr>
                            </thead>
                            <tbody>
                                {paperdata && paperdata.map((list: any) => {
                                    return (
                                        <tr>
                                            <td width='250'>
                                                <span className='table-title-1'> {list?.TitleOfPaper} </span>
                                            </td>
                                            <td>
                                                <span className='block'>
                                                    <a href='javascript:void(0)'
                                                        className={`button-primary active 
                                                     ${list.Status === PaperStatus.SUBMITTED && 'b-completed'}
                                                     ${list.Status === PaperStatus.REJECTED && 'b-rejected'} 
                                                     ${list.Status === PaperStatus.REVESION && 'b-revision'} 
                                                     ${list.Status === PaperStatus.ACCEPTED && 'b-completed'}  `}> {list.Status} </a>
                                                </span>
                                                {list.Status === PaperStatus.SUBMITTED && <span className='table-title-2 mt-2'>Waiting for approval </span>}
                                            </td>
                                            <td>{list?.Comments}</td>
                                            <td> <span className='table-title-1'> Technical Session {list.Session} </span> </td>
                                            {list.Status === PaperStatus.REVESION && <td><a href='javascript:void(0)' className='button-primary active'> Submit Revised Paper <i className="pi pi-angle-right ml-3"></i> </a>
                                            </td>}
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>

                    <div className='mt-4'>
                        <a href='javascript:void(0)' className='button-primary active'
                             onClick={() => SubmitNewPaper()}
                        >Submit Another Paper <i className="pi pi-angle-right ml-3"></i></a>
                    </div>
                </div>
            </div>
        </>
    )
}