
import { ValidFields } from '@Src/utils/messages/validation.contant';
import { globalRegex } from '@Src/utils/regex/regex.constant';
import * as Yup from 'yup';



export const LoginSchema = Yup.object({
    UID: Yup.string().required('UID No is required'),
    password: Yup.string().required(ValidFields?.PASSWORD?.REQUIRED),
    captch:Yup.string().required('Security Code is required')
})

export const LoginInitialform = {
    UID: "",
    password: "",
    captch:''
}