import React  from "react";
import {FeeSubmissionIndex} from "@Features/client/fee-submission";

export const FeeSubmissionPage = () => {


  return (
    <div>
       <FeeSubmissionIndex/>
    </div>
  );
};
