import { getDataFromLocalStorage } from "@Src/utils/globalUtilities";
import axios from "axios";

const unauthorizedCode = [401];

export const NodeBaseService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_NODE_BASE_URL,
});

NodeBaseService.interceptors.request.use(
  (config: any) => {
    const token = getDataFromLocalStorage('authorization');
    if (token) {
      config.headers = {
        authorization: token,
      };
    }
    return config;
    // config.headers = {
    //     		'authorization ': 'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6IjMiLCJlbWFpbCI6IjMiLCJ0aW1lU3RhbXAiOjE2ODE5MTE0MjI0MjQsIm5iZiI6MTY4MTkxMTQyMiwiZXhwIjoxNjg0NTAzNDIyLCJpYXQiOjE2ODE5MTE0MjIsImlzcyI6Ijg3M2IxYWRkLTcwMzQtNDYyNy04NzgzLTA1M2Q0N2JiODE0OCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NjQwNjQvIn0.G2sMfBodWz4-QSm2Ps5Gavms84MvGh4JRoDWxCJ6A1I'
    //     	}
    // return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

NodeBaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      // logoutUserSession()
    }
    return Promise.reject(error);
  }
);
