import { store } from '@Src/app/store';
import { Dialog } from 'primereact/dialog';

export const VerifyFeePaymentFormAlreadyFilledDialog = ({ visibleVerifyPaymentAlreadyFilled, setVisibleVerifyPaymentAlreadyFilledHandler }: any) => {

  console.log('visibleVerifyPaymentAlreadyFilled' , visibleVerifyPaymentAlreadyFilled)
    return (
        <Dialog header="" draggable={false} visible={visibleVerifyPaymentAlreadyFilled} onHide={() => setVisibleVerifyPaymentAlreadyFilledHandler(false)}>
            <div className='group-form mt-3'>
              {store.getState().global.offlineFeePaymentStatus == 1 &&<div>
               Bank details are already Submitted for verification
               </div> }
               {store.getState().global.offlineFeePaymentStatus == 3 &&<div>
                 Your Payment has been rejected
               </div> }
            </div>
        </Dialog>
    );
}