
import { useState } from 'react';
import './registerSuccesful.scss';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import { useAppSelector } from '@Src/app/hooks/hooks';
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"


export const RegisterationSuccessIndex = () => {
    const navigate = useNavigate()
    const [showcopied, setShowcopied] = useState(false)
    const userUId = useAppSelector(state => state.register.userUID)
    const userDetails: any = useAppSelector(state => state.register.UserDetails)

    const handlercopy = (text: any) => {
        navigator.clipboard.writeText(text)
        setShowcopied(true)
        setTimeout(() => {
            setShowcopied(false)
        }, 1000)
    }

    console.log(userDetails)


    return (
        <>
            {/* registeration form */}
            <div className="empty-data regist-sucss">
                <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
                <div className="empty-data-details">
                    <h2 className="empty-data-h1"> First Step Registration </h2>
                    <p className="empty-data-text-1"> Dear {userDetails?.Result?.Salutation == 1 && 'Mr. '}
                        {userDetails?.Result?.Salutation == 2 && 'Miss. '}
                        {userDetails?.Result?.Salutation == 3 && 'Mrs. '}
                        {userDetails?.Result?.Salutation == 4 && 'Dr. '}
                        {userDetails?.Result?.Salutation == 5 && 'Prof. '}
                        {userDetails?.Result?.Salutation == 6 && 'Senior Prof. '}
                        {userDetails && userDetails.Result.FirstName}, Thank you  <br />
                        Your provisional registration has been accepted. To pay the registration fee and complete further steps please login.
                        <span className='br-span'>Your UID is</span>
                        <span className='uia-copy-card'>{userUId && userUId}
                            <span className='copy-svg' onClick={() => handlercopy(userUId)}>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_436_28425)">
                                        <path d="M7.5 6V3C7.5 2.73478 7.60536 2.48043 7.79289 2.29289C7.98043 2.10536 8.23478 2 8.5 2H20.5C20.7652 2 21.0196 2.10536 21.2071 2.29289C21.3946 2.48043 21.5 2.73478 21.5 3V17C21.5 17.2652 21.3946 17.5196 21.2071 17.7071C21.0196 17.8946 20.7652 18 20.5 18H17.5V21C17.5 21.552 17.05 22 16.493 22H4.507C4.37513 22.0008 4.2444 21.9755 4.12232 21.9256C4.00025 21.8757 3.88923 21.8022 3.79566 21.7093C3.70208 21.6164 3.62779 21.5059 3.57705 21.3841C3.52632 21.2624 3.50013 21.1319 3.5 21L3.503 7C3.503 6.448 3.953 6 4.51 6H7.5ZM5.503 8L5.5 20H15.5V8H5.503ZM9.5 6H17.5V16H19.5V4H9.5V6Z" fill="#1C7CED" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_436_28425">
                                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>

                            <span className='copy-tag'>{showcopied ? 'Copied' : 'Copy'}</span>

                        </span>
                        <span >Please save UID for future reference</span> </p>
                    <div className='rember-uid-tag'>Please make sure you remember your UID <br /> mentioned above. It is required while logging in.</div>
                    <div> <button className="button-primary active" onClick={() => navigate(AppRouteURL.LOGIN)}> Continue to Login <i className="pi pi-angle-right ml-1"></i> </button> </div>
                </div>
            </div>

        </>
    )
}