import { Currency, PaymentModes } from '@Constants/constants';
import { useAppDispatch , useAppSelector} from '@Src/app/hooks/hooks';
import { initializeScript, removeScript } from '@Src/utils/globalUtilities';
import React, { useEffect, useState } from 'react';
import { saveTempOrder, postProductOrder, ipAddress } from '../client/services/client.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { hideSpinner, setPaymentStatus, setTempOrderDetails, showSpinner} from '@Src/app/reducers/global/globalSlice';

declare var Razorpay: Function;
export const PaymentGatewayIndex = ({ totalPrice }: any) => {
    const [cartDetailsResponse, setCartDetailsResponse] = useState<any>();
    const [saveorder, setSaveOrder] = useState<any>();
    const [userSelectedPaymentMode, setUserSelectedPaymentMode] = useState<PaymentModes>(PaymentModes.RAZORPAY)
    const [useripaddress , setUseripaddress] =  useState<any>();
    const dispatch = useAppDispatch();
    const firstname = useAppSelector(state => state.login.FirstName)
    const lastname = useAppSelector(state => state.login.Lastname)
    const email = useAppSelector(state => state.login.email)
    useEffect(() => {
        // initializeRazorpayCheckout()
         getUserIpAddress()
        // return () => {
        //     removeScript('razorpayScript');
        // };
    }, []);

    // useEffect(() => {
    //     if (totalPrice > 10) {
    //         saveTempOrderApi()
    //     }
    // }, [totalPrice])

    function initializeRazorpayCheckout() {
        initializeScript('razorpayScript', "https://checkout.razorpay.com/v1/checkout.js");
    }
    function getUserIpAddress() {
        ipAddress().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setUseripaddress(resp.data[0])
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    function saveTempOrderApi() {
        let data = {
            "IPAddress": useripaddress?.ipAddress,
            "SessionId": crypto.randomUUID(),
            "OrderAmount": totalPrice,
            "Currency": 1,
            "GSTAmount": 0
        }
        saveTempOrder(data).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setSaveOrder(resp?.data?.data);
                dispatch(setTempOrderDetails(resp.data?.data));
                let url = resp.data?.data?.instrumentResponse?.redirectInfo?.url; 
                window.open( url,"_self")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    function payWithRazorPay() {
        let options: any = {
            'key': 'rzp_test_qxZW6zdMVeKlpf',//environment[this.env].RAZORPAY_KEY,
            'amount': totalPrice * 100, // to lowest denomination 2000 paise = INR 20 or 200 cents 2$
            'name': 'ICA Conference',
            'description': 'Purchase',
            'order_id': saveorder?.RazorPayOrderID, // in response of save order temp
            'image': 'this.imageUrl' + 'logo.jpg',
            'handler': (razorPaySuccessEvent: any) => {
                onRazorPaySuccess(razorPaySuccessEvent, saveorder?.OrderNo);
            },
            'prefill': {
                'name': firstname + ' ' + lastname || '',
                'email': email
            },
            'notes': {
                '': saveorder?.OrderNo
            },
            'theme': {
                'color': 'orange'
            },
        };
        
        console.log(options)
        options['currency'] = 'INR';
        let rzp1 = Razorpay(options);
        rzp1.open();
        rzp1.on('payment.failed', (res: any) => {
            // navigate(AppRouteURL.PAYMENT_FAILED + "/" + orderNo)

            /**
             *`{"code":"BAD_REQUEST_ERROR",
                            "description":"Your payment didn't go through due to a temporary issue. Any debited amount will be refunded in 4-5 business days.",
                            "source":"issuer",
                            "step":"payment_authorization",
                            "reason":"payment_failed","
                            metadata":{"payment_id":"pay_LWpl9YSwO58C75","order_id":"order_LWpl1emPWaxqU5"}}`
             */
            // this.netCorePayload = {
            //     transactionID: res.error.metadata.order_id,
            //     transactionAmount: 'INR ' + this.paybleAmount,
            //     transactionDate: moment(new Date()).format("MM/DD/YYYY"),
            //     transactionTime: moment(new Date()).format("hh:mm a"),
            //     paymentMode: 'RAZORPAY'
            // }
            // this.netCorePayload = { ...this.netCorePayload, ...this.utmObj };
            // this.appUtilityService.netCoreEventTrack(NETCORE_EVENT_LIST.PAYMENT_FAILED, this.netCorePayload)
        })
    }


    function onRazorPaySuccess(razorPaySuccessEvent: any, orderNo: any) {
        dispatch(showSpinner());
        console.log(razorPaySuccessEvent, 'success eventtt')

        const payload = {
            "OrderNo": '20230802115529678',
            "TransactionId": razorPaySuccessEvent?.razorpay_payment_id,
            "PaymentMode": 1,
            "IsPayment": 1
        }

        postProductOrder(payload).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                dispatch(setPaymentStatus(1));
            }else{
                dispatch(hideSpinner())
            }
        }).catch(error => {
            dispatch(hideSpinner())
            console.log(error)
        })

    }



    return (

        <>
                    {/* <a href='javascript:void(0)' onClick={() => payWithRazorPay()} className='button-primary active mb-3 lg:mb-0'> Submit <i className="pi pi-chevron-right ml-3"></i> </a> */}
            <a href='javascript:void(0)' onClick={() => saveTempOrderApi()} className='button-primary active mb-3 lg:mb-0'> Submit <i className="pi pi-chevron-right ml-3"></i> </a>
        </>
    )


}