import React  from "react";
import {TravelDetailsIndex} from "@Features/client/travel-details";

export const TravelDetailsPage = () => {


  return (
    <div>
       <TravelDetailsIndex/>
    </div>
  );
};
