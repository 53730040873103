import { ResetPasswordIndex } from "@Src/features/client/reset-password"



export const ResetPasswordPage = () => {

 return (
    <>
    <ResetPasswordIndex/>
    </>
 )

}