import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InitialVerifyFeePayment, VerifyFeePaymentSchema } from '../schema/feesubmit.schema';
import { hideSpinner, setOfflineFeePaymentStatus, showSpinner } from '@Src/app/reducers/global/globalSlice';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { useAppDispatch } from '@Src/app/hooks/hooks';
import { addVerifyPayment, userStatus } from '../../services/client.service';
import { Calendar } from 'primereact/calendar';
import toast from 'react-hot-toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Salutationenum } from '../../register/constants/register.constant';
import { useState } from 'react';

export const VerifyFeePaymentFormDialog = ({ visibleVerifyPayment, setVisibleVerifyPaymentHandler, verifyOfflineFeePaymentFormStatusHandler }: any) => {
    const dispatch = useAppDispatch()
    const [imageprooffilename, setimageprooffilename] = useState<any>()
    const [imageprooffileuploaded, setimageProoffileuploaded] = useState<any>()

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue, resetForm } = useFormik({
        initialValues: InitialVerifyFeePayment,
        validationSchema: VerifyFeePaymentSchema,
        onSubmit: (e) => {
            console.log(e)
            VerifyPaymentFormSubmit(e);
        },
    });

    const VerifyPaymentFormSubmit = (e: any) => {
        let data = {
            "Salutation": e.Salutation,
            "UserName": e.UserName,
            "Mobile": e.Mobile,
            "TransactionNo": e.TransactionNo,
            "BankName": e.BankName,
            "PaymentDate": extractDateString(e.PaymentDate),
        }
        const formdata:any = new FormData()
        formdata.append('paymentProof' , imageprooffileuploaded)
        formdata.append('payload' , JSON.stringify(data))
        dispatch(showSpinner())
        addVerifyPayment(formdata).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                onClickClosePaymentHandler();
                getUserStatus();
                toast.success("Payment Fee Details Submitted successfully")
            }
        }).catch((error: any) => {
            dispatch(hideSpinner())
            console.log(error);
        })
    }

    function getUserStatus() {
        dispatch(showSpinner())
        userStatus().then((resp: APIResponse) => {
            dispatch(hideSpinner())
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(setOfflineFeePaymentStatus(resp.data.FormSubmmitedForPayment));
                verifyOfflineFeePaymentFormStatusHandler(resp.data);
            }
        }).catch((error: any) => {
            dispatch(hideSpinner())
            console.log(error);
        })
    }

    function extractDateString(date: any) {
        const dateObject = new Date(date);
        // Get the year, month, and day from the Date object
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
        const day = dateObject.getDate();
        // Format the date in 'YYYY-MM-DD' format
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
        return formattedDate
    }

    const onClickClosePaymentHandler = () => {
        setVisibleVerifyPaymentHandler(false);
        resetForm();
    }

    const uploadFiles = (filesToUpload: FileList | any) => {
        let file = filesToUpload[0]
        //const fileSizeError: any = checkFileSize(file);
        // if (fileSizeError) {
        //     toast.error('File size is too large');
        //     if (fileCategory === FileCategory.ABSTRACT) {
        //         let clearFileUpload: any = document.getElementById('FileInputAbstract')
        //         clearFileUpload.value = ''
        //     }


        //     return;
        // }

        const fileTypeError: any = checkAllowedExtentions(file);
        if (fileTypeError) {
            toast.error('Only jpg and png format is allowed');
            return;
        } else {
            setimageprooffilename(file.name)
            setimageProoffileuploaded(file)
            const formdata:any = new FormData()
        formdata.append('Proof' , imageprooffileuploaded)
        console.log('forma', formdata)

        }
    }

    function checkAllowedExtentions(category: any) {
        const allowedFileExtensions: string[] = ["jpg", "png", 'image/png', 'image/jpeg'];
        const extensionchek: any = allowedFileExtensions.includes(category.type)
        if (!extensionchek) {
            return true;
        }
        return false;
    }

    const removeuploaded = () => {
        setimageProoffileuploaded(null)
    }

    return (
        <Dialog header="Verify Fee Payment" draggable={false} visible={visibleVerifyPayment} onHide={() => onClickClosePaymentHandler()}>
            <div className='group-form mt-3'>
                <form onSubmit={handleSubmit}>
                    <div className='group-form-row'>
                        <div className='group-form-colls'>
                            <label className='form-label-1'>Salutation </label>
                            <div>
                                <Dropdown value={values.Salutation}
                                    onChange={(e) => setFieldValue('Salutation', e.value)}
                                    options={Salutationenum}
                                    optionLabel="name"
                                    optionValue='id'
                                    name='Salutation'
                                    placeholder="Select Salutation" className="w-full" />                  </div>
                        </div>
                        <div className='group-form-colls'>
                            <label className='form-label-1'> Name </label>
                            <input type='text' className='form-control-t1' placeholder='Enter name of the Registered person'
                                name='UserName'
                                id='UserName'
                                value={values.UserName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {(errors.UserName && touched.UserName) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.UserName}</p> : null}
                        </div>
                    </div>
                    <div className='group-form-row'>
                        <div className='group-form-colls'>
                            <label className='form-label-1'> Mobile No. </label>
                            <InputText type='text' className='form-control-t1' placeholder='Enter Mobile No.'
                                name='Mobile'
                                id='Mobile'
                                value={values.Mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={10}
                                keyfilter='int'

                            />
                            {(errors.Mobile && touched.Mobile) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Mobile}</p> : null}
                        </div>
                        <div className='group-form-colls'>
                            <label className='form-label-1'> Date of Payment </label>
                            <Calendar
                                value={values.PaymentDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='PaymentDate'
                                id="PaymentDate"
                                className="w-full  custom-calendar"
                                maxDate={new Date()}
                                placeholder='DD/MM/YYYY' showIcon />
                            {(errors.PaymentDate && touched.PaymentDate) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.PaymentDate}</p> : null}

                        </div>
                    </div>
                    <div className='group-form-row'>
                        <div className='group-form-colls'>
                            <label className='form-label-1'> Bank Name </label>
                            <input type='text' className='form-control-t1' placeholder='Please Enter the name of the bank'
                                name='BankName'
                                id='BankName'
                                value={values.BankName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {(errors.BankName && touched.BankName) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.BankName}</p> : null}
                        </div>
                    </div>
                    <div className='group-form-row'>
                        <div className='group-form-colls'>
                            <label className='form-label-1'>Photo Proof</label>

                            <div>

                                {!imageprooffileuploaded ? <label className="upload-files-ui">
                                    <span className='upload-files-pic'>
                                        <i className="pi pi-cloud-upload"></i>
                                    </span>
                                    <span className='file-upload-titles'>
                                        <span className="file-upload-title-1">
                                            Upload Photo Proof
                                        </span>
                                        <span className="file-upload-title-2">
                                            Only .jpg or .png format
                                        </span>
                                    </span>
                                    <input className="FileUpload1"
                                        onChange={(e) => {
                                            uploadFiles(
                                                e?.target?.files
                                            )
                                        }} id="FileInputAbstract" name="booking_attachment" type="file" />

                                </label> :
                                    <div className='uploaded-card'>
                                        <div className='upload-file'>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.9998 30.3334H11.9998C4.75984 30.3334 1.6665 27.24 1.6665 20V12C1.6665 4.76002 4.75984 1.66669 11.9998 1.66669H18.6665C19.2132 1.66669 19.6665 2.12002 19.6665 2.66669C19.6665 3.21335 19.2132 3.66669 18.6665 3.66669H11.9998C5.85317 3.66669 3.6665 5.85335 3.6665 12V20C3.6665 26.1467 5.85317 28.3334 11.9998 28.3334H19.9998C26.1465 28.3334 28.3332 26.1467 28.3332 20V13.3334C28.3332 12.7867 28.7865 12.3334 29.3332 12.3334C29.8798 12.3334 30.3332 12.7867 30.3332 13.3334V20C30.3332 27.24 27.2398 30.3334 19.9998 30.3334Z" fill="#292D32" />
                                                <path d="M29.3332 14.3333H23.9998C19.4398 14.3333 17.6665 12.56 17.6665 8.00001V2.66667C17.6665 2.26667 17.9065 1.89334 18.2798 1.74667C18.6532 1.58667 19.0798 1.68001 19.3732 1.96001L30.0398 12.6267C30.3198 12.9067 30.4132 13.3467 30.2532 13.72C30.0932 14.0933 29.7332 14.3333 29.3332 14.3333ZM19.6665 5.08001V8.00001C19.6665 11.44 20.5598 12.3333 23.9998 12.3333H26.9198L19.6665 5.08001Z" fill="#292D32" />
                                                <path d="M17.3335 18.3333H9.3335C8.78683 18.3333 8.3335 17.88 8.3335 17.3333C8.3335 16.7866 8.78683 16.3333 9.3335 16.3333H17.3335C17.8802 16.3333 18.3335 16.7866 18.3335 17.3333C18.3335 17.88 17.8802 18.3333 17.3335 18.3333Z" fill="#292D32" />
                                                <path d="M14.6668 23.6667H9.3335C8.78683 23.6667 8.3335 23.2134 8.3335 22.6667C8.3335 22.12 8.78683 21.6667 9.3335 21.6667H14.6668C15.2135 21.6667 15.6668 22.12 15.6668 22.6667C15.6668 23.2134 15.2135 23.6667 14.6668 23.6667Z" fill="#292D32" />
                                            </svg>
                                        </div>
                                        <div className='file-name-card'>
                                            <div className='file-name'>{imageprooffilename}</div>
                                            <div className='file-status'>Uploaded</div>
                                        </div>

                                        <span className='remove-upload' onClick={() => removeuploaded()}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_404_30422)">
                                                    <path d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z" fill="#ED1C24" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_404_30422">
                                                        <rect width="24" height="24" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            Remove
                                        </span>

                                    </div>}

                            </div>
                        </div>
                    </div>
                    <div className='group-form-row'>
                        <div className='group-form-colls'>
                            <label className='form-label-1'> Transaction No </label>
                            <input type='text' className='form-control-t1' placeholder='Please Enter the transaction no of the payment'
                                name='TransactionNo'
                                id='TransactionNo'
                                value={values.TransactionNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {(errors.TransactionNo && touched.TransactionNo) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.TransactionNo}</p> : null}
                        </div>
                    </div>
                    <div className='group-form-row mt-4'>
                        <div className='group-form-colls'>
                            {/* <button type='submit' className='add-person-dialog' >Submit</button> */}
                            <a href='javascript:void(0)' className='button-primary active col-12' > <button type='submit' className='add-person-dialog' >Submit</button> </a>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}