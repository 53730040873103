import React, { useState, useEffect } from "react";
import {SubmissionsIndex} from "@Features/admin/submissions";

export const SubmissionDetailsPage = () => {


    return (
        <div>
            <SubmissionsIndex/>
        </div>
    );
};
