export interface IAPIResponse {
    message: string;
    success: boolean;
    data: any;
}

export enum HeaderTypes {
    MAIN_HEADER= "MAIN_HEADER",
    PAYMENT_HEADER= "PAYMENT_HEADER"
}

export const ErrorResponseTypes  = {
    INVALID_KEY:"INVALID_KEY",
    LIMIT_REACHED:"LIMIT_REACHED",
}

export const SuccessMessages = {
    ADDED_TO_WISHLIST: 'Added to wishlist successfully!',
    REMOVED_FROM_WISHLIST: 'Removed from wishlist successfully!',
    ADDED_TO_CART: 'Added to cart successfully!',
    REMOVED_FROM_CART: 'Removed from cart successfully!',
}


export const ErrorMessages = {
    INVALID_KEY_ERROR_MESSAGE: 'Key is invalid please recheck and make sure key is correct.',
    LIMIT_REACHED_ERROR_MESSAGE: 'This key has reached maximum usage limit. Please try with different key.'
}