import { createSlice  } from '@reduxjs/toolkit';
import {HeaderTypes} from "@Src/types/api-response-interface";

// payment status 0, 1 and 2 => pending, success and failed
const initialState = {
    loading: false,
    user: null,
    loginError: "",
    showProfileSidebar: false,
    showWebSidebar: true,
    showFooter: true,
    manageWebSidebar: true,
    activeHeader: HeaderTypes.MAIN_HEADER,
    paymentstatus:0,
    submitedtraveldetails:0,
    paperAttempted:0,
    feedbackstatus:0,
    againSubmitpaper:false,
    revesionpapersubmit:{},
    tabledropdownpaperStatus:[],
    tempOrderDetails:{},
    offlineFeePaymentStatus:0,
    UserType:0,
    isVerifiedResearchScholar:0,
    donationTempOrderdetails:{},
    editUserDetails:[],
    allowPaperSubmissionFlag:0,
    CertificateAllow:0
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        showSpinner: (state) => {
            state.loading = true;
        },
        hideSpinner: (state) => {
            state.loading = false;
        },
        manageProfileSidebar: (state, action) => {
            state.showProfileSidebar = action.payload;
        },
        setActiveHeader: (state, action) => {
            state.activeHeader = action.payload;
        },
        manageWebSidebar: (state, action) => {
            state.manageWebSidebar = action.payload;
        },
        showWebSidebar: (state, action) => {
            state.showWebSidebar = action.payload;
        },
        showFooter: (state, action) => {
            state.showFooter = action.payload;
        },
        setPaymentStatus:(state , action) => {
            state.paymentstatus = action.payload
        },
        setPaperAttempted:(state , action) => {
            state.paperAttempted = action.payload
        },
        setSubmitedTravelDetails:(state , action) => {
            state.submitedtraveldetails = action.payload
        },
        setFeedbackstatus:(state , action) => {
            state.feedbackstatus = action.payload
        },
        setAgainSubmitpaper:(state , action) => {
            state.againSubmitpaper = action.payload
        },
        setRevisionPaperSubmit:(state , action) => {
            state.revesionpapersubmit = action.payload
        },
        setTableDropdownpaperStatus:(state , action) => {
            state.tabledropdownpaperStatus = action.payload
        },
        setTempOrderDetails:(state , action) => {
            state.tempOrderDetails = action.payload
        },
        setOfflineFeePaymentStatus:(state , action) => {
            state.offlineFeePaymentStatus = action.payload
        },
        setUserType:(state , action) => {
            state.UserType = action.payload
        },
        setisVerifiedResearchScholar:(state , action) => {
            state.isVerifiedResearchScholar = action.payload
        },
        setDonationTempOrderDetails:(state , action) => {
            state.donationTempOrderdetails = action.payload
        },
        setEditUserDetails:(state , action) => {
            state.editUserDetails = action.payload
        },
        setAllowPaperSubmissionFlag:(state , action) => {
            state.allowPaperSubmissionFlag = action.payload
        },
        setCertificateAllow:(state , action) => {
            state.CertificateAllow = action.payload
        },
    }
});

export const { showSpinner, hideSpinner,manageWebSidebar, manageProfileSidebar, setSubmitedTravelDetails ,setDonationTempOrderDetails,setEditUserDetails,
    setActiveHeader, showWebSidebar, showFooter , setPaymentStatus  , setPaperAttempted  , setisVerifiedResearchScholar , setUserType ,setAllowPaperSubmissionFlag,setCertificateAllow,
    setFeedbackstatus , setAgainSubmitpaper , setRevisionPaperSubmit , setTableDropdownpaperStatus,setTempOrderDetails,setOfflineFeePaymentStatus } = globalSlice.actions;
export default globalSlice.reducer;
