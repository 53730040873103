import ApiService from "@Src/services/bookstore/ApiService";
import { ADMIN_SUBMISSION_CONSTANT } from "./submission.constant";


export const adminpaperDetails = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_DETAILS +`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'post',
        data:data
    })
}


export const adminPaperRemark = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_REMARK,
        method:'put',
        data
    })
}

export const allowPaperPresentationCert = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.PAPER_PRESENTATION_CART,
        method:'put',
        data
    })
}

export const adminPaperDownload = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.ADMIN_PAPER_DOWNLOAD,
        method:'post',
        data
    })
}

export const paperdetailscontpost = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.PAPER_DETAILS_COUNT +`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'post',
        data
    })
}

export const adminEditUserDetails = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_SUBMISSION_CONSTANT.EDIT_USER_DETAILS_API,
        method:'put',
        data
    })
}

