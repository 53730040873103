import RigthArrowSvg from "@Src/assets/svg/commonsvg/rightarrow.svg"
import { adminMasterReport, adminMasterListCount, adminDownloadMasterReport } from "../service/masterreport.service"
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces"
import { useEffect, useState } from "react"
import "./list.scss"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useNavigate } from "react-router-dom"
import { AppRouteURL } from "@Src/routes/app-route-urls"
import { DownloadReport } from "../downloadreport"
import { InputText } from "primereact/inputtext"


export const MasterReportListIndex = () => {
  const navigate = useNavigate()
  const [masterreportdata, setMasterreportdata] = useState()
  const [masterreportdownload, setMasterreportdownload] = useState<any>()
  const [feepayemntFilter, setFeepayemntFilter] = useState(false)
  const [accompanyinfFilter, setAccompanyinfFilter] = useState(false)
  const [travelFilter, setTravelFilter] = useState(false)
  const [paperSubmitedFilter, setPaperSubmitedFilter] = useState(false)
  const [feedbackFilter, setFeedbackFilter] = useState(false)
  const [certificateFilter, setCertificateFilter] = useState(false)
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchuser, setSearchUser] = useState('');
  const [masterreportcountUID, setMasterreportcountUID] = useState();
  useEffect(() => {
    MasterReportFilter()
    getReportCount()
    MasterReportDownload()
  }, [currentPage, rows, searchuser, feepayemntFilter, accompanyinfFilter, travelFilter, paperSubmitedFilter, feedbackFilter, certificateFilter])



  function genderBodyTemplate(rowData: any) {
    return (
      <>
        {(rowData.Gender === 1) && <span>Male</span>}
        {(rowData.Gender === 2) && <span>Female</span>}
        {(rowData.Gender === 3) && <span>Other</span>}
      </>
    )
  }
  function traveldetailsBodyTemplate(rowData: any) {
    return (
      <div>
        {(rowData.TravelDetailsSubmitted === 0) && <span
          className={`button-primary active 
         ${rowData.TravelDetailsSubmitted === 0 && 'b-rejected'}`}>Not Submitted</span>
        }
        {(rowData.TravelDetailsSubmitted === 1) && <span
          className={`button-primary active 
         ${rowData.TravelDetailsSubmitted === 1 && 'b-completed'}`}>Submitted</span>
        }
      </div>
    )
  }

  function feepaymentBodyTemplate(rowData: any) {
    return (
      <div>
        {(rowData.FeePayment === 0) && <span
          className={`button-primary active 
         ${rowData.FeePayment === 0 && 'b-rejected'}`}>Not Done</span>
        }
        {(rowData.FeePayment === 1) && <span
          className={`button-primary active 
         ${rowData.FeePayment === 1 && 'b-completed'}`}>Done</span>
        }
      </div>
    )
  }

  function MasterReportFilter() {
    let payload = {
      filters: {
        "filters": {
          "feePayment": feepayemntFilter ? 1 : 0,
          "emailOrUsername": searchuser.trim(),
          "accompanyingPerson": accompanyinfFilter ? 1 : 0,
          "travelDetails": travelFilter ? 1 : 0,
          "paperSubmitted": paperSubmitedFilter ? 1 : 0,
          "feedback": feedbackFilter ? 1 : 0,
          "certificateDownload": certificateFilter ? 1 : 0,
        }
      },
      pageNo: currentPage,
      pageSize: rows
    }
    adminMasterReport(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setMasterreportdata(resp.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  function MasterReportDownload() {
    let payload = {
      filters: {
        "filters": {
          "feePayment": feepayemntFilter ? 1 : 0,
          "emailOrUsername": searchuser.trim(),
          "accompanyingPerson": accompanyinfFilter ? 1 : 0,
          "travelDetails": travelFilter ? 1 : 0,
          "paperSubmitted": paperSubmitedFilter ? 1 : 0,
          "feedback": feedbackFilter ? 1 : 0,
          "certificateDownload": certificateFilter ? 1 : 0,
        }
      }
    }
    adminDownloadMasterReport(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setMasterreportdownload(resp.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  function getReportCount() {
    let payload = {
      filters: {
        "filters": {
          "feePayment": feepayemntFilter ? 1 : 0,
          "emailOrUsername":  searchuser.trim(),
          "accompanyingPerson": accompanyinfFilter ? 1 : 0,
          "travelDetails": travelFilter ? 1 : 0,
          "paperSubmitted": paperSubmitedFilter ? 1 : 0,
          "feedback": feedbackFilter ? 1 : 0,
          "certificateDownload": certificateFilter ? 1 : 0,
        }
      },
      pageNo: currentPage,
      pageSize: rows
    }
    adminMasterListCount(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setTotalRecords1(resp?.data?.countData)
        setMasterreportcountUID(resp?.data?.countUID)
      }
    }).catch(error => {
      console.log(error)
    })

  }

  function resethandler() {
    setFeepayemntFilter(false)
    setAccompanyinfFilter(false)
    setTravelFilter(false)
    setPaperSubmitedFilter(false)
    setFeedbackFilter(false)
    setCertificateFilter(false)
    setSearchUser('')
  }

  const onPageInputChange = (event: any) => {
    if (event) {
      setFirst(event.first);
      setRows(event.rows);
      setCurrentPage(event.page + 1);
    }
  };

  function arrivalBodyTemplate(rowData: any) {
    return (
      <>
        {(rowData.TravelDetailsSubmitted === 1) && <span className="travel-data">
          Date: {rowData.ArrivalTravelDetailsDate}<br />
          Mode: {rowData.ArrivalTravelType} <br />
          {rowData.ArrivalTravelType == 'Air' && <span>Flight No.: {rowData.ArrivalFlightTrainNo}  <br /></span>}
          {rowData.ArrivalTravelType == 'Train' && <span>Train No.: {rowData.ArrivalFlightTrainNo}  <br /></span>}
          Time of Arrival: {rowData.ArrivalTravelDetailsTime} <br />
          Place: {rowData.ArrivalTravelDetailsPlace} <br />
          {rowData.ArrivalTravelType == 'Air' && <span>Arrival Terminal: {rowData.ArrivalTerminal == "T1" && "Terminal 1"}
            {rowData.ArrivalTerminal == "T2" && "Terminal 2"}
            {rowData.ArrivalTerminal == "T3" && "Terminal 3"}</span>}
        </span>}

      </>
    )
  }

  function departureBodyTemplate(rowData: any) {
    return (
      <>
        {(rowData.TravelDetailsSubmitted === 1) && <span className="travel-data">
          Date: {rowData.DepartureTravelDetailsDate}<br />
          Mode: {rowData.DepartureTravelType} <br />
          {rowData.DepartureTravelType == 'Air' && <span>Flight No.:{rowData.DepartureFlightTrainNo} <br /></span>}
          {rowData.DepartureTravelType == 'Train' && <span>Train No.: {rowData.DepartureFlightTrainNo}  <br /></span>}
          Time of Arrival:{rowData.DepartureTravelDetailsTime} <br />
          Place: {rowData.DepartureTravelDetailsPlace} <br />
          {rowData.DepartureTravelType == 'Air' && <span>
            Departure Terminal: {rowData.DepartureTerminal}
            {rowData.DepartureTerminal == "T1" && "Terminal 1"}
            {rowData.DepartureTerminal == "T2" && "Terminal 2"}
            {rowData.DepartureTerminal == "T3" && "Terminal 3"}
          </span>}
        </span>}

      </>
    )
  }

  function papersubmisssionBodyTemplate(rowData: any) {
    return (
      <>
        <div>
          {(rowData.PaperSubmitted === 0) && <span
            className={`button-primary active 
         ${rowData.PaperSubmitted === 0 && 'b-rejected'}`}>Not Done</span>
          }
          {(rowData.PaperSubmitted === 1) && <span
            className={`button-primary active 
         ${rowData.PaperSubmitted === 1 && 'b-completed'}`}>Done</span>
          }
        </div>

      </>
    )
  }

  function feedbacksubmitbodytemplate() {
    return (
      <>
        <span>No</span>
      </>
    )
  }

  function certificatedownloadbodytemplate() {
    return (
      <>
        <span>No</span>
      </>
    )
  }

  function NameBodyTemplate(rowData: any) {
    return (
      <>
        {rowData?.Salutation == 1 && 'Mr. '}
        {rowData?.Salutation == 2 && 'Miss. '}
        {rowData?.Salutation == 3 && 'Mrs. '}
        {rowData?.Salutation == 4 && 'Dr. '}
        {rowData?.Salutation == 5 && 'Prof. '}
        {rowData?.Salutation == 6 && 'Senior Prof. '}
        {rowData?.Name}

      </>
    )
  }


  return (
    <>
      <div>
        <div className='page-header-1'>
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
            <div className="tw-flex tw-items-center">
              <img className="tw-mr-1 tw-cursor-pointer" onClick={() => navigate(`/${AppRouteURL.SUBMISSION_DETAILS}`)} src={RigthArrowSvg} alt="RigthArrowSvg" />
              <h2 className='master-head'>
                Master Report
              </h2>
            </div>
            <div >
              <DownloadReport masterreportdownload={masterreportdownload} />
            </div>
          </div>


          {masterreportcountUID && <div className="uuid-master">
            Unique  Registration:  {masterreportcountUID}
          </div>}


          <div className="filter-boxes">
            <InputText
              value={searchuser}
              onChange={(e) => setSearchUser(e.target.value)}
              name='search'
              className='form-control-t1 md:tw-w-40  tw-w-full'
              placeholder='Search User'
              autoComplete='off'

            />
            <a href="javascript:void(false)" className={`filter-a ${feepayemntFilter ? 'active-filter' : ''}`}
              onClick={() => setFeepayemntFilter(!feepayemntFilter)}>Fee Payment</a>
            <a href="javascript:void(false)" className={`filter-a ${accompanyinfFilter ? 'active-filter' : ''}`}
              onClick={() => setAccompanyinfFilter(!accompanyinfFilter)}>Accompanying Person</a>
            <a href="javascript:void(false)" className={`filter-a ${travelFilter ? 'active-filter' : ''}`}
              onClick={() => setTravelFilter(!travelFilter)}>Travel Details</a>
            <a href="javascript:void(false)" className={`filter-a ${paperSubmitedFilter ? 'active-filter' : ''}`}
              onClick={() => setPaperSubmitedFilter(!paperSubmitedFilter)}>Paper Submitted</a>
            <a href="javascript:void(false)" className={`filter-a ${feedbackFilter ? 'active-filter' : ''}`}
              onClick={() => setFeedbackFilter(!feedbackFilter)}>Feedback</a>
            <a href="javascript:void(false)" className={`filter-a ${certificateFilter ? 'active-filter' : ''}`}
              onClick={() => setCertificateFilter(!certificateFilter)}>Certificate Downloads</a>
          </div>
          <div className="tw-text-right tw-mt-3">
            <a href="javascript:void(false)" className="reset-all-filter tw-mt-6" onClick={() => resethandler()}>Reset</a>
          </div>

        </div>
        <div className='page-content tw-mt-8'>
          <div className='table-format table-auto'>
            {masterreportdata && <DataTable
              value={masterreportdata}
              paginator
              style={{ height: "auto" }}
              rows={rows}
              first={first}
              totalRecords={totalRecords1}
              onPage={onPageInputChange}
              rowHover
              lazy
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown"
              rowsPerPageOptions={[10, 25, 50, 100]}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              showGridlines
              scrollable
              scrollHeight="400px"
              className="admin-paper-details" tableStyle={{ minWidth: '50rem' }}>
              <Column field="RowNum" header="SNo." />
              <Column field="UID" header="UID" />
              <Column field="IcaMembershipNumber" header="ICA Life Membership no" />
              <Column field="Name" header="Name" body={NameBodyTemplate} />
              <Column field="Gender" header="Gender" body={genderBodyTemplate} />
              <Column field="EmailId" header="Email" />
              <Column field="Mobile" header="Mobile" />
              <Column field="Designation" header="Designation" />
              <Column field="Department" header="Department" />
              <Column field="SessionName" header="Session" />
              <Column field="TitleOfPaper" header="Paper Title" style={{ width: '150px' }} />
              <Column field="FeePayment" body={feepaymentBodyTemplate} header="Fee Payment" />
              <Column field="NoOfAccompany" header="Accompanying Person" />
              <Column field="TravelDetailsSubmitted" body={traveldetailsBodyTemplate} header="Travel Details" />
              <Column field="TravelDetailsSubmitted" style={{ maxWidth: '300px', whiteSpace: 'nowrap' }} body={arrivalBodyTemplate} header="Arrival Details" />
              <Column style={{ maxWidth: '300px', whiteSpace: 'nowrap' }} field="TravelDetailsSubmitted" body={departureBodyTemplate} header="Departure Details" />
              <Column field="PaperSubmitted" body={papersubmisssionBodyTemplate} header="Paper Submission" />
              <Column header="Feedback Submitted" body={feedbacksubmitbodytemplate} />
              <Column header="Certificate DOwnloaded" body={certificatedownloadbodytemplate} />
            </DataTable>}
          </div>
        </div>
      </div>

    </>
  )


}