
import { ValidFields } from '@Src/utils/messages/validation.contant';
import { globalRegex } from '@Src/utils/regex/regex.constant';
import * as Yup from 'yup';


export const UpdatepasswordSchema = Yup.object({
    Password: Yup.string().min(ValidFields?.PASSWORD?.MIN, ValidFields?.PASSWORD?.SHORT_MESSAGE).required(ValidFields?.PASSWORD?.REQUIRED).matches(globalRegex?.PASSWORD?.REGEX, globalRegex?.PASSWORD?.MESSAGE),
    conformpassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match').min(ValidFields?.PASSWORD?.MIN, ValidFields?.PASSWORD?.SHORT_MESSAGE).required(ValidFields?.PASSWORD?.REQUIRED).matches(globalRegex?.PASSWORD?.REGEX, globalRegex?.PASSWORD?.MESSAGE),
})

export const UpdatepasswordInitialform = {
    Password: "",
    conformpassword:""
}


export const ResetPasswordSchema = Yup.object({
    Email: Yup.string().required(ValidFields?.EMAIL?.REQUIRED),
    captch:Yup.string().required('Security Code is required')
})

export const ResetPasswordInitialform = {
    Email: "",
    captch:''
}


