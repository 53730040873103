import ApiService from "@Src/services/bookstore/ApiService";
import { REGISTER_CONSTANT_API_ROUTES } from "./register.constant";


export const getDesignation = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_DESIGNATION,
        method:'get',
        params:''
    })
}
 
export const getdonationsponsorship = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.DONATION_SPONSOR_SHIP_TYPE,
        method:'get',
        params:''
    })
}

export const getCountry = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_COUNTRY,
        method:'get',
        params:''
    })
}

export const getCountryCode = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_COUNTRY_CODE,
        method:'get',
        params:''
    })
}


export const getDepartment = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_DEPARTMENT,
        method:'get',
        params:''
    })
}

export const getState = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_STATE,
        method:'get',
        params:''
    })
}

export const getRelations = async ()=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_RELATION,
        method:'get',
        params:''
    })
}

export const postRegister = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_USER_REGISTER,
        method:'post',
        data
    })
}

export const postSponsorDonation = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.POST_DONATION_SPONSORSHIP_API,
        method:'post',
        data
    })
}


export const verifyicamembership = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.VERIFY_ICA_MEMBERSHIP,
        method:'post',
        data
    })
}

export const verifyiwemailid = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.EMAIL_VERIFY,
        method:'post',
        data
    })
}


export const getverifyscholar = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.GET_VERIFY_RESEARCH_SCHOLAR,
        method:'post',
        data
    })
}

export const postverifyscholar = async (data:any)=>{
    return await ApiService.fetchData({
        url:REGISTER_CONSTANT_API_ROUTES.POST_VERIFY_RESEARCHS_SCHOLAR,
        method:'post',
        data
    })
}