import React, { useEffect } from 'react'
import 'primeicons/primeicons.css';
import "./index.scss";
import { Link, useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import { userStatus } from '../services/client.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { useAppDispatch, useAppSelector } from '@Src/app/hooks/hooks';
import { setCertificateAllow, setFeedbackstatus, setOfflineFeePaymentStatus, setPaperAttempted, setPaymentStatus, setSubmitedTravelDetails, setUserType, setisVerifiedResearchScholar } from '@Src/app/reducers/global/globalSlice';
import CompletedSvg from "@Src/assets/svg/homepage/completed.svg"
import { QRScannerIndex } from './component/QrScanner';
import { offlineFeePaymentStatusEnum } from '@Src/app/constants/app.constant';
import { store } from '@Src/app/store';

export const HomePageIndex = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const paymentstatus = useAppSelector(state => state.global.paymentstatus)
    const TravelCount = useAppSelector(state => state.global.submitedtraveldetails)
    const feedback = useAppSelector(state => state.global.feedbackstatus)
    const papersubmited = useAppSelector(state => state.global.paperAttempted)
    const offlineFeePaymentStatus = useAppSelector(state => state.global.offlineFeePaymentStatus)

    useEffect(() => {
        getUserStatus()
    }, [])

    function getUserStatus() {
        userStatus().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(setPaymentStatus(resp.data.FeePaymentCount))
                dispatch(setFeedbackstatus(resp.data.FeedbackCount))
                dispatch(setSubmitedTravelDetails(resp.data.TravelCount))
                dispatch(setPaperAttempted(resp.data.PaperSubmissionCount))
                dispatch(setOfflineFeePaymentStatus(resp.data.FormSubmmitedForPayment))
                dispatch(setUserType(resp?.data?.UserType))
                dispatch(setisVerifiedResearchScholar(resp?.data?.isVerifiedResearchScholar))
                dispatch(setCertificateAllow(resp?.data?.Certificate))
            }
        })
    }


    return (
        <>
            <div className=''>
                <div className='page-info-details'> You have successfully registered! </div>
                <div className='page-header-1'>
                    <h2 className='content-heading-1'> Current Application Status </h2>
                </div>
                <div className='page-content'>
                    <div className='status-tour'>
                        <div className={`status-tour-row compt-status`}>
                            <span className='status-details'>
                                <span className='status-title'> Registration </span>
                                <span className='status-cta'>
                                    <a href='javscript:void(0)' className='button-primary active b-completed'> Completed
                                        <img src={CompletedSvg} alt='CompletedSvg' />
                                    </a>
                                </span>
                            </span>
                        </div>
                        <div className={`status-tour-row ${(paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED) && 'compt-status'}`}>
                            <span className='status-details'>
                                <span className='status-title'> Fee Payment </span>
                                {/* {(paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED) &&
                                    <span className='status-cta'>
                                        <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.FEE_SUBMISSION)} className='button-primary active b-completed'> Completed
                                            <img src={CompletedSvg} alt='CompletedSvg' />
                                        </a>
                                    </span>} */}

                                {/* {(((store.getState().global.UserType != 2 && store.getState().global.isVerifiedResearchScholar == 0) ||
                                    (store.getState().global.UserType == 2 && store.getState().global.isVerifiedResearchScholar == 1)) &&
                                    !(paymentstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                        <Link to={AppRouteURL.FEE_SUBMISSION} className='button-primary active b-pay-fees' > Pay Fees
                                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.879 4.99999L0.166504 1.28749L1.227 0.22699L6 4.99999L1.227 9.77299L0.166504 8.71249L3.879 4.99999Z" fill="white" />
                                            </svg>

                                        </Link>
                                    </span>} */}

                                {/* {((store.getState().global.UserType == 2 && store.getState().global.isVerifiedResearchScholar == 0) &&
                                    !(paymentstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                        <a href='javscript:void(0)' className='button-primary active b-locked'> Locked   <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                        </svg>

                                        </a>
                                    </span>} */}

                                <span className='status-cta'>
                                    <a href='javscript:void(0)' className='button-primary active b-locked'> Closed   <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                    </svg>

                                    </a>
                                </span>




                            </span>
                        </div>
                        <div className={`status-tour-row ${TravelCount === 1 && 'compt-status'}`}>
                            <span className='status-details'>
                                <span className='status-title'> Travel Details </span>
                                {(TravelCount === 0 && !(paymentstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                    <a href='javscript:void(0)' className='button-primary active b-locked'> Locked   <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                    </svg>

                                    </a>
                                </span>}
                                {(TravelCount === 0 && (paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.TRAVEL_DETAILS)} className='button-primary active b-revision'> Continue

                                    </a>
                                </span>}
                                {(TravelCount === 1 && (paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.TRAVEL_DETAILS)} className='button-primary active b-completed'> Completed
                                        <img src={CompletedSvg} alt='CompletedSvg' />
                                    </a>
                                </span>}

                            </span>
                        </div>
                        <div className={`status-tour-row ${papersubmited === 1 && 'compt-status'}`}>
                            <span className='status-details'>
                                <span className='status-title'> Paper Submission </span>
                                {(papersubmited === 0 && !(paymentstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) &&
                                    <span className='status-cta'>
                                        <a href='javscript:void(0)' className='button-primary active b-locked'> Locked
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                            </svg>
                                        </a>
                                    </span>}
                                {(papersubmited === 1 && (paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.PAPER_SUBMISSION)} className='button-primary active b-completed'> Check Status
                                        <img src={CompletedSvg} alt='CompletedSvg' />
                                    </a>
                                </span>}

                                {(papersubmited === 0 && (paymentstatus === 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED)) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.PAPER_SUBMISSION)} className='button-primary active b-revision'> Continue

                                    </a>
                                </span>}
                            </span>
                        </div>
                        <div className={`status-tour-row ${feedback === 1 && 'compt-status'}`}>
                            <span className='status-details'>
                                <span className='status-title'>
                                    <span className='status-title-1'> Feedback Submission </span>
                                    {(feedback === 0 && store.getState().global.CertificateAllow == 0) && <span className='status-title-2'> Available after the event </span>}
                                </span>
                                {(feedback === 0 && store.getState().global.CertificateAllow == 0) && <span className='status-cta'>
                                    <a href='javascript:void(0)' className='button-primary active b-locked'> Locked
                                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                        </svg>
                                    </a>
                                </span>
                                }

                                {(feedback === 0 && store.getState().global.CertificateAllow == 1) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.FEEDBACK)} className='button-primary active b-revision'> Continue

                                    </a>
                                </span>}

                                {feedback === 1 && <span className='status-cta'>
                                    <a href='javscript:void(0)' className='button-primary active b-completed'> Completed
                                        <img src={CompletedSvg} alt='CompletedSvg' />
                                    </a>
                                </span>}

                            </span>
                        </div>
                        <div className='status-tour-row'>
                            <span className='status-details'>
                                <span className='status-title'>
                                    <span className='status-title-1'> Certificate Download </span>
                                    {(feedback === 0 && store.getState().global.CertificateAllow == 0) && <span className='status-title-2'> Available after the event </span>}
                                </span>

                                {(store.getState().global.CertificateAllow == 1) && <span className='status-cta'>
                                    <a href='javscript:void(0)' onClick={() => navigate(AppRouteURL.CERTIFICATE)} className='button-primary active b-revision'> Continue

                                    </a>
                                </span>}
                                {(store.getState().global.CertificateAllow == 0) && <span className='status-cta'>
                                    <a href='javscript:void(0)' className='button-primary active b-locked'> Locked
                                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.75 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25V14.75C14.25 14.9489 14.171 15.1397 14.0303 15.2803C13.8897 15.421 13.6989 15.5 13.5 15.5H1.5C1.30109 15.5 1.11032 15.421 0.96967 15.2803C0.829018 15.1397 0.75 14.9489 0.75 14.75V7.25C0.75 7.05109 0.829018 6.86032 0.96967 6.71967C1.11032 6.57902 1.30109 6.5 1.5 6.5H2.25V5.75C2.25 5.06056 2.3858 4.37787 2.64963 3.74091C2.91347 3.10395 3.30018 2.5252 3.78769 2.03769C4.2752 1.55018 4.85395 1.16347 5.49091 0.899632C6.12787 0.635795 6.81056 0.5 7.5 0.5C8.18944 0.5 8.87213 0.635795 9.50909 0.899632C10.146 1.16347 10.7248 1.55018 11.2123 2.03769C11.6998 2.5252 12.0865 3.10395 12.3504 3.74091C12.6142 4.37787 12.75 5.06056 12.75 5.75V6.5ZM2.25 8V14H12.75V8H2.25ZM6.75 9.5H8.25V12.5H6.75V9.5ZM11.25 6.5V5.75C11.25 4.75544 10.8549 3.80161 10.1517 3.09835C9.44839 2.39509 8.49456 2 7.5 2C6.50544 2 5.55161 2.39509 4.84835 3.09835C4.14509 3.80161 3.75 4.75544 3.75 5.75V6.5H11.25Z" fill="#ED1C24" />
                                        </svg>
                                    </a>
                                </span>}
                            </span>
                        </div>

                    </div>
                    <QRScannerIndex />
                </div>
            </div>
        </>
    )
}