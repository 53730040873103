import { UpdatePasswordIndex } from "@Src/features/client/reset-password/component/updatepassword"




export const  UpdatePasswordPage = () => {
    return (
        <>
        <UpdatePasswordIndex/>
        </>
    )
}