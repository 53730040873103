import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";
import {  HeightAdjustElementType
} from "@Constants/constants";


let userMindlerAuthKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlzbWFpbGtoYW4xM0BtaW5kbGVyLmNvbSIsImF1dGhfaWQiOjI4MjEyNiwiaXNfYWx1bW5pIjoxLCJpYXQiOjE2NTgxMjUwNzV9.oVOXjQdRdwlSQ34vF1yM_lt25XziYDIyFrRYSPKY-cs";

export const encodeString = (value: any) => {
  return jwt_encode(value, process.env.JWT_ENCODE_SECRECT || "");
};

export const decodeString = (value: string) => {
  return jwt_decode(value);
};

export const encodeKeyAndUrl = (route: string) => {
  const decodedObj: any = decodeString(userMindlerAuthKey);
  decodedObj["url"] = route;
  return encodeString(decodedObj);
};

export const setDataOnLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const getDataFromLocalStorage = (key: string) => {
  var value = localStorage.getItem(key);
  if (value === null) {
    return "";
  }
  return value;
};

export const removeDataFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  return;
};

export const isMobile = () => {
  let mediaQueryObj = window.matchMedia("(max-width: 950px)");
  return mediaQueryObj.matches;
};

export function removeScript(scriptId: any) {
  let js;
  const fjs: any = document.getElementById(scriptId);
  fjs.remove();
}

export function initializeScript(scriptId: any, scriptUrl: any) {
  let js;
  const fjs: any = document.getElementsByTagName("script")[0];
  if (document.getElementById(scriptId)) {
    return;
  }
  js = document.createElement("script");
  js.id = scriptId;
  js.async = true;
  js.src = scriptUrl;
  fjs.parentNode.insertBefore(js, fjs);
}

export const adjustViewHeight = (
    key: string,
    type: Number,
    panelType?: string,
    forceFlag?: boolean,
    mobminusHeight: number = 0,
    minusHeight: number = 0
) =>  {
  if (isMobile() && !forceFlag) {
    return
  }
  try {
    let selector
    if (type === HeightAdjustElementType.HTML_ELEMENT) {
      selector = key
    } else if (type === HeightAdjustElementType.HTML_CLASS) {
      selector = `.${key}`
    } else if (type === HeightAdjustElementType.HTML_ID) {
      selector = `#${key}`
    } else {
      selector = key
    }

    let arr = document.querySelectorAll(selector)
    if (selector && arr && arr.length) {
      let windowHeight = window.innerHeight
      Array.from(arr).forEach((element: any) => {
        let elementTop = element.getBoundingClientRect().top
        if (elementTop > 0) {
          let remainingHeight = +windowHeight - +elementTop
          if (remainingHeight > 0) {
            if (panelType === 'middle') {
              element.style.height = `${remainingHeight - 15}px`
            } else if (isMobile()) {
              element.style.height = `${remainingHeight - 15 - mobminusHeight}px`
            } else {
              element.style.height = `${remainingHeight - 15 - minusHeight}px`
            }
          }
        }
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const API_STATUS_MSG_CONSTANT = {
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
    EMAILID_NOT_RECEIVED: "EMAILID_NOT_RECEIVED",
    INVALID_EMAIL_RECEIVED: "INVALID_EMAIL_RECEIVED",
    PASSWORD_CHANGED_SUCCESFULLY: "PASSWORD_CHANGED_SUCCESFULLY",
    SUPPLIED_LOGIN_TOKEN_INVALID: "SUPPLIED_LOGIN_TOKEN_INVALID",
    LOGIN_TOKEN_NOT_RECEIVED: "LOGIN_TOKEN_NOT_RECEIVED",
    PASSWORD_NOT_RECEIVED: "PASSWORD_NOT_RECEIVED",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    EMAIL_REPEATED: "EMAIL_REPEATED",
    ICA_MEMBERSHIP_REPEATED: "ICA_MEMBERSHIP_REPEATED",
    INCORRECT_PASSWORD:'INCORRECT_PASSWORD',
    ID_MISSING:'ACCOMPANYING_PERSON_ID_MISSING',
    NOT_AN_ICA_MEMEBER:'NOT_AN_ICA_MEMEBER',
    REPEATED_ICAMEMBERSHIP_ID:'REPEATED_ICAMEMBERSHIP_ID'

}

export const ApiStatusMessage = {
  ACCOUNT_NOT_FOUND: "Account not found.",
  EMAILID_NOT_RECEIVED: "Email not received.",
  INVALID_EMAIL_RECEIVED: "Invalid email received.",
  PASSWORD_CHANGED_SUCCESFULLY: "Password changed successfully.",
  SUPPLIED_LOGIN_TOKEN_INVALID: "Supplied login token is invalid.",
  LOGIN_TOKEN_NOT_RECEIVED: "Login token not received.",
  PASSWORD_NOT_RECEIVED: "Password not received.",
  USER_NOT_FOUND: "User not found.",
  EMAIL_REPEATED: "Email id already used with other account.",
  ICA_MEMBERSHIP_REPEATED: "ICA membership already used with other account.",
  INCORRECT_PASSWORD:'You have Entered Wrong Password.',
  ACCOMPANYING_PERSON_ID_MISSING:'Accompanying Person id required',
  NOT_AN_ICA_MEMEBER:'Please enter a valid ICA membership number',
  REPEATED_ICAMEMBERSHIP_ID:'ICA membership already used with other account.'
}