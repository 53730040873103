import React  from 'react'
import { Routes, Route } from 'react-router-dom';

import './App.scss';

import {  useAppSelector } from '@App/hooks/hooks';
import Loader from '@Components/loader/Loader';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '@Components/layout/Layout';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { RouteChangeSubscription } from "@Src/utils/subscriptions/route-change/route-change";


const App: React.FC = () => {

	const isSpinnerVisible = useAppSelector(state => state.global);

	return (
		<div>
			<RouteChangeSubscription />
			<Routes>
				<Route path='/*' element={<Layout />} />
			</Routes>

			{isSpinnerVisible.loading ? <Loader /> : null}

			<div>

				<div>
					<Toaster
						position="top-center"
						reverseOrder={false}
					/>
				</div>
			</div>
		</div >
	);
}

export default App;
