
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"
import { AppRouteURL } from "@Src/routes/app-route-urls"
import { useNavigate } from "react-router-dom"


export const DonationPaidSuccessPage = () => {
    const navigate = useNavigate()

    return (
        <>

            <div className="empty-data">
                <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
                <div className="empty-data-details">
                    <h2 className="empty-data-h1"> Donation Successful </h2>
                    <div> <button className="button-primary active" onClick={() => navigate(AppRouteURL.LOGIN)}> Go to Home <i className="pi pi-angle-right ml-1"></i> </button> </div>
                </div>
            </div>

        </>
    )
}