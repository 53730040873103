import ApiService from "@Src/services/bookstore/ApiService"
import { FEEDBACK_CONSTANT } from "./feedback.constant"



export const postfeedback = async (data:any) => {
    return await ApiService.fetchData({
        url:FEEDBACK_CONSTANT.FEED_BACK,
        method:'post',
        data
    })
}

export const getfeedbackList = async () => {
    return await ApiService.fetchData({
        url:FEEDBACK_CONSTANT.FEED_BACK_GET_LIST,
        method:'get',
    })
}