export const AppRouteURL = {
  LOGIN: "/auth/login",
  /**
   * client side URLs
   */
  //REGISTER: "/auth/register",
  DASHBOARD: "/dashboard",
  CERTIFICATE: "/certificate",
  FEEDBACK: "/feedback",
  PAPER_SUBMISSION: "/paper-submission",
  TRAVEL_DETAILS: "/travel-details",
  FEE_SUBMISSION: "/fee-submission",
  REGISTER_SUCCESS: "register-success",
  RESET_PASSWORD:'/auth/reset-password',
  UPDATE_PASSWORD:'/auth/update-password',
  THANKING_YOU:'/thankyou',
  PAYMENT_STATUS: "/payment-status",
  /**
   * Admin side URLs
   */
  ADMIN_LOGIN: "/admin/auth/login",
  SUBMISSION_DETAILS: "submission-details",
  MASTER_REPORT:'master-report',
  OFFLINE_FEE_PAY_REPORT:'offline-fee-pay-report',
  PRIVACY_POLICY:'/privacy-policy',
  RESEARCH_SCHOLAR_VERIFICATION:'researchScholarData',
  DONATION_SPONSOR_SHIP:'/donation-sponsorship',
  DONATION_PAYMENT_STATUS:'/donationpayment-status',
  DONATION_PAYEMENT_SUCCESS:'/donation-payment-success'


};
