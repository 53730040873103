import React from "react";
import {PaperSubmissionIndex} from "@Features/client/paper-submission";

export const PaperSubmissionPage = () => {


  return (
    <div>
       <PaperSubmissionIndex/>
    </div>
  );
};
