import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext"
import './index.scss';
import { useEffect, useState } from "react";
import { getCountryCode, getdonationsponsorship, postSponsorDonation } from "../register/services/register.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import { useAppDispatch } from "@Src/app/hooks/hooks";
import { setDonationTempOrderDetails } from "@Src/app/reducers/global/globalSlice";
import { ValidFields } from '@Src/utils/messages/validation.contant';
import { globalRegex } from '@Src/utils/regex/regex.constant';
import * as Yup from 'yup';




export const DonationSponsorshipPage = () => {
    const dispatch = useAppDispatch();
    const [sponsordropdown, setsponsordropdown] = useState<any>([])
    const [countryCode, setCountryCode] = useState<any>([])


    const donationinitialform = {
        donationtype: undefined,
        type: undefined,
        Name: '',
        EmailId: '',
        mobile: '',
        CountryCodeValue: "+91",
        userpan: '',
        usergst: '',
        universitypan: 'AAAGU0114N',
        univesitygst: '07AAAGU0114N3Z8',
        remark: '',
        reversecharge: undefined,
        sponsorshipamount: { Amount: 0, Booked: 0, ID: 0, Remarks: "" },
        othersponsoramount: '',
        donationamount: '',
        gstamount: '',
        totalamount: ''
    }

    const donationschema = Yup.object({
        donationtype: Yup.string().required('Donation Type is required'),
        type: Yup.string().required('Type is required'),
        Name: Yup.string().min(ValidFields?.FIRST_NAME?.MIN, 'Name is too short , atleast 2 characters required').max(60, 'Name is too long , maximum 20 characters accepted').required('Name is required').matches(/^[a-zA-Z\s]+$/, globalRegex?.ONLY_ALBHABET?.MESSAGE),
        EmailId: Yup.string().email(ValidFields?.EMAIL?.ERROR_MESSAGE).required(ValidFields?.EMAIL?.REQUIRED),
        CountryCodeValue: Yup.string().required(ValidFields?.COUNTRY_CODE?.REQUIRED),
        mobile: Yup.string().min(ValidFields?.MOBILE.MIN, ValidFields?.MOBILE?.SHORT_MESSAGE).max(ValidFields?.MOBILE.MAX, ValidFields?.MOBILE?.LONG_MESSAGE).required(ValidFields?.MOBILE?.REQUIRED)
            .matches(globalRegex?.MOBILE_NUMBER?.REGEX, globalRegex?.MOBILE_NUMBER?.MESSAGE),
        userpan: Yup.string().required(' PAN No is required'),
        usergst: Yup.string().required(' GST No is required'),
        remark: Yup.string().required(' Remarks is required'),
        reversecharge: Yup.string().when('donationtype', {
            is: (donationtype: any) => donationtype && donationtype?.includes(2),
            then: Yup.string().required('Please choose reverse charge mechanism'),
            otherwise: Yup.string().notRequired(),
        }),
        donationamount: Yup.string().when('donationtype', {
            is: (donationtype: any) => donationtype && donationtype?.includes(1),
            then: Yup.string().required('Please Enter the amount'),
            otherwise: Yup.string().notRequired(),
        }),
        // sponsorshipamount: Yup.string().when('donationtype', {
        //     is: (donationtype: any) => donationtype && donationtype?.includes(2),
        //     then: Yup.string().required('Sponsorship type is required'),
        //     otherwise: Yup.string().notRequired(),
        // }),
        // othersponsoramount: Yup.string().when(['sponsorshipamount'], {
        //     is: (sponsorshipamount: any) => sponsorshipamount && sponsorshipamount?.ID?.includes(17),
        //     then: Yup.string().required('Please Enter the amount'),
        //     otherwise: Yup.string().notRequired(),
        // }),


    })


    const donationsponsordropdown = [
        { Name: 'Donation', Id: 1 },
        { Name: 'Sponsorship', Id: 2 }
    ]

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
        initialValues: donationinitialform,
        validationSchema: donationschema,
        onSubmit: (e: any) => {
            onSubmitdonationform(e)
        },
    });

    console.log(values.donationtype)


    useEffect(() => {
        if (values?.sponsorshipamount) {
            if (values?.sponsorshipamount?.ID !== 17) {
                const percentage = 0.18
                let gstamountcalculated: any = (Number(values?.sponsorshipamount?.Amount) * percentage)
                values.gstamount = gstamountcalculated
                values.totalamount = values?.sponsorshipamount?.Amount + (values.reversecharge == 2 ? gstamountcalculated : Number(0))
            } else if (values?.sponsorshipamount?.ID == 17) {
                const percentage = 0.18
                let gstamountcalculated: any = (Number(values?.othersponsoramount) * percentage)
                values.gstamount = gstamountcalculated
                values.totalamount = Number(values?.othersponsoramount) + (values.reversecharge == 2 ? gstamountcalculated : Number(0))

            }
        }


    }, [values?.sponsorshipamount, values?.othersponsoramount, values?.reversecharge])


    useEffect(() => {
        sponsorshiptypedropdown()
        countryCodegetApi()
    }, [])

    function sponsorshiptypedropdown() {
        getdonationsponsorship().then((resp: APIResponse) => {
            if (resp.ResponseType == APIStatus.SUCCESS) {
                setsponsordropdown(resp?.data)
            }
        }).catch((error: any) => {
            console.log(error)
        })
    }

    const countryCodegetApi = () => {
        getCountryCode().then((resp: APIResponse | any) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setCountryCode(resp.data)

            }

        }).catch((error) => {
            console.log(error);

        })
    }

    function onSubmitdonationform(e: any) {
        let data = {}
        if (e.donationtype == 1) {
            let payload =
            {
                "DonationSponsorshipType": e.donationtype,
                "Type": e.type,
                "Name": e.Name,
                "Email": e.EmailId,
                "CountryCode": e.CountryCodeValue,
                "MobileNo": e.mobile,
                "PanNo": e.userpan,
                "GstNo": e.usergst,
                "GstAmount": 0,
                "TotalAmount": e.donationamount,
                "Remarks": e.remark,
            }

            data = payload
        }

        if (e.donationtype == 2) {
            let payload =
            {
                "DonationSponsorshipType": e.donationtype,
                "Type": e.type,
                "Name": e.Name,
                "Email": e.EmailId,
                "CountryCode": e.CountryCodeValue,
                "MobileNo": e.mobile,
                "PanNo": e.userpan,
                "GstNo": e.usergst,
                "SponsorshipType": (e.sponsorshipamount?.ID != 17 ? e.sponsorshipamount?.Amount : e.othersponsoramount),
                "GstAmount": (e.reversecharge == 2 ? e.gstamount : 0),
                "TotalAmount": e.totalamount,
                "Remarks": e.remark,
            }
            data = payload
        }
        if (data) {
            postSponsorDonation(data).then((resp: APIResponse | any) => {
                if (resp.ResponseType === APIStatus.SUCCESS) {
                    dispatch(setDonationTempOrderDetails(resp.data?.data));
                    let url = resp.data?.data?.instrumentResponse?.redirectInfo?.url;
                    window.open(url, "_self")
                }

            }).catch((error) => {
                console.log(error);

            })
        }
    }


    const selectedsponsorshipOptionTemplate = (option: any, props: any) => {
        if (option) {
            return (

                <div className="sponsor-drop-class">
                    {option.Amount > 0 ? <div>₹ {option.Amount} </div> : <div>Any other</div>}
                    <div className="tw-mx-1">-</div>
                    <div> {option.Remarks} </div>

                </div>
            );
        }
        return <span>{props.placeholder}</span>;

    };

    const sponsorshipOptionTemplate = (option: any) => {
        if (option) {
            return (
                <div className={`sponsor-drop-class paddi-class ${option.Booked == 1 && 'booked-class'}`}>
                    {option.Amount > 0 ? <div>₹ {option.Amount} </div> : <div>Any other</div>}
                    <div className="tw-mx-1">-</div>
                    <div> {option.Remarks} </div>
                    {option.Booked == 1 ? <div className="tw-flex"><div className="tw-mx-1">|</div>
                        Already Booked </div> : ''}

                </div>
            );
        }
    };





    console.log(typeof (values.donationtype))

    return (
        <>
            <div className="donation-sponsor-section">
                <div className='content-row'>
                    <div className="don-sponr-header">Donation/Sponsorship</div>
                    <div className='content-cols'>

                        <div className='content-form'>
                            <div className='group-form col-row'>
                                <form onSubmit={handleSubmit}>
                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Select Donation Type<span className='tw-text-red-500'>*</span> </label>
                                            <div>
                                                <Dropdown value={values.donationtype} onChange={handleChange}
                                                    optionValue="Id"
                                                    options={donationsponsordropdown}
                                                    onBlur={handleBlur}
                                                    optionLabel="Name"
                                                    name='donationtype'
                                                    className='form-control-t1 w-full'
                                                    placeholder="Select Donation Type" />
                                                {(errors.donationtype && touched.donationtype) ?
                                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Donation Type is required</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'> Type<span className='tw-text-red-500'>*</span></label>
                                            <div className='inline-list-row'>
                                                <label className='radio-label inline-list-coll'>
                                                    <input type='radio' name="type" value={values.type} onChange={(e) => setFieldValue('type', 1)} checked={values.type == 1} />Individual
                                                </label>
                                                <label className='radio-label inline-list-coll'>
                                                    <input type='radio' name="type" value={values.type} onChange={(e) => setFieldValue('type', 2)} checked={values.type == 2} /> Organisation
                                                </label>
                                            </div>
                                            {(errors.type && touched.type) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Type is required</p> : null}
                                        </div>
                                    </div>
                                    <div className='group-form-row md:tw-mb-4'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Name <span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.Name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='Name' className='form-control-t1'
                                                    placeholder='Please enter the name of the donor or sponsor'
                                                    keyfilter={/^[a-zA-Z\s]+$/}
                                                    maxLength={60}

                                                />
                                            </div>
                                            {(errors.Name && touched.Name) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Name is required</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Email<span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.EmailId}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='EmailId' className='form-control-t1'
                                                    placeholder='Please enter the email'
                                                />
                                            </div>
                                            {(errors.EmailId && touched.EmailId) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.EmailId}</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Mobile Number<span className='tw-text-red-500'>*</span> </label>
                                            <div className='form-mobile-info'>
                                                <span className='form-cotrol-c1 right-border'>
                                                    <Dropdown value={values.CountryCodeValue}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        options={countryCode}
                                                        optionLabel="DialCode"
                                                        optionValue='DialCode'
                                                        name='CountryCodeValue'
                                                        className='md:tw-w-20'
                                                    />
                                                </span>
                                                <InputText
                                                    value={values.mobile}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='mobile' className='form-control-t1'
                                                    placeholder='XXXXXXXXXX'
                                                    maxLength={10}
                                                    keyfilter="int" />

                                            </div>

                                            {(errors.mobile && touched.mobile) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.mobile}</p> : null}


                                        </div>
                                    </div>

                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>PAN No.<span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.userpan}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name='userpan' className='form-control-t1'
                                                    placeholder='Please enter the PAN No.'
                                                />

                                            </div>
                                            {(errors.userpan && touched.userpan) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.userpan}</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>GST No.<span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.usergst}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name='usergst' className='form-control-t1'
                                                    placeholder='Please enter the GST No.'
                                                />

                                            </div>
                                            {(errors.usergst && touched.usergst) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.usergst}</p> : null}
                                        </div>
                                    </div>


                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>GST No. of University of Delhi<span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.univesitygst}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name='univesitygst' className='form-control-t1'
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                    </div>


                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>PAN of University of Delhi<span className='tw-text-red-500'>*</span></label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.universitypan}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name='universitypan' className='form-control-t1'
                                                    readOnly
                                                />

                                            </div>
                                        </div>
                                    </div>


                                    {values?.donationtype == 1 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Please enter the Amount you wish to donate<span className='tw-text-red-500'>*</span> </label>
                                            <div className='form-mobile-info'>
                                                <span className='form-cotrol-c1 right-border customspan-box'>
                                                    ₹
                                                </span>
                                                <InputText
                                                    value={values.donationamount}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='donationamount' className='form-control-t1'
                                                    placeholder='XXXXXXXXXX'
                                                    keyfilter="int" />

                                            </div>
                                            {(errors.donationamount && touched.donationamount) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.donationamount}</p> : null}


                                        </div>
                                    </div>}

                                    {values?.donationtype == 2 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'> Whether reverse charge mechanism is applicable?<span className='tw-text-red-500'>*</span></label>
                                            <div className='inline-list-row'>
                                                <label className='radio-label inline-list-coll'>
                                                    <input type='radio' name="reversecharge" value={values.reversecharge} onChange={(e) => setFieldValue('reversecharge', 1)} checked={values.reversecharge == 1} />Yes
                                                </label>
                                                <label className='radio-label inline-list-coll'>
                                                    <input type='radio' name="reversecharge" value={values.reversecharge} onChange={(e) => setFieldValue('reversecharge', 2)} checked={values.reversecharge == 2} /> No
                                                </label>
                                            </div>
                                            {(errors.reversecharge && touched.reversecharge) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.reversecharge}</p> : null}
                                        </div>

                                    </div>}

                                    {values?.donationtype == 2 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Please select the Sponsorship type and amount<span className='tw-text-red-500'>*</span> </label>
                                            <div>
                                                <Dropdown value={values.sponsorshipamount}
                                                    onChange={(e) => {
                                                        if (e.value?.Booked == 0) {
                                                            setFieldValue('sponsorshipamount', e.value)
                                                        }
                                                    }}
                                                    options={sponsordropdown}
                                                    onBlur={handleBlur}
                                                    optionLabel='name'
                                                    name='sponsorshipamount'
                                                    className='form-control-t1 w-full'
                                                    placeholder="Select Sponsorship type"
                                                    valueTemplate={selectedsponsorshipOptionTemplate}
                                                    itemTemplate={sponsorshipOptionTemplate}

                                                />
                                                {/* {(errors.sponsorshipamount && touched.sponsorshipamount) ?
                                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.sponsorshipamount}</p> : null} */}
                                            </div>
                                        </div>
                                    </div>}

                                    {values?.sponsorshipamount?.ID == 17 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>other </label>
                                            <div className='form-mobile-info'>
                                                <span className='form-cotrol-c1 right-border customspan-box'>
                                                    ₹
                                                </span>
                                                <InputText
                                                    value={values.othersponsoramount}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='othersponsoramount' className='form-control-t1'
                                                    placeholder='XXXXXXXXXX'
                                                    keyfilter="int" />

                                            </div>
                                            {/* {(errors.othersponsoramount && touched.othersponsoramount) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.othersponsoramount}</p> : null} */}



                                        </div>
                                    </div>}

                                    {values?.reversecharge == 2 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>GST applicable (18%)</label>
                                            <div className='form-mobile-info'>
                                                <span className='form-cotrol-c1 right-border customspan-box'>
                                                    ₹
                                                </span>
                                                <InputText
                                                    value={values.gstamount}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='gstamount' className='form-control-t1'
                                                    placeholder='XXXXXXXXXX'
                                                    keyfilter="int"
                                                    readOnly />

                                            </div>
                                        </div>
                                    </div>}
                                    {values?.donationtype == 2 && <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Total</label>
                                            <div className='form-mobile-info'>
                                                <span className='form-cotrol-c1 right-border customspan-box'>
                                                    ₹
                                                </span>
                                                <InputText
                                                    value={values.totalamount}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='totalamount' className='form-control-t1'
                                                    placeholder='XXXXXXXXXX'
                                                    keyfilter="int"
                                                    readOnly />

                                            </div>
                                        </div>
                                    </div>}
                                    <div className='group-form-row '>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Remarks </label>
                                            <div className='form-mobile-info'>
                                                <InputText
                                                    value={values.remark}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name='remark' className='form-control-t1'
                                                    placeholder='Please enter the remarks'
                                                />

                                            </div>
                                            {(errors.remark && touched.remark) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.remark}</p> : null}
                                        </div>
                                    </div>
                                    <div className='group-form-row'>
                                        <div className='group-form-footer flex align-items-center mt-4'>
                                            <button className="button-primary active" type="submit"> Submit <i className="pi pi-angle-right ml-1"></i> </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}