export const globalRegex = {
    ONLY_ALBHABET: {
        REGEX: /^[aA-zZ\s]+$/,
        MESSAGE: "Only alphabets are allowed for this field"
    },
    // ICA_MemberShip: {
    //     REGEX: /^[a-zA-Z0-9\[\]]*$/,
    //     MESSAGE: "Only alphabets are allowed for this field"
    // },
    ONLY_NUMBER : {
        REGEX : /^[0-9]*$/,
        MESSAGE : "Only numbers are allowed for this field"
    },
    PASSWORD: {
        REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,16}$/,
        MESSAGE: "Password Contains atleast 1 UpperCase, LowerCase, Number and Special Character"
    },
    MOBILE_NUMBER : {
        REGEX : /^[6-9](?!(\d)\1+$)\d{9}$/,
        MESSAGE : "Please Enter a valid Mobile Number"
    },
    BLANK_SPACE : {
        REGEX : /^\S+$/,
        MESSAGE : "Please Enter a valid Name"
    },
    ZIP_CODE:{
        REGEX : /^(?!(\d)(?:\1{5})+$)\d{6}$/,
        MESSAGE : "Please Enter a valid Zip Code"
    }
}