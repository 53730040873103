import ApiService from "@Src/services/bookstore/ApiService";
import { ADMIN_MASTER_REPORT } from "./masterreport.constant";

export const adminMasterReport = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_MASTER_REPORT.ADMIN_MASTER_DETAILS+`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'POST',
        data:data.filters
    })
}

export const adminMasterListCount = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_MASTER_REPORT.ADMIN_MASTER_LISt_COUNT+`?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
        method:'POST',
        data:data.filters
    })
}


export const adminDownloadMasterReport = async (data:any) => {
    return await ApiService.fetchData({
        url:ADMIN_MASTER_REPORT.ADMIN_MASTER_DETAILS_DOWNLOAD,
        method:'POST',
        data:data.filters
    })
}