import React, { useEffect, useRef, useState } from 'react'
import 'primeicons/primeicons.css';
import "./index.scss"
import { userFeeList, deleteUser, addUser, getinvoice } from '../services/client.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { getRelations, verifyicamembership, verifyiwemailid } from '../register/services/register.service';
import { Genderenum, Salutationenum } from '../register/constants/register.constant';
import { useFormik } from 'formik';
import { AccompanyingSchema, InitialAccompanyingUser } from './schema/feesubmit.schema';
import { useAppSelector } from '@Src/app/hooks/hooks';
import { PaymentGatewayIndex } from '@Src/features/payment';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '@Src/routes/app-route-urls';
import { useAppDispatch } from '@Src/app/hooks/hooks';
import { hideSpinner, showSpinner } from '@Src/app/reducers/global/globalSlice';
import { API_STATUS_MSG_CONSTANT, ApiStatusMessage } from '@Src/utils/globalUtilities';
import { toast } from 'react-hot-toast';
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"
import { VerifyFeePaymentFormDialog } from './dialogs/verify-fee-payment-form-dialog';
import { offlineFeePaymentStatusEnum } from '@Src/app/constants/app.constant';
import { VerifyFeePaymentFormAlreadyFilledDialog } from './dialogs/verify-fee-payment-form-already-filled-dialog';
import { store } from '@Src/app/store';
import { Toast } from 'primereact/toast';
import VerifiedSvg from "@Src/assets/svg/commonsvg/verified.svg"
import ErrorSvg from '@Src/assets/svg/commonsvg/error.svg'

export const FeeSubmissionIndex = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [userlist, setUserList] = useState<any>([])
    const [visible, setVisible] = useState(false);
    const [relations, setRelations] = useState([]);
    const [visibleVerifyPayment, setVisibleVerifyPayment] = useState<boolean>(false);
    const [visibleVerifyPaymentAlreadyFilled, setVisibleVerifyPaymentAlreadyFilled] = useState<boolean>(false);
    const [offlineFeePaymentStatus, setOfflineFeePaymentStatus] = useState<number>(useAppSelector(state => state.global.offlineFeePaymentStatus));
    const payemntstatus = useAppSelector(state => state.global.paymentstatus);
    const [checkVerifyPayment, setCheckVerifyPayment] = useState<boolean>(false);
    //const [resp?.data , setresp?.data] = useState<any>()
    const [displayayemntpage, setdisplayayemntpage] = useState<any>(false)
    const usertype = useAppSelector((state) => state.global.isVerifiedResearchScholar)
    const toaster: any = useRef(null);
    const [Notfound, setNotfound] = useState(false)
    const [verifiedsuccess, setverifiedsuccess] = useState(false)



    useEffect(() => {
        getUserFeelist()
        relationsgetAPi()
    }, [])

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue, resetForm } = useFormik({
        initialValues: InitialAccompanyingUser,
        validationSchema: AccompanyingSchema,
        onSubmit: (e) => {
            console.log(e)
            addAcompanyingUser(e)
        },
    });

    const getUserFeelist = () => {
        userFeeList().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setUserList(resp.data)

            }
        }).catch((error) => {
            console.log(error);

        })
    }
    let totalPrice = userlist && userlist.reduce((acc: any, item: any) => acc + item.TotalFees, 0);

    const deleteAccompanyingUser = (id: any) => {
        dispatch(showSpinner())
        let data = {
            "Id": id
        }

        deleteUser(data).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                getUserFeelist()
                resetForm()
                setverifiedsuccess(false)
            }
            else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.ID_MISSING) {
                dispatch(hideSpinner())
                toast.error(ApiStatusMessage.ACCOMPANYING_PERSON_ID_MISSING)
            }
            else {
                dispatch(hideSpinner())
            }
        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error);

        })
    }

    const relationsgetAPi = () => {
        dispatch(showSpinner())
        getRelations().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                setRelations(resp.data)
                dispatch(hideSpinner())


            }

        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error);

        })
    }

    const addAcompanyingUser = (e: any) => {
        dispatch(showSpinner())
        let data = {
            "IcaMembershipNumber": verifiedsuccess ? e.IcaMembershipNumber : '',
            "Salutation": e.Salutation,
            "Name": e.Name,
            "Age": Number(e.Age),
            "Gender": e.Gender,
            "Relation": e.Relation,
        }
        addUser(data).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                getUserFeelist()
                setVisible(false)
                resetForm()
                setverifiedsuccess(false)

            } else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.ICA_MEMBERSHIP_REPEATED) {
                toast.error(ApiStatusMessage.ICA_MEMBERSHIP_REPEATED)
            } else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.NOT_AN_ICA_MEMEBER) {
                toast.error(ApiStatusMessage.NOT_AN_ICA_MEMEBER)
            }

        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error);

        })
    }

    const setVisibleVerifyPaymentHandler = (flag: boolean) => {
        setVisibleVerifyPayment(flag)
    }

    const onClickVerifyFeePaymentHandler = () => {
        if (checkVerifyPayment) {
            if (offlineFeePaymentStatus == offlineFeePaymentStatusEnum.NONE) {
                setVisibleVerifyPaymentHandler(true);
            } else {
                // setVisibleVerifyPaymentHandler(true);
                setVisibleVerifyPaymentAlreadyFilled(true)
            }
        } else {
            toast.error('Please check confirmation first')
        }
    }

    const verifyOfflineFeePaymentFormStatusHandler = (data: any) => {
        setOfflineFeePaymentStatus(data.FormSubmmitedForPayment);
    }

    const onChangeCheckVerifyPaymentHandler = (e: any) => {
        setCheckVerifyPayment(e.target.checked)
    }

    const setVisibleVerifyPaymentAlreadyFilledHandler = (flag: boolean) => {
        setVisibleVerifyPaymentAlreadyFilled(flag)
    }

    const printinvoicedate = () => {
        dispatch(showSpinner())
        getinvoice().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                const printWindow: any = window.open('', '_blank');
                printWindow.document.open();
                printWindow.document.write(`
                <!doctype html>
                <html>
                <head>
                <meta charset="utf-8" />
                <title>Payment Invoice</title>
                </head>
                
                <body onload="window.print();window.close()">
                <table width="720" border="0" cellspacing="0" cellpadding="0" align="center" style="font-family:Arial, Helvetica, sans-serif; background: #FFF;">
                  <tr>
                    <td>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 32px 40px;">
                            <tr>
                                <td>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-bottom: 48px; ">
                                        <tr >
                                            <td width="200">
                                                <img style="width: 182px;
                                                height: 40px;" src="https://i.postimg.cc/G2gCBHHb/DUCCLogo-90005f5f88de12d8ca4d.png" alt="ica-logo" />
                                            </td>
                                            <td></td>
                                            <td width="150" style="text-align: right; color: var(--Primary, #1C7CED);
                                            font-size: 18px;
                                            font-weight: 700;
                                            line-height: normal;">Invoice</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                           
                
                           <tr>
                            <td style="color: rgba(47, 48, 67, 0.75);
                            font-size: 13px;
                            font-weight: 700;
                            line-height: normal;
                            padding-bottom: 8px;
                            display: block;">Dear ${resp?.data?.UserSalutation} ${resp?.data?.FirstName} ${resp?.data?.MiddleName} ${resp?.data?.LastName},</td>
                           </tr>
                           <tr>
                            <td style="color: #2F3043;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 140%;
                            width: 100%;">We are delighted to inform you that your payment for the ICA Conference 
                                Participant Onboarding has been successfully confirmed. 
                                Your registration details and participation are now officially confirmed for the upcoming ICA Conference.</td>
                           </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                           <tr>
                            <td style="color: #2F3043;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 140%;
                            padding-bottom: 22px;">Please find below your invoice cum participation details:</td>
                           </tr>
                
                           <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Name:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.UserSalutation} ${resp?.data?.FirstName} ${resp?.data?.MiddleName} ${resp?.data?.LastName}</span>
                        </td>
                        </tr>
                
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">UID:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.UID}</span>
                        </td>
                        </tr>
                
                      ${resp?.data?.ICAMembershipNumber ? `<tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">ICA Membership No.:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.ICAMembershipNumber}</span>
                        </td>
                        </tr>` : ''}
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Email:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.EmailId}</span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 24px;"><span style="width: 150px; display: block;">Accompanying Persons:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.AccompanyingUsers}</span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Payment Details:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;"></span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Fee Amount:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">INR ${resp?.data?.FeesAmount}</span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Transaction Id:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.TransactionId}</span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Payment Type:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.PaymentType}</span>
                        </td>
                        </tr>
                        <tr>
                            <td  style="color: #2F3043;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            padding-bottom: 11px;"><span style="width: 150px; display: block;">Payment Date:</span>
                          
                          <span style="color: #2F3043;
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 140%;
                          text-align: left;">${resp?.data?.PaymentDate}</span>
                        </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                          </tr>
                
                        <tr>
                            <td style="color: #2F3043;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 140%;">
                                As a registered participant, you will have access to paper submissions, and travel detail submissions. 
                                Feedback submission and Certificate download will be available after the conference is over.
                                Please keep your login credentials secure and do not share them with anyone else to ensure the privacy 
                                and security of your account. We look forward to your active participation and 
                                engagement in the conference.
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                
                          <tr>
                            <td style="color: #2F3043;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 140%;">Best Regards</td>
                          </tr>
                
                        </table>
                    </td>
                  </tr>
        
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </table>
                </body>
                </html>`)
                printWindow.document.close();
            }

        }).catch((error) => {
            dispatch(hideSpinner())
            console.log(error);

        })


    }

    useEffect(() => {
        setTimeout(() => {
            showSuccess()
        }, 100)

    }, [])

    const showSuccess = () => {
        toaster.current.show({
            severity: 'info', summary: <span className='toast-msg'>Message</span>, detail: <span className='toast-msg'>Your Research scholar status hasn't been verified by HOD and Supervisor</span>
            , life: 3000
        });
    }

    const verificarionicamembershipid = () => {

        if (values.IcaMembershipNumber) {
            let payload = {
                "IcaMembershipId": values.IcaMembershipNumber //a user may or may not have this value. so we can pass '' in case there is no value
            }
            verifyicamembership(payload).then((resp: APIResponse) => {
                if (resp.ResponseType === APIStatus.SUCCESS) {
                    setverifiedsuccess(true)
                    toast.success('Valid ICA Membership Id entered')

                } else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.NOT_AN_ICA_MEMEBER) {
                    toast.error(ApiStatusMessage.NOT_AN_ICA_MEMEBER)
                    setNotfound(true)

                    setTimeout(() => {
                        setNotfound(false)
                    }, 4000)

                } else if (resp.StatusMsg == API_STATUS_MSG_CONSTANT.REPEATED_ICAMEMBERSHIP_ID) {
                    toast.error(ApiStatusMessage.REPEATED_ICAMEMBERSHIP_ID)

                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    function onhidedialogbox() {
        setVisible(false)
        resetForm()
        setverifiedsuccess(false)
    }




    return (
        <>
            <div>
                {(store.getState().global.UserType == 2 && store.getState().global.isVerifiedResearchScholar == 0) ? <Toast ref={toaster} position="top-center" /> :
                    <div>
                        {!(payemntstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED) ?
                            <div>
                                <div className="empty-data">
                                    <div className="empty-data-icon">  </div>
                                    <div className="empty-data-details">
                                        <h2 className="empty-data-h1"> Fees Payment Closed </h2>
                                    </div>
                                </div>
                                <div className="tw-hidden">
                                    <div className='page-header-1'>
                                        <h2 className='content-heading-1'> Fee Submission </h2>
                                    </div>
                                    <div className='page-content'>
                                        <div className='table-format'>
                                            <table className='table-format-ui' cellPadding="0" cellSpacing="0" width="100%" border={1}>
                                                <thead>
                                                    <tr className='table-format-head'>  <th> Person </th>
                                                        <th> Relation </th>
                                                        <th> Amount </th> </tr>
                                                </thead>
                                                <tbody>
                                                    {userlist && userlist.map((list: any) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span className='table-title-1'>
                                                                        {list?.Salutation == 1 && 'Mr. '}
                                                                        {list?.Salutation == 2 && 'Miss. '}
                                                                        {list?.Salutation == 3 && 'Mrs. '}
                                                                        {list?.Salutation == 4 && 'Dr. '}
                                                                        {list?.Salutation == 5 && 'Prof. '}
                                                                        {list?.Salutation == 6 && 'Senior Prof. '}


                                                                        {list?.name} </span>
                                                                    {list?.relation === 'you' && <span className='table-title-2'>({list.relation}) </span>}
                                                                    {list?.relation != 'you' && <span className='table-title-2' >
                                                                        <a href='javascript:void(0)' className='button-links red' onClick={() => deleteAccompanyingUser(list.ID)}> Remove </a>  </span>}
                                                                </td>
                                                                <td>{list?.relation != 'you' && <span>{list.relation}</span>} </td>
                                                                <td> <span className='price'> {list?.TotalFees} </span> </td>
                                                            </tr>
                                                        )


                                                    })}

                                                    {userlist.length > 0 && <tr className='tr-total'>
                                                        <td>
                                                            <span className='table-total-1'>TOTAL </span>
                                                        </td>
                                                        <td></td>
                                                        <td> <span className='table-total-price'> ₹ {userlist && totalPrice} </span> </td>
                                                    </tr>}
                                                </tbody>

                                            </table>
                                        </div>

                                        <div className='flex-wrap mt-4 flex justify-content-center lg:justify-content-start'>
                                            <PaymentGatewayIndex totalPrice={totalPrice} />
                                            <a href='javascript:void(0)' className='button-primary ml-0 lg:ml-3' onClick={() => setVisible(true)}> Add Accompanying Person <i className="pi pi-plus ml-3"></i> </a>
                                        </div>
                                        <div className='info-text-1 mt-3'> *Amount inclusive of all taxes, you will be redirected to the Payment Gateway page to submit the fees</div>
                                        {/*  */}
                                        <div className='ui-style-1'>
                                            <div className='row-1'>
                                                <input className='checkbox-1' type="checkbox"
                                                    onChange={(e) => onChangeCheckVerifyPaymentHandler(e)}
                                                    checked={checkVerifyPayment}
                                                /> I confirm that I’ve submitted fees via Bank Transfer.
                                            </div>
                                            <div> <a href='javascript:void(0)' className='button-primary' onClick={() => onClickVerifyFeePaymentHandler()}>  Verify Fee Payment  </a> </div>
                                            <div className='text-u-1'> We will verify your payment from bank, then you will be eligible for travel detail submission and Paper submission.  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            (payemntstatus == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED) ?
                                <div className="empty-data">
                                    <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
                                    <div className="empty-data-details">
                                        <h2 className="empty-data-h1"> Fees Payment Successful </h2>
                                        <p className="empty-data-text-2"> Your fees payment has been completed successfully.<br />
                                            Please proceed to add Travel and Paper Details. </p>
                                        <div className='download-invoice' onClick={() => printinvoicedate()}>Download Invoice</div>
                                        <div> <button className="button-primary active" onClick={() => navigate(AppRouteURL.TRAVEL_DETAILS)}> Continue to Travel Details <i className="pi pi-angle-right ml-1"></i> </button> </div>
                                    </div>
                                </div>
                                :
                                <div className="empty-data">
                                    <div className="empty-data-icon">  </div>
                                    <div className="empty-data-details">
                                        <h2 className="empty-data-h1"> Fees Payment Failed </h2>
                                        <p className="empty-data-text-2"> Your fees payment has been failed.<br />
                                            Please retry. </p>
                                    </div>
                                </div>
                        }
                        <div className='refund-policy-card tw-hidden'>
                            <div className='rfnd-head'><span className='rfnd-star'>*</span> REFUND POLICY</div>
                            <ul className='rfnd-ul'>
                                <li>Delegate Fees once paid is non-refundable.</li>
                                <li>Sponsorship/ Donation once paid is non-refundable.</li>
                            </ul>

                        </div>
                    </div>}
            </div>
            <Dialog header="Add Accompanying Person" draggable={false} visible={visible} className='add-accompany-dialog-card' onHide={() => onhidedialogbox()}>
                <div className='add-accompany-dialog-box'>
                    <form onSubmit={handleSubmit}>
                        <div className='group-form-row md:tw-mb-1 md:tw-mt-2'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>ICA Life Membership No. (Optional) </label>
                                <div className='form-mobile-info'>
                                    <InputText
                                        value={values.IcaMembershipNumber}
                                        onChange={handleChange}
                                        autoComplete='off'
                                        name='IcaMembershipNumber' className='form-control-t1'
                                        placeholder='Please enter ICA Life Membership Number'
                                        disabled={verifiedsuccess}
                                        maxLength={13} />
                                    {(!verifiedsuccess && !Notfound) && <a href='javascript:void(0)' className='ica-mem-verify' onClick={(e) => verificarionicamembershipid()}>Verify</a>}
                                    {verifiedsuccess && <a href='javascript:void(0)' className='ica-mem-verifed'> <img src={VerifiedSvg} alt='VerifiedSvg' /> Verified</a>}
                                    {Notfound && <a href='javascript:void(0)' className='ica-mem-not-found'> <img src={ErrorSvg} alt='ErrorSvg' />Not Found</a>}
                                </div>
                            </div>
                        </div>
                        <div className='tw-flex tw-items-center tw-gap-5'>

                            <div className='tw-w-full'>
                                <label className='dialog-label'>Salutation </label>
                                <div>
                                    <Dropdown value={values.Salutation}
                                        onChange={(e) => setFieldValue('Salutation', e.value)}
                                        options={Salutationenum}
                                        optionLabel="name"
                                        optionValue='id'
                                        name='Salutation'
                                        placeholder="Select Salutation" className='form-control-t1' />                  </div>
                            </div>
                            <div className='tw-w-full'>
                                <label className='dialog-label'>Name</label>
                                <InputText
                                    value={values.Name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='Name' className='form-control-t1'
                                    placeholder='Enter name of the accompanying person'
                                />
                                {(errors.Name && touched.Name) ?
                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Name}</p> : null}
                            </div>
                        </div>

                        <div className='tw-flex tw-items-center tw-gap-5'>
                            <div className='tw-w-full'>
                                <label className='dialog-label'>Age</label>
                                <InputText
                                    value={values.Age}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='Age' className='form-control-t1'
                                    placeholder='Enter age of the person'
                                    keyfilter="int"
                                />
                                {(errors.Age && touched.Age) ?
                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Age}</p> : null}
                            </div>

                            <div className='tw-w-full'>
                                <label className='dialog-label'>Gender</label>
                                <Dropdown
                                    value={values.Gender}
                                    onChange={(e) => setFieldValue('Gender', e.value)}
                                    options={Genderenum}
                                    optionLabel="name"
                                    optionValue='id'
                                    onBlur={handleBlur}
                                    name='Gender'
                                    placeholder="Select gender" className="w-full" />
                                {(errors.Gender && touched.Gender) ?
                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Gender}</p> : null}
                            </div>

                        </div>

                        <div>
                            <label className='dialog-label'>Relation</label>
                            <Dropdown
                                value={values.Relation}
                                onChange={(e) => setFieldValue('Relation', e.value)}
                                optionValue="Id"
                                options={relations}
                                onBlur={handleBlur}
                                optionLabel="Name"
                                name='Relation'
                                placeholder="Select relation with the accompanying person"
                                className="w-full" />
                            {(errors.Relation && touched.Relation) ?
                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.Relation}</p> : null}
                        </div>
                        <button type='submit' className='add-person-dialog' >Add Person</button>

                    </form>
                </div>
            </Dialog>

            <VerifyFeePaymentFormDialog visibleVerifyPayment={visibleVerifyPayment} setVisibleVerifyPaymentHandler={setVisibleVerifyPaymentHandler} verifyOfflineFeePaymentFormStatusHandler={verifyOfflineFeePaymentFormStatusHandler} />
            <VerifyFeePaymentFormAlreadyFilledDialog visibleVerifyPaymentAlreadyFilled={visibleVerifyPaymentAlreadyFilled} setVisibleVerifyPaymentAlreadyFilledHandler={setVisibleVerifyPaymentAlreadyFilledHandler} />

        </>
    )
}