import { useAppDispatch, useAppSelector } from "@Src/app/hooks/hooks";
import { hideSpinner, showSpinner } from "@Src/app/reducers/global/globalSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postDonationProductOrder } from "../../services/client.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import toast from "react-hot-toast";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import checkinStatusSvg from "@Src/assets/svg/commonsvg/checkingstatus.svg"





export const DonationPayemntSuccess = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const tempOrderDetails: any = useAppSelector(state => state.global.donationTempOrderdetails)
    const [timesRun, setTimesRun] = useState<number>(0);
    const interval: any = useRef();

    useEffect(() => {
        checkPaymentStatus();
    }, [])

    function checkPaymentStatus() {
        dispatch(showSpinner());
        const payload = {
            "OrderNo": tempOrderDetails?.merchantTransactionId,
            //    "TransactionId": tempOrderDetails?.transactionId,
            //     "PaymentMode": 1,
            //     "IsPayment": 1
        }
        postDonationProductOrder(payload).then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(hideSpinner())
                navigate(AppRouteURL.DONATION_PAYEMENT_SUCCESS)

            } else {
                if (resp.StatusMsg == APIStatus.PAYMENT_PENDING) {
                    toast.error("Payment Pending")
                    navigate(AppRouteURL.DONATION_SPONSOR_SHIP)
                    interval.current = setTimeout(() => {
                        let times: number = timesRun + 1;
                        setTimesRun(times);
                        if (timesRun == 90) {
                            clearTimeout(interval.current);
                            toast.error("Payment Pending")
                            navigate(AppRouteURL.DONATION_SPONSOR_SHIP)
                        } else {
                            checkPaymentStatus();
                        }
                    }, 10000);
                } else if (resp.StatusMsg == APIStatus.PAYMENT_ERROR) {
                    dispatch(hideSpinner())
                    toast.error("Payment Failed")
                    navigate(AppRouteURL.DONATION_SPONSOR_SHIP)
                } else {
                    dispatch(hideSpinner())
                    toast.error("Payment Failed")
                    navigate(AppRouteURL.DONATION_SPONSOR_SHIP)
                }
            }
        }).catch(error => {
            dispatch(hideSpinner())
            console.log(error)
            toast.error("Payment Faled")
            navigate(AppRouteURL.DONATION_SPONSOR_SHIP)
        })

    }




    return (
        <>
            <div className="empty-data">
                <div className="empty-data-icon"> <img src={checkinStatusSvg} alt="checkinStatusSvg" /> </div>
                <div className="empty-data-details">
                    <h2 className="empty-data-h1"> Checking Payment Status </h2>
                    <p className="empty-data-text-2">Please wait for sometime, we are checking your payment status </p>
                </div>
            </div>

        </>
    )

}