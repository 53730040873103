import React  from "react";
import {HomePageIndex} from "@Features/client/homepage";

export const DashboardPage = () => {


  return (
    <div>
        <HomePageIndex/>
    </div>
  );
};
