import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import "./list.scss";
import { adminEditUserDetails, adminpaperDetails, adminPaperDownload, adminPaperRemark, allowPaperPresentationCert, paperdetailscontpost } from "../service/submission.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { paperDetailsStatus, paperStatusdropdown } from "../service/submission.constant";
import DownloadSvg from "@Src/assets/svg/commonsvg/download.svg"
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-hot-toast";
import RightCarrotSvg from '@Src/assets/svg/commonsvg/rightcarrot.svg'
import { useNavigate } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import { useAppDispatch, useAppSelector } from "@Src/app/hooks/hooks";
import { hideSpinner, setEditUserDetails, setTableDropdownpaperStatus, showSpinner } from "@Src/app/reducers/global/globalSlice";
import { store } from "@Src/app/store";
import { InputText } from "primereact/inputtext";
import EditIconSvg from "@Src/assets/svg/commonsvg/editicon.svg";
import { getDesignation } from "@Src/features/client/register/services/register.service";
import { InputSwitch } from 'primereact/inputswitch';
import Certificateimg1 from '@Src/assets/certificateimages/session1.png'
import Certificateimg2 from '@Src/assets/certificateimages/session2.png'
import Certificateimg3 from '@Src/assets/certificateimages/session3.png'
import Certificateimg4 from '@Src/assets/certificateimages/session4.png'
import Certificateimg5 from '@Src/assets/certificateimages/session5.png'
import Certificateimg6 from '@Src/assets/certificateimages/session6.png'
import Certificateimg7 from '@Src/assets/certificateimages/session7.png'



export const SubmissionList = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);
  const [adminPaperDetails, setAdminPaperDetails] = useState<any>();
  const [addcommenttextarea, setAddcommenttextarea] = useState<any>();
  const [commentRowData, setCommentRowData] = useState<any>();
  const [dropdownstatusvaleu, setDropdownstatusvaleu] = useState<any>();
  const tabledropdownstatus = useAppSelector((state) => state.global.tabledropdownpaperStatus)
  const [searchuser, setSearchUser] = useState('');
  const dispatch = useAppDispatch()
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PapersubmissionreportcountUID, setPapersubmissionreportcountUID] = useState(1);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [editmobilenum, setEditmobilenum] = useState<any>();
  const [editpapertirlevalue, setEditpapertirlevalue] = useState('');
  const [designation, setDesignation] = useState<any>([]);
  const [editDesignationvalue, setEditDesignationvalue] = useState<any>();




  useEffect(() => {
    getadminpaperDetails()
    savePaperDatatoredux()
    postpaperdetailscount()
    designationgetApi()
  }, [])

  useEffect(() => {
    postpaperdetailscount()
    getadminpaperDetails()


  }, [searchuser, currentPage, rows,])

  function getadminpaperDetails() {
    let payload = {
      filters: {
        "emailOrUsername": searchuser.trim()
      },
      pageNo: currentPage,
      pageSize: rows
    }
    adminpaperDetails(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setAdminPaperDetails(resp.data)
        if (resp.data) {
          const updatedArray = resp?.data.map((iteam: any) => ({
            ...iteam,
            editname: false,
            editmobilenumber: false,
            editDesignation: false,
            editpaperTitle: false

          }))
          dispatch(setEditUserDetails(updatedArray))
        }
      }
    }).catch(error => {
      console.log(error)
    })
  }


  /*-------------------------start-data-table-body-template-------------------*/


  function savePaperDatatoredux() {
    let storeValueCopy: any = []

    adminPaperDetails && adminPaperDetails.map((item: any) => {
      storeValueCopy.push({ status: item.Status, id: item.ID })
    });
    if (storeValueCopy.length) {
      dispatch(setTableDropdownpaperStatus([...storeValueCopy]))

    }


  }

  function changeStoreValue(value: any, index: any, rowData: any) {
    let storedata = store.getState().global.tabledropdownpaperStatus

    let storeupdateStatus: Array<any> = storedata.map(item => {
      return Object.assign({}, item);
    });

    if (storeupdateStatus?.length > 0) {
      storeupdateStatus[index]['status'] = value.Name
    }
    dispatch(setTableDropdownpaperStatus(storeupdateStatus))
    paperremarkapi(rowData, value.Name)

  }
  function statusBodyTemplate(rowData: any, rowDetails: any) {
    return (
      <>
        <Dropdown
          name="PaperStatus"
          onChange={(inputEvent: any) => {
            changeStoreValue(inputEvent?.target?.value, rowDetails?.rowIndex, rowData)
          }}
          options={paperStatusdropdown}
          optionLabel="Name"
          className='md:tw-w-44'
          placeholder="Select Status"
          disabled={rowData?.Status == "Accepted"}
        />

      </>
    )
  }

  const TagBodyTemplate = (rowData: any) => {
    return (
      <div>
        {(rowData.Status) && <span
          className={`button-primary active 
         ${rowData.Status === paperDetailsStatus.ACCEPTED && 'b-completed'}
         ${rowData.Status === paperDetailsStatus.SUBMITTED && 'b-completed'}
          ${rowData.Status === paperDetailsStatus.REJECTED && 'b-rejected'} 
           ${rowData.Status === paperDetailsStatus.REVESION && 'b-revision'} 
         ${rowData.Status === paperDetailsStatus.RE_SUBMITTED && 'b-completed'}
         ${rowData.Status === 'Revision-ReSubmitted' && 'b-revision'}    `}> {rowData.Status} </span>
        }
      </div>
    );
  };

  function abstractpaperdownload(rowData: any) {
    let name = `${rowData.UID}_${rowData.TitleOfPaper}_PLAGIARISM_REPORT.pdf`
    return (
      <>
        <span className="down-svg" onClick={() => downloadpaper(rowData.PlagiarismReport, name)} >
          <img src={DownloadSvg} alt="DownloadSvg" />
        </span>

      </>
    )
  }

  function Fullpaperdownload(rowData: any) {
    let name = `${rowData.UID}_${rowData.TitleOfPaper}_Full Paper.pdf`
    return (
      <>
        <span className="down-svg" onClick={() => downloadpaper(rowData.FullPaperSubmission, name)} >
          <img src={DownloadSvg} alt="DownloadSvg" />
        </span>

      </>
    )
  }

  function genderBodyTemplate(rowData: any) {
    return (
      <>
        {(rowData.Gender === 1) && <span>Male</span>}
        {(rowData.Gender === 2) && <span>Female</span>}
        {(rowData.Gender === 3) && <span>Other</span>}
      </>
    )
  }

  function commentBodyTemplate(rowData: any) {

    return (
      <>
        <a href="javascript:void(0)" className="button-comment-links" onClick={() => onclickcommenthandler(rowData)}> Add Comment </a>
      </>
    )
  }

  function onclickcommenthandler(rowData: any) {
    setVisible(true)
    setCommentRowData(rowData)
  }


  /*-------------------download-paper-api---------------------------*/
  function downloadpaper(link: any, fileName: any) {
    let payload = {
      "url": link
    }
    adminPaperDownload(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        downloadFileFromBase64(resp.data, fileName)
      }

    }).catch(error => {
      console.log(error)
    })
  }

  function edituserdetailsfunction(editfield: any, rowdata: any) {

    if ((editfield && rowdata && (firstname || middlename || lastname || editmobilenum || editDesignationvalue || editpapertirlevalue))) {
      let payload = {
        "UID": rowdata?.UID,
        "FName": editfield == 'Name' ? firstname : '',
        "MName": editfield == 'Name' ? middlename : '',
        "LName": editfield == 'Name' ? lastname : '',
        "Mobile": editfield == 'Mobile' ? editmobilenum : '',
        "Designation": editfield == 'Designation' ? editDesignationvalue.toString() : '',
        "paperTitle": editfield == 'PaperTitle' ? editpapertirlevalue : '',
        "paperSubmissionID": rowdata?.ID
      }
      adminEditUserDetails(payload).then((resp: APIResponse) => {
        if (resp.ResponseType === APIStatus.SUCCESS) {
          toast.success('User Details Updated Successfully')
          getadminpaperDetails()
          setFirstName('')
          setLastName('')
          setMiddleName('')
          setEditpapertirlevalue('')
          setEditDesignationvalue('')
          setEditmobilenum('')
        }
        else {
          toast.error(resp.StatusMsg)
        }

      }).catch(error => {
        console.log(error)
      })
    } else {
      toast.error('Invalid data')
    }
  }

  function downloadFileFromBase64(base64Data: any, fileName: any) {
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  }



  /*------------------------------paper-remark-put-api---------------*/
  function paperremarkapi(value: any, status: any) {
    dispatch(showSpinner())
    let data = {
      "Id": value.ID,
      "paperTitle": value.TitleOfPaper,
      "Status": status,
      "Comments": addcommenttextarea
    }
    adminPaperRemark(data).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setAddcommenttextarea('')
        dispatch(hideSpinner())
        setVisible(false)
        setDropdownstatusvaleu('')
        getadminpaperDetails()
        toast.success('paper submited successfully')
      } else {
        dispatch(hideSpinner())
      }
    }).catch(error => {
      console.log(error)
      dispatch(hideSpinner())
    })

  }




  function postpaperdetailscount() {

    let payload = {
      "filters": {
        "emailOrUsername": searchuser
      },
      pageNo: currentPage,
      pageSize: rows
    }
    paperdetailscontpost(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setTotalRecords1(resp?.data?.countData)
        setPapersubmissionreportcountUID(resp?.data?.countUID)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  function onUpdateUserDetails(data: any, field: string, status: boolean) {
    let editdata: any = store.getState().global.editUserDetails
    if (editdata) {
      const updatedData = editdata.map((item: any, index: number) => {
        if (index === (data?.RowNum - 1)) {
          return {
            ...item,
            [field]: status,
          };
        }
        return item;
      });

      dispatch(setEditUserDetails(updatedData));
    }

  }

  function NameBodyTemplate(rowData: any) {
    return (
      <>
        {(store.getState().global.editUserDetails && !store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editname']) &&
          <div className="tw-flex tw-items-center">
            <span>
              {rowData?.Salutation == 1 && 'Mr. '}
              {rowData?.Salutation == 2 && 'Miss. '}
              {rowData?.Salutation == 3 && 'Mrs. '}
              {rowData?.Salutation == 4 && 'Dr. '}
              {rowData?.Salutation == 5 && 'Prof. '}
              {rowData?.Salutation == 6 && 'Senior Prof. '}
              {rowData?.Name}
            </span>
            <span className="tw-ml-2 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editname', true)}>
              <img src={EditIconSvg} alt="EditIconSvg" />
            </span>
          </div>}

        {(store.getState().global.editUserDetails && store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editname']) && <div>
          <div className="tw-flex">
            <div>
              <InputText
                value={firstname}
                onChange={(e: any) => setFirstName(e.target.value)}
                autoComplete='off'
                name='FirstName' className='form-control-t1'
                placeholder='Please enter FirstName'
              />
              <InputText
                value={middlename}
                onChange={(e: any) => setMiddleName(e.target.value)}
                autoComplete='off'
                name='MiddleName' className='form-control-t1 tw-my-1'
                placeholder='Please enter MiddleName'
              />
              <InputText
                value={lastname}
                onChange={(e: any) => setLastName(e.target.value)}
                autoComplete='off'
                name='LastName' className='form-control-t1'
                placeholder='Please enter LastName'
              />
            </div>
            <div className="tw-flex">
              <span className="tw-mx-1 tw-cursor-pointer" onClick={() => edituserdetailsfunction('Name', rowData)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span className="tw-mx-1 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editname', false)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
          </div>
        </div>}


      </>
    )
  }

  function mobileBodyTemplate(rowData: any) {
    return (
      <>
        {(store.getState().global.editUserDetails && !store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editmobilenumber']) &&
          <div className="tw-flex">
            <span>{rowData?.Mobile}</span>
            <span className="tw-ml-2 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editmobilenumber', true)}>
              <img src={EditIconSvg} alt="EditIconSvg" />
            </span>
          </div>
        }

        {(store.getState().global.editUserDetails && store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editmobilenumber']) && <div className="tw-flex">
          <InputText
            value={editmobilenum}
            onChange={(e) => { setEditmobilenum(e.target.value) }}
            autoComplete='off'
            name='PrMobileNumber'
            className='form-control-t1'
            placeholder='XXXXXXXXXX'
            maxLength={10}
            keyfilter="int" />

          <div className="tw-flex">
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => edituserdetailsfunction('Mobile', rowData)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editmobilenumber', false)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>}

      </>
    )
  }

  function papertitleBodyTemplate(rowData: any) {
    return (
      <>
        {(store.getState().global.editUserDetails && !store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editpaperTitle']) &&
          <div className="tw-flex">
            <span className="tw-w-36">{rowData?.TitleOfPaper}</span>
            <span className="tw-ml-2 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editpaperTitle', true)}>
              <img src={EditIconSvg} alt="EditIconSvg" />
            </span>
          </div>
        }

        {(store.getState().global.editUserDetails && store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editpaperTitle']) && <div className="tw-flex">
          <InputText
            value={editpapertirlevalue}
            onChange={(e) => { setEditpapertirlevalue(e.target.value) }}
            autoComplete='off'
            name='paperTitle' className='form-control-t1'
            placeholder='Please enter the Title of the Paper'
          />

          <div className="tw-flex">
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => edituserdetailsfunction('PaperTitle', rowData)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editpaperTitle', false)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>}

      </>
    )
  }
  const designationgetApi = () => {
    getDesignation().then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setDesignation(resp.data)

      }

    }).catch((error) => {
      console.log(error);

    })
  }

  function DesignationBodyTemplate(rowData: any) {
    return (
      <>
        {(store.getState().global.editUserDetails && !store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editDesignation']) &&
          <div className="tw-flex">
            <span>{rowData?.Designation}</span>
            <span className="tw-ml-2 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editDesignation', true)}>
              <img src={EditIconSvg} alt="EditIconSvg" />
            </span>
          </div>
        }

        {(store.getState().global.editUserDetails && store.getState().global.editUserDetails[(rowData?.RowNum - 1)]['editDesignation']) && <div className="tw-flex">
          <Dropdown value={editDesignationvalue} onChange={(e) => setEditDesignationvalue(e.value)}
            optionValue="Id"
            options={designation}
            optionLabel="Name"
            name='Designation'
            className='form-control-t1 tw-w-4/5'
            placeholder="Select designation" />

          <div className="tw-flex">
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => edituserdetailsfunction('Designation', rowData)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span className="tw-mx-1 tw-cursor-pointer" onClick={() => onUpdateUserDetails(rowData, 'editDesignation', false)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>}

      </>
    )
  }



  function PaperPresentBodyTemplet(rowData: any) {
    return (
      <>
        <span>{rowData?.IsPresentPaper == 1 && 'Yes'}</span>
        <span>{rowData?.IsPresentPaper == 0 && 'No'}</span>
      </>
    )
  }

  const onPageInputChange = (event: any) => {
    if (event) {
      setFirst(event.first);
      setRows(event.rows);
      setCurrentPage(event.page + 1);
    }
  };

  function AuthorBodyTemplate(rowData: any) {
    const splitString = rowData.AuthorName && rowData.AuthorName.split(',');
    const stringWithSpaces = splitString && splitString.join(', ');
    return (
      <>
        <span>{stringWithSpaces && stringWithSpaces}</span>
      </>
    )
  }

  function CertificationBodyTemplate(rowData: any) {
    return (
      <>
       {rowData.Status === paperDetailsStatus.ACCEPTED  && <InputSwitch checked={rowData?.PresentationCertificateAllowed == 1}
          disabled={rowData?.PresentationCertificateAllowed == 1}
          onChange={(e) => PaperPresentaion(rowData)} />}
      { (rowData.Status === paperDetailsStatus.ACCEPTED  && rowData?.PresentationCertificateAllowed == 1) && <span className="button-comment-links tw-cursor-pointer" onClick={()=>downloadPresentationCertificate(rowData)}>Download</span>}      </>
    )
  }

  function downloadPresentationCertificate(data: any) {
    if (data) {

      let imageUrl = data?.SessionID == "1" ? Certificateimg1
        : data?.SessionID == "2" ? Certificateimg2
          : data?.SessionID == "3" ? Certificateimg3
            : data?.SessionID == "4" ? Certificateimg4
            : data?.SessionID == "5" ? Certificateimg5
            : data?.SessionID == "6" ? Certificateimg6
              : Certificateimg7
      const printWindow: any = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
      <!DOCTYPE>
              <html xmlns="http://www.w3.org/1999/xhtml">
              <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <title>Paper Presentation Certificate </title>
              </head>
              
              <body onload="window.print();window.close()" style="background:#f5f5f5; font-family:Arial, Helvetica, sans-serif; font-size:18px; font-weight:bold;">
              <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 auto;  position: relative;">
                <tr>
                <td style=" background-color:#fff; width:100%;  background-repeat: no-repeat; height:800px; padding:50px" valign="top">
                <img src="${imageUrl}" style="position: absolute; top:0; bottom:0; left:0; right:0;  z-index:10;  " />
                <table width="100%" border="0" cellspacing="0" cellpadding="0" >
                <tr>
                  <td style="padding: 207px 0 0 250px; font-weight:bold; z-index:777; position: absolute; top:8.5%; left:5%;">${data?.Name}</td>
                </tr>
                <tr>
                  <td  style="padding: 207px 0 0 12px; font-weight:bold; z-index:999; position: absolute; top:12.5%; left:6%;">${data?.University}</td>
                </tr>
               <tr>
                  <td  style="padding: 10px 500px 0 0px;">&nbsp;</td>
                </tr>
               <tr>
                  <td  style="padding: 207px 0 0 12px; font-weight:bold; z-index:999; position: absolute; top:21%; left:10%;">${data?.TitleOfPaper} </td>
                </tr>
              </table>
              
                  </td>
                </tr>
              </table>
              
              </body>
              </html>
             `)
      printWindow.document.close();
      // document.body.appendChild(htmlcontent);
      // html2canvas(htmlcontent, { useCORS: true }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF("p", "mm", "a4");
      //   const pageWidth = pdf.internal.pageSize.getWidth(); // Get page width
      //   const pageHeight = pdf.internal.pageSize.getHeight(); // Get page height
      //   const aspectRatio = canvas.width / canvas.height;
      //   const imgWidth = pageWidth; // Set image width to page width
      //   const imgHeight = imgWidth / aspectRatio; // Calculate image height based on aspect ratio
      //   console.log('imgWidth', imgWidth)
      //   console.log('imgHeight', imgHeight)
      //   pdf.addImage(imgData, 'PNG', 10, 10, 250, 180);
      //   pdf.save('Paper Presentation Certificate.pdf');
      //   document.body.removeChild(htmlcontent);

      // })
    } else {
      toast.error("Something went wrong. Please try again.");

    }
  }

  function PaperPresentaion(rowData: any) {
    let data = {
      UID: rowData?.UID,
      paperSubmissionID: rowData?.ID
    }
    allowPaperPresentationCert(data).then((resp: APIResponse) => {
      if (resp.ResponseType == APIStatus.SUCCESS) {
        getadminpaperDetails()
        toast.success(resp?.StatusMsg)
      }
    })

  }

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
        <div className='page-header-1'>
          <h2 className='master-head'> Submission Details </h2>
        </div>

        <div className="tw-flex tw-items-center ">
          <a href="javascript:void(false)" onClick={() => navigate(`/${AppRouteURL.OFFLINE_FEE_PAY_REPORT}`)} className="reset-all-filter tw-flex tw-mr-3" >Offline Fee Pay Report <img src={RightCarrotSvg} alt="RightCarrotSvg" /></a>
          <a href="javascript:void(false)" onClick={() => navigate(`/${AppRouteURL.MASTER_REPORT}`)} className="reset-all-filter tw-flex" >Master Report <img src={RightCarrotSvg} alt="RightCarrotSvg" /></a>
        </div>
      </div>

      <div className="submis-search-card">
        <InputText
          value={searchuser}
          onChange={(e) => setSearchUser(e.target.value)}
          name='search'
          className='form-control-t1 md:tw-w-40  tw-w-full'
          placeholder='Search User'
          autoComplete='off'
        />

        {PapersubmissionreportcountUID && <span className="unique-num">Unique Paper Submission: {PapersubmissionreportcountUID}</span>
        }      </div>

      <div className='page-content'>
        <div className='table-format table-auto'>
          {adminPaperDetails && <DataTable
            paginator
            style={{ height: "auto" }}
            rows={rows}
            first={first}
            totalRecords={totalRecords1}
            onPage={onPageInputChange}
            rowHover
            lazy
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50, 100]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            showGridlines
            scrollable
            scrollHeight="400px" className="admin-paper-details" value={adminPaperDetails} tableStyle={{ minWidth: '50rem' }}>
            <Column field="RowNum" header="SNo." />
            <Column field="UID" header="UID" />
            <Column field="IcaMembershipNumber" header="ICA Life Membership no" />
            <Column field="Name" header="Name" style={{ minWidth: '250px' }} body={NameBodyTemplate} />
            <Column field="Gender" header="Gender" body={genderBodyTemplate} />
            <Column field="EmailId" header="Email" />
            <Column field="Mobile" header="Mobile" style={{ minWidth: '250px' }} body={mobileBodyTemplate} />
            <Column field="Designation" header="Designation" style={{ minWidth: '250px' }} body={DesignationBodyTemplate} />
            <Column field="Department" header="Department" />
            <Column field="SessionName" header="Session" style={{ minWidth: '150px' }} />
            <Column field="TitleOfPaper" header="Paper Title" style={{ minWidth: '250px' }} body={papertitleBodyTemplate} />
            <Column field="AuthorName" body={AuthorBodyTemplate} header="Authors" />
            <Column field="IsPresentPaper" body={PaperPresentBodyTemplet} style={{ minWidth: '150px' }} header="Would you like to present the paper" />
            <Column field="Pliagirism" header="Plagiarism" />
            <Column field="FullPaperSubmission" body={Fullpaperdownload} header="Full Paper" />
            <Column field="PlagiarismReport" body={abstractpaperdownload} header=" Plagiarism report " />
            <Column body={statusBodyTemplate} header="Status" />
            <Column field="Status" body={TagBodyTemplate} header="Tag" />
            <Column field="Allow presentation certificate" body={CertificationBodyTemplate} header="Allow presentation certificate" />
            <Column field="comment" body={commentBodyTemplate} header="Comments" />
          </DataTable>}
        </div>
      </div>

      {/* modal dialog */}
      <Dialog className="sm-dialog" draggable={false} header="" visible={visible} onHide={() => setVisible(false)}>
        <div className="content-heading-1"> Add Comment </div>
        <div className="p-3">
          <div className='group-form col-row'>
            <div className='group-form-row'>
              <div className='group-form-colls'>
                <label className='form-label-1'>Comment </label>
                <div>
                  <textarea
                    className='form-control-t1'
                    placeholder='Enter your comments'
                    value={addcommenttextarea}
                    onChange={(e) => setAddcommenttextarea(e.target.value)}> </textarea>
                </div>
              </div>
            </div>
            <div className='group-form-row'>
              <div className='group-form-footer flex align-items-center mt-0 col-12'>
                <button className="button-primary active col-12" onClick={() => paperremarkapi(commentRowData, commentRowData.Status)}>Done  </button>

              </div>
            </div>
          </div>
        </div>
      </Dialog>

    </div>
  );
};


