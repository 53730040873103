import React from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import RightCarrotSvg from '@Src/assets/svg/commonsvg/rightcarrot.svg'


export const DownloadReport = (selectedReportDetails:any) => {
    const exportExcel = () => {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(selectedReportDetails?.masterreportdownload);
            const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
            saveAsExcelFile(excelBuffer, "Master Report");
        });
    }

    const saveAsExcelFile = (buffer:any, fileName:any) => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

     {
        return (
            <a href="javascript:void(false)" onClick={() => exportExcel()} className="reset-all-filter tw-flex" >Download Report <img src={RightCarrotSvg} alt="RightCarrotSvg" /></a>
        );
    }
}

