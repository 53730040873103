export const ADMIN_SUBMISSION_CONSTANT = {
    ADMIN_PAPER_DETAILS:'ica-admin/submission/paperDetails',
    ADMIN_PAPER_REMARK:'ica-admin/submission/submitPaperRemarks',
    ADMIN_PAPER_DOWNLOAD:'ica-admin/submission/downloadPaper',
    PAPER_DETAILS_COUNT:'ica-admin/submission/paperDetailsCount',
    PAPER_PRESENTATION_CART:'ica-admin/submission/allowPaperPresentationCert',
    EDIT_USER_DETAILS_API:'ica-admin/submission/updatePaperDetails'
}


export const paperStatusdropdown = [
   {Name:'Revision'},
   {Name:'Rejected'},
   {Name:'Accepted'},
]

export enum paperDetailsStatus {
  RE_SUBMITTED ="ReSubmitted",
  REVESION ='Revision',
  ACCEPTED='Accepted',
  REJECTED='Rejected',
  SUBMITTED= 'Submitted'
}