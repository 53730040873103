import { useAppSelector } from "@Src/app/hooks/hooks"
import { store } from "@Src/app/store"
import { getQrcode , getthankingYouPageDetails } from "../../services/client.service"
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppRouteURL } from "@Src/routes/app-route-urls"


export const QRScannerIndex = () => {
    const navigate =useNavigate()
    const [qrImageSrc, setQRImageSrc] = useState('');

    useEffect(()=>{
        converBase64toQRcode()
    },[])

 function converBase64toQRcode () {
    getQrcode().then((resp:APIResponse)=>{
        if(resp.ResponseType == APIStatus.SUCCESS){
            QRcodeFromBase64(resp.data) 
        }
    }).catch((error) => {
        console.log(error)
    })
   
 }


 function QRcodeFromBase64(base64Data:any) {
    const binaryData = atob(base64Data);     
    // Convert binary data to Data URL
    const dataUrl = `data:image/png;base64,${btoa(binaryData)}`; 
    // Set the Data URL as the source for the image
    setQRImageSrc(dataUrl);
  }



    return (
        <>
            <div className='msg-bx-1 msg-bx-flex mt-5 '>
                <div className='msg-bx-colls'>
                    <span className='msg-bx-title'>
                     Hello {store.getState().login.FirstName},
                    </span>
                    <p>  Thank you for registering on the ICA Conference Portal. To submit your travel details & paper, please
                        pay the conference fees. Once the conference is over, you can download your participation certificate by
                        providing your feedback. </p>

                    <p>  You can view your Registration details by <a href='javscript:void(0)' 
                    onClick={()=> navigate(`${AppRouteURL.THANKING_YOU}/?UID=${store.getState().login.LoginuserUID}&EmailId=${store.getState().login.email}`)}> 
                    clicking here </a> or by scanning the QR code. </p>
                </div>
                <div className='msg-bx-colls bar-scaner'>
                    <img src={qrImageSrc} />
                </div>
            </div>


        </>
    )

}