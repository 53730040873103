import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import Loader from "@Components/loader/Loader";
import { AdminLoginIndex } from "@Features/admin/login";
import { LoginPage } from "@Pages/login/Login";
import "primeicons/primeicons.css";
/**
 * @todo Please lazy load below components
 */
import { RegisterPage } from "@Pages/client/register/register";
import { DashboardPage } from "@Pages/client/dashboard/dashboard";
import { CertificatePage } from "@Pages/client/certificate/certificate";
import { FeedbackPage } from "@Pages/client/feedback/feedback";
import { FeeSubmissionPage } from "@Pages/client/fee-submission/fee-submission";
import { PaperSubmissionPage } from "@Pages/client/paper-submission/paper-submission";
import { TravelDetailsPage } from "@Pages/client/travel-details/travel-details";
import { RegisterIndexPage } from "@Pages/client/register";
import { SubmissionDetailsPage } from "@Pages/admin/submission-details/submissions";
import { RegisterSuccessPage } from "@Pages/client/register/register-success";
import { UnAuthorisedRoute, PrivateRoute, AdminPrivateRoute } from "@Src/utils/service/Authenticated-Routes";
import { MasterReportPage } from "@Pages/admin/master-report/masterreport";
import { ResetPasswordPage } from "@Pages/login/resetpassword";
import { UpdatePasswordPage } from "@Pages/login/updatepassword";
import { ThankingYouPage } from "@Src/features/client/homepage/component/thankingyoupage";
import { PaymentStatusPage } from "@Pages/client/payment-status/payment-status";
import { OfflineFeePayReportPage } from "@Pages/admin/offline-fee-pay-report/offline-fee-pay-report";
import { PrivacyPolicyPage } from "@Pages/client/privacy-policy";
import { ResearchScholarVerificatio } from "@Src/features/admin/research-scholar";
import { DonationSponsorshipPage } from "@Src/features/client/donation-sponsorship";
import { DonationPayemntSuccess } from "@Src/features/client/donation-sponsorship/component/donation-payment-success";
import { DonationPaidSuccessPage } from "@Src/features/client/donation-sponsorship/component/donation-paid";


export default function AppRoutes() {

    function RootPage() {
        const navigate = useNavigate();
      
        // Redirect to "/dashboard" when accessing the root URL ("/")
        React.useEffect(() => {
          navigate('/dashboard');
        }, [navigate]);
      
        return null; // You can return null or any other content you want
      }

    return (
        <>
            <Routes>



                <Route
                    path={AppRouteURL.LOGIN}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            {/* <LoginPage/> */}
                            <UnAuthorisedRoute component={LoginPage} />
                        </React.Suspense>
                    }
                />

                <Route
                    path={AppRouteURL.ADMIN_LOGIN}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AdminLoginIndex />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.SUBMISSION_DETAILS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AdminPrivateRoute component={SubmissionDetailsPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.OFFLINE_FEE_PAY_REPORT}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AdminPrivateRoute component={OfflineFeePayReportPage} />
                        </React.Suspense>
                    }
                />
                {/* <Route
                    path={AppRouteURL.REGISTER}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <UnAuthorisedRoute component={RegisterIndexPage} />
                        </React.Suspense>
                    }
                > <Route
                        path={""}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <UnAuthorisedRoute component={RegisterPage} />
                            </React.Suspense>
                        }
                    />

                </Route> */}
                <Route
                    path={AppRouteURL.REGISTER_SUCCESS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <RegisterSuccessPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.RESEARCH_SCHOLAR_VERIFICATION}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ResearchScholarVerificatio />
                        </React.Suspense>
                    }
                />
                 <Route
                    path={AppRouteURL.DONATION_SPONSOR_SHIP}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <DonationSponsorshipPage/>
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.DONATION_PAYMENT_STATUS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                           <DonationPayemntSuccess/>
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.DONATION_PAYEMENT_SUCCESS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <DonationPaidSuccessPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.UPDATE_PASSWORD}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <UpdatePasswordPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.RESET_PASSWORD}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ResetPasswordPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.DASHBOARD}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={DashboardPage} />
                        </React.Suspense>
                    }
                />
                  <Route path="/:randomValue" element={<RootPage/>} />
                  <Route path="/auth/register" element={<RootPage/>} />
                  <Route path="/" element={<RootPage/>} />



                <Route
                    path={AppRouteURL.THANKING_YOU}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={ThankingYouPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.PRIVACY_POLICY}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivacyPolicyPage/>
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.MASTER_REPORT}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AdminPrivateRoute component={MasterReportPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.CERTIFICATE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={CertificatePage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.FEEDBACK}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={FeedbackPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.FEE_SUBMISSION}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={FeeSubmissionPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.PAYMENT_STATUS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={PaymentStatusPage} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={AppRouteURL.PAPER_SUBMISSION}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={PaperSubmissionPage} />
                        </React.Suspense>
                    }
                />

                <Route
                    path={AppRouteURL.TRAVEL_DETAILS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivateRoute component={TravelDetailsPage} />
                        </React.Suspense>
                    }
                />
            </Routes>
        </>
    );
}
