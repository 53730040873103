import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoginuserUID: "",
  FirstName: "",
  Lastname: "",
  Token: "",
  email: "",
  isAdmin:0,
  MiddleName:""
};

const loginSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setUserUID: (state, action) => {
      state.LoginuserUID = action.payload;
    },
    setFirstName: (state, action) => {
      state.FirstName = action.payload;
    },
    setMiddleName: (state, action) => {
      state.MiddleName = action.payload;
    },
    setLastname: (state, action) => {
      state.Lastname = action.payload;
    },
    setToken: (state, action) => {
      state.Token = action.payload;
    },
    setEmailid: (state, action) => {
      state.email = action.payload;
    },
    setisAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
  },
});

export default loginSlice.reducer;
export const { setFirstName, setUserUID, setToken, setLastname , setEmailid , setisAdmin , setMiddleName} =
  loginSlice.actions;
