import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from "@Src/app/hooks/hooks";
import toast from "react-hot-toast";
import 'primeicons/primeicons.css';
import "./index.scss";
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { getCertificateDownload } from '../services/client.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Certificateimg1 from '@Src/assets/certificateimages/session1.png'
import Certificateimg2 from '@Src/assets/certificateimages/session2.png'
import Certificateimg3 from '@Src/assets/certificateimages/session3.png'
import Certificateimg4 from '@Src/assets/certificateimages/session4.png'
import Certificateimg5 from '@Src/assets/certificateimages/session5.png'
import Certificateimg6 from '@Src/assets/certificateimages/session6.png'
import Certificateimg7 from '@Src/assets/certificateimages/session7.png'
import attendance from '@Src/assets/certificateimages/attendance.png'
import { Dialog } from 'primereact/dialog';
import { CertificateToast } from './certificatetoast';


export const CertificateIndex = () => {
  const certificate = useAppSelector(state => state.global.CertificateAllow);
  const [attendancedata, setAttendanceData] = useState<any>([])
  const [Presentationdata, setPresentationData] = useState<any>([])
  const [visible, setVisible] = useState(false);




  useEffect(() => {
    Certificateapicall()
  }, [])

  const downloadParticipationCertificate = () => {
    if (Object.keys(attendancedata).length > 0) {
      const printWindow: any = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(
        `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
             <html xmlns="http://www.w3.org/1999/xhtml">
             <head>
             <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
             <title>Participation Certificate </title>
             </head>
             
             <body onload="window.print();window.close()" style="background:#f5f5f5; font-family:Arial, Helvetica, sans-serif; font-size:18px; font-weight:bold;">
             <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 auto; position: relative;">
               <tr>
               <td style="background-color:#fff; width:100%;  background-repeat: no-repeat; height:800px; padding:50px" valign="top">
               <img src="${attendance}" style="position: absolute; top:0; bottom:0; left:0; right:0;  z-index:10;" />
               <table width="100%" border="0" cellspacing="0" cellpadding="0">
               <tr>
                 <td style="padding: 260px 0 0 272px; font-weight:bold; z-index:777; position: absolute; top:5%; left:5%;">${attendancedata?.Name}</td>
               </tr>
               <tr>
                 <td  style="padding: 260px 0 0 12px; font-weight:bold; z-index:999; position: absolute; top:8.5%; left:7%;">${attendancedata?.University}</td>
               </tr>
             </table>
             
                 </td>
               </tr>
             </table>
             
             </body>
             </html>
             -`)
      printWindow.document.close();
    } else {
      toast.error("Something went wrong. Please try again.");
    }

  }
  function downloadPresentationCertificate(data: any) {
    if (Presentationdata.length > 0) {

      let imageUrl = data?.SessionID == "1" ? Certificateimg1
        : data?.SessionID == "2" ? Certificateimg2
          : data?.SessionID == "3" ? Certificateimg3
            : data?.SessionID == "4" ? Certificateimg4
            : data?.SessionID == "5" ? Certificateimg5
            : data?.SessionID == "6" ? Certificateimg6
              : Certificateimg7
      const printWindow: any = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
      <!DOCTYPE>
              <html xmlns="http://www.w3.org/1999/xhtml">
              <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <title>Paper Presentation Certificate </title>
              </head>
              
              <body onload="window.print();window.close()" style="background:#f5f5f5; font-family:Arial, Helvetica, sans-serif; font-size:18px; font-weight:bold;">
              <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 auto;  position: relative;">
                <tr>
                <td style=" background-color:#fff; width:100%;  background-repeat: no-repeat; height:800px; padding:50px" valign="top">
                <img src="${imageUrl}" style="position: absolute; top:0; bottom:0; left:0; right:0;  z-index:10;  " />
                <table width="100%" border="0" cellspacing="0" cellpadding="0" >
                <tr>
                  <td style="padding: 207px 0 0 250px; font-weight:bold; z-index:777; position: absolute; top:8.5%; left:5%;">${data?.Name}</td>
                </tr>
                <tr>
                  <td  style="padding: 207px 0 0 12px; font-weight:bold; z-index:999; position: absolute; top:12.5%; left:6%;">${data?.University}</td>
                </tr>
               <tr>
                  <td  style="padding: 10px 500px 0 0px;">&nbsp;</td>
                </tr>
               <tr>
                  <td  style="padding: 207px 0 0 12px; font-weight:bold; z-index:999; position: absolute; top:21%; left:10%;">${data?.PaperTitle} </td>
                </tr>
              </table>
              
                  </td>
                </tr>
              </table>
              
              </body>
              </html>
             `)
      printWindow.document.close();
      // document.body.appendChild(htmlcontent);
      // html2canvas(htmlcontent, { useCORS: true }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const pdf = new jsPDF("p", "mm", "a4");
      //   const pageWidth = pdf.internal.pageSize.getWidth(); // Get page width
      //   const pageHeight = pdf.internal.pageSize.getHeight(); // Get page height
      //   const aspectRatio = canvas.width / canvas.height;
      //   const imgWidth = pageWidth; // Set image width to page width
      //   const imgHeight = imgWidth / aspectRatio; // Calculate image height based on aspect ratio
      //   console.log('imgWidth', imgWidth)
      //   console.log('imgHeight', imgHeight)
      //   pdf.addImage(imgData, 'PNG', 10, 10, 250, 180);
      //   pdf.save('Paper Presentation Certificate.pdf');
      //   document.body.removeChild(htmlcontent);

      // })
    } else {
      toast.error("Something went wrong. Please try again.");

    }
  }
  const Certificateapicall = () => {
    getCertificateDownload().then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setAttendanceData(resp?.data?.attendance)
        setPresentationData(resp?.data?.data)
      }


    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      {certificate === 1 ?
        <div>
          <div className='content-heading-1'>
            Event Certificates
          </div>
          <div>
            <ul className='certificate-lists'>
              <li>
                <span className='ui-icons'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0003 30.3334H12.0003C4.76033 30.3334 1.66699 27.24 1.66699 20V12C1.66699 4.76002 4.76033 1.66669 12.0003 1.66669H18.667C19.2137 1.66669 19.667 2.12002 19.667 2.66669C19.667 3.21335 19.2137 3.66669 18.667 3.66669H12.0003C5.85366 3.66669 3.66699 5.85335 3.66699 12V20C3.66699 26.1467 5.85366 28.3334 12.0003 28.3334H20.0003C26.147 28.3334 28.3337 26.1467 28.3337 20V13.3334C28.3337 12.7867 28.787 12.3334 29.3337 12.3334C29.8803 12.3334 30.3337 12.7867 30.3337 13.3334V20C30.3337 27.24 27.2403 30.3334 20.0003 30.3334Z" fill="#292D32" />
                    <path d="M29.3337 14.3333H24.0003C19.4403 14.3333 17.667 12.56 17.667 8.00001V2.66667C17.667 2.26667 17.907 1.89334 18.2803 1.74667C18.6537 1.58667 19.0803 1.68001 19.3737 1.96001L30.0403 12.6267C30.3203 12.9067 30.4137 13.3467 30.2537 13.72C30.0937 14.0933 29.7337 14.3333 29.3337 14.3333ZM19.667 5.08001V8.00001C19.667 11.44 20.5603 12.3333 24.0003 12.3333H26.9203L19.667 5.08001Z" fill="#292D32" />
                    <path d="M17.333 18.3333H9.33301C8.78634 18.3333 8.33301 17.88 8.33301 17.3333C8.33301 16.7866 8.78634 16.3333 9.33301 16.3333H17.333C17.8797 16.3333 18.333 16.7866 18.333 17.3333C18.333 17.88 17.8797 18.3333 17.333 18.3333Z" fill="#292D32" />
                    <path d="M14.6663 23.6667H9.33301C8.78634 23.6667 8.33301 23.2134 8.33301 22.6667C8.33301 22.12 8.78634 21.6667 9.33301 21.6667H14.6663C15.213 21.6667 15.6663 22.12 15.6663 22.6667C15.6663 23.2134 15.213 23.6667 14.6663 23.6667Z" fill="#292D32" />
                  </svg>

                </span>
                <span className='certificate-text-1'> Paper Presentation Certificate </span>
                <span className='certificate-download-links' onClick={() => setVisible(true)}>
                  <a href='javascript:void(0)' className='button-links'> <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 17H18V19H0V17ZM10 11.172L16.071 5.1L17.485 6.514L9 15L0.515 6.515L1.929 5.1L8 11.17V0H10V11.172Z" fill="#1573FF" />
                  </svg>
                    Download </a>
                </span>
              </li>
              <li>
                <span className='ui-icons'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0003 30.3334H12.0003C4.76033 30.3334 1.66699 27.24 1.66699 20V12C1.66699 4.76002 4.76033 1.66669 12.0003 1.66669H18.667C19.2137 1.66669 19.667 2.12002 19.667 2.66669C19.667 3.21335 19.2137 3.66669 18.667 3.66669H12.0003C5.85366 3.66669 3.66699 5.85335 3.66699 12V20C3.66699 26.1467 5.85366 28.3334 12.0003 28.3334H20.0003C26.147 28.3334 28.3337 26.1467 28.3337 20V13.3334C28.3337 12.7867 28.787 12.3334 29.3337 12.3334C29.8803 12.3334 30.3337 12.7867 30.3337 13.3334V20C30.3337 27.24 27.2403 30.3334 20.0003 30.3334Z" fill="#292D32" />
                    <path d="M29.3337 14.3333H24.0003C19.4403 14.3333 17.667 12.56 17.667 8.00001V2.66667C17.667 2.26667 17.907 1.89334 18.2803 1.74667C18.6537 1.58667 19.0803 1.68001 19.3737 1.96001L30.0403 12.6267C30.3203 12.9067 30.4137 13.3467 30.2537 13.72C30.0937 14.0933 29.7337 14.3333 29.3337 14.3333ZM19.667 5.08001V8.00001C19.667 11.44 20.5603 12.3333 24.0003 12.3333H26.9203L19.667 5.08001Z" fill="#292D32" />
                    <path d="M17.333 18.3333H9.33301C8.78634 18.3333 8.33301 17.88 8.33301 17.3333C8.33301 16.7866 8.78634 16.3333 9.33301 16.3333H17.333C17.8797 16.3333 18.333 16.7866 18.333 17.3333C18.333 17.88 17.8797 18.3333 17.333 18.3333Z" fill="#292D32" />
                    <path d="M14.6663 23.6667H9.33301C8.78634 23.6667 8.33301 23.2134 8.33301 22.6667C8.33301 22.12 8.78634 21.6667 9.33301 21.6667H14.6663C15.213 21.6667 15.6663 22.12 15.6663 22.6667C15.6663 23.2134 15.213 23.6667 14.6663 23.6667Z" fill="#292D32" />
                  </svg>

                </span>
                <span className='certificate-text-1'> Participation Certificate </span>
                <span className='certificate-download-links' onClick={(data) => { downloadParticipationCertificate() }}>
                  <a href='javascript:void(0)' className='button-links'> <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 17H18V19H0V17ZM10 11.172L16.071 5.1L17.485 6.514L9 15L0.515 6.515L1.929 5.1L8 11.17V0H10V11.172Z" fill="#1573FF" />
                  </svg>
                    Download </a>
                </span>
              </li>
            </ul>
          </div>
        </div> : <CertificateToast />}

      <Dialog className="md-dialog" draggable={false} header="" visible={visible} onHide={() => setVisible(false)}>
        <ul className="paper-presentation-download">
          {Presentationdata && Presentationdata.map((list: any) => {
            return (<li className='paper-title'>
              {list?.PresentationCertificateAllowed == 1 && <span>
                {list?.presentation?.PaperTitle}  <span className='certificate-download-links tw-ml-1' onClick={() => downloadPresentationCertificate(list?.presentation)}>
                  <a href='javascript:void(0)' className='button-links'> <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 17H18V19H0V17ZM10 11.172L16.071 5.1L17.485 6.514L9 15L0.515 6.515L1.929 5.1L8 11.17V0H10V11.172Z" fill="#1573FF" />
                  </svg>
                    Download </a>
                </span>
              </span>}

            </li>)
          })}

        </ul>
      </Dialog>
    </>
  )
}