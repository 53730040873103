import React  from "react";
import {CertificateIndex} from "@Features/client/certificate";

export const CertificatePage = () => {


  return (
    <div>
<CertificateIndex/>
    </div>
  );
};
