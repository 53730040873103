import React, { useEffect, useRef, useState } from 'react'
import 'primeicons/primeicons.css';
import "./index.scss";
import { useAppSelector, useAppDispatch } from '@Src/app/hooks/hooks';
import { getpaperSession, paperSubmissionAllowed, submitpaper } from './service/papersubmission.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { useFormik } from 'formik';
import { FileUploadType, FileSize, FileCategory, PaperSession } from './schema/papersubmission.schema';
import { toast } from 'react-hot-toast';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { PaperDetailsList } from './component/paperDetail';
import { hideSpinner, setAgainSubmitpaper, setAllowPaperSubmissionFlag, setPaperAttempted, setRevisionPaperSubmit, showSpinner } from '@Src/app/reducers/global/globalSlice';
import { PaperSubmissionToastIndex } from './component/toast';
import RigthArrowSvg from "@Src/assets/svg/commonsvg/rightarrow.svg"
import { store } from '@Src/app/store';
import { object } from 'yup';
import { offlineFeePaymentStatusEnum } from '@Src/app/constants/app.constant';
import PlusIconSvg from "@Src/assets/svg/commonsvg/plusicon.svg"
import CancelIconSvg from "@Src/assets/svg/commonsvg/cancelicon.svg"
import * as Yup from 'yup';


export const PaperSubmissionIndex = () => {


    const dispatch = useAppDispatch()
    const [abstarctfilename, setabstarctfilename] = useState<any>()
    const [abstractfileuploaded, setabstarctfileuploaded] = useState<any>()
    const [fullpaperfilename, setFullpaperfilename] = useState<any>()
    const [fullpaperfileuploaded, setfullpaperfileuploaded] = useState<any>()
    const payment = useAppSelector(state => state.global.paymentstatus)
    const offlineFeePaymentStatus = useAppSelector(state => state.global.offlineFeePaymentStatus)
    const againsubmitpaper = useAppSelector(state => state.global.againSubmitpaper)
    const revesinonpaperdetails: any = useAppSelector(state => state.global.revesionpapersubmit)
    const paperAttemted = useAppSelector(state => state.global.paperAttempted)
    const [sessiondropdowndata, setSessiondropdowndata] = useState([]);
    const [authordata, setAuthordata] = useState<any>([]);
    const [fileerrors, setFileerrors] = useState(false)

    const papersubmission = Yup.object({
        session: Yup.string().ensure().required('Session is required'),
        paperTitle: Yup.string().ensure().required('Title of the paper is required'),
        similarityIndex: Yup.string().ensure().required('Plagiarism is required'),
    })

    useEffect(() => {
        getSessiondropdown()
        PaperSubmissionAllowedfun()
    }, [])

    const PaperSubmissionInitialform = {
        session: revesinonpaperdetails ? Number(revesinonpaperdetails.SessionID) : "",
        paperTitle: revesinonpaperdetails ? revesinonpaperdetails?.TitleOfPaper : "",
        abstract: "",
        fullpaper: "",
        author: "",
        isPresentPaper: undefined,
        similarityIndex: 0,
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, setFieldValue } = useFormik({
        initialValues: PaperSubmissionInitialform,
        validationSchema: papersubmission,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async (e, { resetForm }) => {
            if (store.getState().global.allowPaperSubmissionFlag) {
                papersubmissionapi(e, resetForm)
            }
        },
    });

    function onClearRevesionData() {
        dispatch(setRevisionPaperSubmit(''))
        setFileerrors(false)

    }

    function PaperSubmissionAllowedfun() {
        paperSubmissionAllowed().then((resp: APIResponse) => {
            if (resp.ResponseType === APIStatus.SUCCESS) {
                dispatch(setAllowPaperSubmissionFlag(resp?.data?.IsPaperSubmissionAllowed))
            }

        }).catch(error => {
            console.log(error)
        })
    }

    const papersubmissionapi = (e: any, resetForm: any) => {
        if (abstractfileuploaded && fullpaperfileuploaded) {
            dispatch(showSpinner())
            let data = {
                "session": e.session,
                "paperTitle": e.paperTitle,
                "paperSubmissionAuthors": authordata ? authordata : [],
                'isPresentPaper': e.isPresentPaper == 1 ? 1 : 0,
                "similarityIndex": e.similarityIndex
            }
            console.log(data)
            const formData: any = new FormData()
            formData.append("plagiarismReport", abstractfileuploaded);
            formData.append('fullpaper', fullpaperfileuploaded)
            formData.append('payload', JSON.stringify(data))
            submitpaper(formData).then((resp: APIResponse) => {
                if (resp.ResponseType === APIStatus.SUCCESS) {
                    dispatch(hideSpinner())
                    onClearRevesionData()
                    resetForm(PaperSubmissionInitialform)
                    setabstarctfileuploaded(null)
                    setfullpaperfileuploaded(null)
                    toast.success('Your Paper Submited successfully')
                    dispatch(setAgainSubmitpaper(true))
                    dispatch(setPaperAttempted(1))
                    setAuthordata([])
                    setFileerrors(false)


                }
                else {
                    dispatch(hideSpinner())
                }
            }).catch(error => {
                dispatch(hideSpinner())
                console.log(error)
            })
        } else {
            setFileerrors(true)
        }
    }
    const uploadFiles = (filesToUpload: FileList | any,
        fileCategory: string) => {
        let file = filesToUpload[0]
        const fileSizeError: any = checkFileSize(file);
        if (fileSizeError) {
            toast.error('File size is too large');
            if (fileCategory === FileCategory.ABSTRACT) {
                let clearFileUpload: any = document.getElementById('FileInputAbstract')
                clearFileUpload.value = ''
            }
            if (fileCategory === FileCategory.FULL_PAPER) {
                let clearFileUpload: any = document.getElementById('FileInputFullPaper')
                clearFileUpload.value = ''
            }

            return;
        }

        const fileTypeError: any = checkAllowedExtentions(file);
        if (fileTypeError) {
            toast.error('Only PDF format is allowed');
            return;
        }
        if (file.type === FileUploadType.UPLOAD_FILE_TYPE) {
            if (file.size <= FileSize.TWENTY_MB) {
                if (fileCategory === FileCategory.ABSTRACT) {
                    setabstarctfilename(file.name)
                    setabstarctfileuploaded(file)
                } else if (fileCategory === FileCategory.FULL_PAPER) {
                    setFullpaperfilename(file.name)
                    setfullpaperfileuploaded(file)
                }

            }
        }
    }



    function checkFileSize(file: any) {
        if (file.size > FileSize.TWENTY_MB) {
            return true;
        }
        return false;
    }


    function checkAllowedExtentions(category: any) {
        if (category.type != FileUploadType.UPLOAD_FILE_TYPE) {
            return true;
        }
        return false;
    }

    const removeuploaded = (category: any) => {
        if (category === FileCategory.ABSTRACT) {
            setabstarctfileuploaded(null)
        } else if (category === FileCategory.FULL_PAPER) {
            setfullpaperfileuploaded(null)
        }

    }

    function clickedBackHandler() {
        onClearRevesionData()
        dispatch(setAgainSubmitpaper(true))
    }

    function addauthorhandler() {
        if (values.author && authordata.length < 5) {
            let newauthor: any = values?.author
            setAuthordata([...authordata, newauthor])
            values.author = ''
        } else if (authordata.length >= 5) {
            toast.error('Maximum 5 authors are allowed')
        }

    }

    function onclearauthorhandler(id: any) {
        const updateauthor: any = authordata.filter((list: any, i: any) => i !== id)
        setAuthordata(updateauthor)
    }

    function getSessiondropdown() {
        getpaperSession().then((response: APIResponse) => {
            if (response.ResponseType == APIStatus.SUCCESS) {
                setSessiondropdowndata(response.data)
            }
        }).catch((error: any) => {
            console.log(error)
        })
    }

    function submitHandler() {
        if (store.getState().global.allowPaperSubmissionFlag) {
            if (!abstractfileuploaded && !fullpaperfileuploaded) {
                setFileerrors(true)
            }
        }else{
            toast.success('Paper Submission is closed')
        }
    }

    return (
        <>
            {(payment == 1 || offlineFeePaymentStatus == offlineFeePaymentStatusEnum.ACCEPTED) ?
                <div>
                    {!againsubmitpaper ? <div className=''>
                        <div className='page-header-1'>
                            <div className="tw-flex tw-items-center tw-mb-8">
                                {paperAttemted === 1 && <img className="tw-mr-1 tw-cursor-pointer" onClick={() => clickedBackHandler()} src={RigthArrowSvg} alt="RigthArrowSvg" />}
                                <h2 className='master-head'> Paper Submission </h2>
                            </div>
                        </div>
                        <div className='page-content'>
                            <form onSubmit={handleSubmit}>
                                <div className='group-form col-row mx-width-1'>
                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Select Session </label>
                                            <div>
                                                <Dropdown value={values.session}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => setFieldValue('session', e.value)}
                                                    options={sessiondropdowndata}
                                                    optionLabel="Name"
                                                    optionValue='Id'
                                                    name='session'
                                                    placeholder="Please select the session" className="w-full"
                                                    disabled={revesinonpaperdetails ? true : false} />
                                            </div>
                                            {(errors.session && touched.session && store.getState().global.allowPaperSubmissionFlag) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors.session}</p> : null}
                                        </div>
                                    </div>
                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Title of the paper </label>
                                            <div>
                                                <InputText
                                                    value={values.paperTitle}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='paperTitle' className='form-control-t1'
                                                    placeholder='Please enter the Title of the Paper'
                                                    disabled={revesinonpaperdetails ? true : false} />
                                            </div>
                                            {(errors.paperTitle && touched.paperTitle && store.getState().global.allowPaperSubmissionFlag) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Title of the paper is required</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Author(s) of the paper</label>
                                            <div className='custom-input-box'>
                                                <InputText
                                                    value={values.author}
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    autoComplete='off'
                                                    name='author' className='form-control-t1'
                                                    placeholder='Please enter the author of the paper'
                                                />
                                                <img src={PlusIconSvg} alt='PlusIconSvg' onClick={() => addauthorhandler()} />
                                            </div>
                                            <div className='author-card'>
                                                {authordata && authordata.map((author: any, index: any) => {
                                                    return (
                                                        <div className='auht-namr' key={index}>
                                                            <span onClick={() => onclearauthorhandler(index)}>
                                                                <img src={CancelIconSvg} alt="CancelIconSvg" />
                                                            </span>
                                                            {author}</div>

                                                    )
                                                })}
                                            </div>

                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Full Paper Submission (with abstract)</label>
                                            <div>
                                                {!fullpaperfileuploaded ? <label className="upload-files-ui">
                                                    <span className='upload-files-pic'>
                                                        <i className="pi pi-cloud-upload"></i>
                                                    </span>
                                                    <span className='file-upload-titles'>
                                                        <span className="file-upload-title-1">
                                                            Upload Full Paper
                                                        </span>
                                                        <span className="file-upload-title-2">
                                                            Only PDF format. Max Size 20 MB
                                                        </span>
                                                    </span>
                                                    <input className="FileUpload1"
                                                        onChange={(e) => {
                                                            uploadFiles(
                                                                e?.target?.files, FileCategory.FULL_PAPER
                                                            )
                                                        }} id="FileInputFullPaper" name="booking_attachment" type="file" />

                                                </label> :
                                                    <div className='uploaded-card'>
                                                        <div className='upload-file'>
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.9998 30.3334H11.9998C4.75984 30.3334 1.6665 27.24 1.6665 20V12C1.6665 4.76002 4.75984 1.66669 11.9998 1.66669H18.6665C19.2132 1.66669 19.6665 2.12002 19.6665 2.66669C19.6665 3.21335 19.2132 3.66669 18.6665 3.66669H11.9998C5.85317 3.66669 3.6665 5.85335 3.6665 12V20C3.6665 26.1467 5.85317 28.3334 11.9998 28.3334H19.9998C26.1465 28.3334 28.3332 26.1467 28.3332 20V13.3334C28.3332 12.7867 28.7865 12.3334 29.3332 12.3334C29.8798 12.3334 30.3332 12.7867 30.3332 13.3334V20C30.3332 27.24 27.2398 30.3334 19.9998 30.3334Z" fill="#292D32" />
                                                                <path d="M29.3332 14.3333H23.9998C19.4398 14.3333 17.6665 12.56 17.6665 8.00001V2.66667C17.6665 2.26667 17.9065 1.89334 18.2798 1.74667C18.6532 1.58667 19.0798 1.68001 19.3732 1.96001L30.0398 12.6267C30.3198 12.9067 30.4132 13.3467 30.2532 13.72C30.0932 14.0933 29.7332 14.3333 29.3332 14.3333ZM19.6665 5.08001V8.00001C19.6665 11.44 20.5598 12.3333 23.9998 12.3333H26.9198L19.6665 5.08001Z" fill="#292D32" />
                                                                <path d="M17.3335 18.3333H9.3335C8.78683 18.3333 8.3335 17.88 8.3335 17.3333C8.3335 16.7866 8.78683 16.3333 9.3335 16.3333H17.3335C17.8802 16.3333 18.3335 16.7866 18.3335 17.3333C18.3335 17.88 17.8802 18.3333 17.3335 18.3333Z" fill="#292D32" />
                                                                <path d="M14.6668 23.6667H9.3335C8.78683 23.6667 8.3335 23.2134 8.3335 22.6667C8.3335 22.12 8.78683 21.6667 9.3335 21.6667H14.6668C15.2135 21.6667 15.6668 22.12 15.6668 22.6667C15.6668 23.2134 15.2135 23.6667 14.6668 23.6667Z" fill="#292D32" />
                                                            </svg>
                                                        </div>
                                                        <div className='file-name-card'>
                                                            <div className='file-name'>{fullpaperfilename}</div>
                                                            <div className='file-status'>Uploaded</div>
                                                        </div>

                                                        <span className='remove-upload' onClick={() => removeuploaded(FileCategory.FULL_PAPER)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_404_30422)">
                                                                    <path d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z" fill="#ED1C24" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_404_30422">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                            Remove
                                                        </span>

                                                    </div>}

                                            </div>
                                            <div className='paper-note'>
                                                *For paper submission guidelines, please refer to brochure</div>
                                            {(!fullpaperfileuploaded && fileerrors) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Full Paper Submission is required</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'> Would you like to present the paper? </label>
                                            <div className='inline-list-row'>

                                                <label className='radio-label inline-list-coll'>
                                                    <input type='radio'
                                                        name='isPresentPaper'
                                                        value={values.isPresentPaper}
                                                        onChange={(e) => setFieldValue('isPresentPaper', 1)}
                                                        checked={values.isPresentPaper === 1} />Yes
                                                </label>
                                                <label className='radio-label inline-list-coll paper-right-radio'>
                                                    <input type='radio'
                                                        name='isPresentPaper'
                                                        value={values.isPresentPaper}
                                                        onChange={(e) => setFieldValue('isPresentPaper', 0)}
                                                        checked={values.isPresentPaper === 0} /> No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <label className='form-label-1'>Plagiarism (in percentage) </label>
                                            <div>
                                                <InputNumber
                                                    value={values.similarityIndex}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name='similarityIndex'
                                                    showButtons min={0} max={100}
                                                    className='num-range'
                                                />
                                            </div>
                                            {(errors.similarityIndex && touched.similarityIndex) ?
                                                <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">{errors?.similarityIndex}</p> : null}
                                        </div>
                                    </div>

                                    <div className='group-form-row'>
                                        <div className='group-form-colls'>
                                            <div>

                                                {!abstractfileuploaded ? <label className="upload-files-ui">
                                                    <span className='upload-files-pic'>
                                                        <i className="pi pi-cloud-upload"></i>
                                                    </span>
                                                    <span className='file-upload-titles'>
                                                        <span className="file-upload-title-1">
                                                            Upload Plagiarism report
                                                        </span>
                                                        <span className="file-upload-title-2">
                                                            Only PDF format. Max Size 20 MB
                                                        </span>
                                                    </span>
                                                    <input className="FileUpload1"
                                                        onChange={(e) => {
                                                            uploadFiles(
                                                                e?.target?.files, FileCategory.ABSTRACT
                                                            )
                                                        }} id="FileInputAbstract" name="booking_attachment" type="file" />

                                                </label> :
                                                    <div className='uploaded-card'>
                                                        <div className='upload-file'>
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.9998 30.3334H11.9998C4.75984 30.3334 1.6665 27.24 1.6665 20V12C1.6665 4.76002 4.75984 1.66669 11.9998 1.66669H18.6665C19.2132 1.66669 19.6665 2.12002 19.6665 2.66669C19.6665 3.21335 19.2132 3.66669 18.6665 3.66669H11.9998C5.85317 3.66669 3.6665 5.85335 3.6665 12V20C3.6665 26.1467 5.85317 28.3334 11.9998 28.3334H19.9998C26.1465 28.3334 28.3332 26.1467 28.3332 20V13.3334C28.3332 12.7867 28.7865 12.3334 29.3332 12.3334C29.8798 12.3334 30.3332 12.7867 30.3332 13.3334V20C30.3332 27.24 27.2398 30.3334 19.9998 30.3334Z" fill="#292D32" />
                                                                <path d="M29.3332 14.3333H23.9998C19.4398 14.3333 17.6665 12.56 17.6665 8.00001V2.66667C17.6665 2.26667 17.9065 1.89334 18.2798 1.74667C18.6532 1.58667 19.0798 1.68001 19.3732 1.96001L30.0398 12.6267C30.3198 12.9067 30.4132 13.3467 30.2532 13.72C30.0932 14.0933 29.7332 14.3333 29.3332 14.3333ZM19.6665 5.08001V8.00001C19.6665 11.44 20.5598 12.3333 23.9998 12.3333H26.9198L19.6665 5.08001Z" fill="#292D32" />
                                                                <path d="M17.3335 18.3333H9.3335C8.78683 18.3333 8.3335 17.88 8.3335 17.3333C8.3335 16.7866 8.78683 16.3333 9.3335 16.3333H17.3335C17.8802 16.3333 18.3335 16.7866 18.3335 17.3333C18.3335 17.88 17.8802 18.3333 17.3335 18.3333Z" fill="#292D32" />
                                                                <path d="M14.6668 23.6667H9.3335C8.78683 23.6667 8.3335 23.2134 8.3335 22.6667C8.3335 22.12 8.78683 21.6667 9.3335 21.6667H14.6668C15.2135 21.6667 15.6668 22.12 15.6668 22.6667C15.6668 23.2134 15.2135 23.6667 14.6668 23.6667Z" fill="#292D32" />
                                                            </svg>
                                                        </div>
                                                        <div className='file-name-card'>
                                                            <div className='file-name'>{abstarctfilename}</div>
                                                            <div className='file-status'>Uploaded</div>
                                                        </div>

                                                        <span className='remove-upload' onClick={() => removeuploaded(FileCategory.ABSTRACT)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_404_30422)">
                                                                    <path d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z" fill="#ED1C24" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_404_30422">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                            Remove
                                                        </span>

                                                    </div>}
                                                {(!abstractfileuploaded && fileerrors) ?
                                                    <p className="tw-text-red-500 tw-text-sm tw-mt-2 tw-ml-1">Plagiarism report is required</p> : null}

                                            </div>
                                        </div>
                                    </div>

                                    <div className='paper-note'>
                                        *Please note that if plagiarism is more than 10%,
                                        than the <br /> submitted paper will not be considered for any prize/reward
                                    </div>




                                    <div className='group-form-row mt-4'>
                                        <div className='group-form-footer mt-0'>
                                            <button type='submit' className='button-primary active' onClick={() => submitHandler()}> Submit <i className="pi pi-angle-right ml-1"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </form>



                        </div>
                    </div> :
                        <PaperDetailsList />}
                </div> :
                <PaperSubmissionToastIndex />
            }


        </>
    )
}