export const TRAVEL_DETAILS_CONSTANT = {
  TRAVEL_DETAILS: "ica/travel/travelDetails",
};

export const TravelTerminals = [
  { Name: "Terminal 1", id: "T1" },
  { Name: "Terminal 2", id: "T2" },
  { Name: "Terminal 3", id: "T3" },
];

export const TravelPlace = [{ Name: "Delhi" }];
export const TrainTravelPlace = [
{Name:'New Delhi Railway Station'},
{Name:'Old Delhi Railway Station'},
{Name:'Hazrat Nizamuddin Railway Station'},
{Name:'Anand Vihar Railway Station'},
{Name:'Sarai Rohilla Railway Station'},

]

export const TimeTravel = [
  { Name: "00:00 AM - 01:00 AM", Id: 1 },
  { Name: "01:00 AM - 02:00 AM", Id: 2 },
  { Name: "02:00 AM - 03:00 AM", Id: 3 },
  { Name: "03:00 AM - 04:00 AM", Id: 4 },
  { Name: "04:00 AM - 05:00 AM", Id: 5 },
  { Name: "05:00 AM - 06:00 AM", Id: 6 },
  { Name: "06:00 AM - 07:00 AM", Id: 7 },
  { Name: "07:00 AM - 08:00 AM", Id: 8 },
  { Name: "08:00 AM - 09:00 AM", Id: 9 },
  { Name: "09:00 AM - 10:00 AM", Id: 10 },
  { Name: "10:00 AM - 11:00 AM", Id: 11 },
  { Name: "11:00 AM - 12:00 PM", Id: 12 },
  { Name: "12:00 PM - 01:00 PM", Id: 13 },
  { Name: "01:00 PM - 02:00 PM", Id: 14 },
  { Name: "02:00 PM - 03:00 PM", Id: 15 },
  { Name: "03:00 PM - 04:00 PM", Id: 16 },
  { Name: "04:00 PM - 05:00 PM", Id: 17 },
  { Name: "05:00 PM - 06:00 PM", Id: 18 },
  { Name: "06:00 PM - 07:00 PM", Id: 19 },
  { Name: "07:00 PM - 08:00 PM", Id: 20 },
  { Name: "08:00 PM - 09:00 PM", Id: 21 },
  { Name: "09:00 PM - 10:00 PM", Id: 22 },
  { Name: "10:00 PM - 11:00 PM", Id: 23 },
  { Name: "11:00 PM - 00:00 AM", Id: 24 },

];
