import React  from "react";
import {FeedbackIndex} from "@Features/client/feedback";

export const FeedbackPage = () => {


  return (
    <div>
       <FeedbackIndex/>
    </div>
  );
};
