import { useFormik } from "formik";
import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react";
import './index.scss';
import { getverifyscholar, postverifyscholar } from "@Src/features/client/register/services/register.service";
import { APIResponse, APIStatus } from "@Src/services/bookstore/baseInterfaces";


export const ResearchScholarVerificatio = () => {
    const [scholardetails, setScholardetails] = useState<any>([])
    const [verifiedsuccess, setverifiedsuccess] = useState(false)
    const [initialresearchform, setinitialresearchform] = useState({
        scholarName: '',
        scholaoEmail: '',
        scholaomobile: '',
        scholaogender: '',
        supervisorName: '',
        supervisoEmail: '',
        supervisoDepartment: '',
        supervisouniversity: '',
        hodName: '',
        hodEmail: '',
        hodDepartment: '',
        hodouniversity: '',
    })
    const params = new URLSearchParams(window.location.search);
    const Type = params.get('Type');
    const emailId: any = params.get('Email');

    useEffect(() => {
        getresearchscholardetails()
    }, [Type, emailId])

    function getresearchscholardetails() {
        let data = {
            "Email": emailId,
            "Type": Type
        }
        getverifyscholar(data).then((resp: APIResponse) => {
            if (resp.ResponseType == APIStatus.SUCCESS) {
                setScholardetails(resp?.data)
            }
        }).catch((error: any) => {
            console.log(error)
        })
    }

    function submitpostverifieddetails() {
        if (Type && emailId) {
            let data = {
                "Email": emailId,
                "Type": Type
            }
            postverifyscholar(data).then((resp: APIResponse) => {
                if (resp.ResponseType == APIStatus.SUCCESS) {
                    setverifiedsuccess(true)

                }
            }).catch((error: any) => {
                console.log(error)
            })

        }
    }

    useEffect(() => {
        if (scholardetails) {
            setinitialresearchform((prevState: any) => {
                return {
                    ...prevState,
                    scholarName: scholardetails?.ResearchScholarDetails && scholardetails?.ResearchScholarDetails[0]?.Name,
                    scholaoEmail: scholardetails?.ResearchScholarDetails ? scholardetails?.ResearchScholarDetails[0]?.EmailId : '',
                    scholaomobile: scholardetails?.ResearchScholarDetails ? scholardetails?.ResearchScholarDetails[0]?.PrMobileNumber : '',
                    scholaogender: scholardetails?.ResearchScholarDetails ?
                        (scholardetails?.ResearchScholarDetails[0]?.Gender == 1 && 'Male' ||
                            scholardetails?.ResearchScholarDetails[0]?.Gender == 1 && 'Female' ||
                            scholardetails?.ResearchScholarDetails[0]?.Gender == 3 && 'Other')
                        : '',
                    supervisorName: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[0]?.Name : '',
                    supervisoEmail: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[0]?.Email : '',
                    supervisoDepartment: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[0]?.Department : '',
                    supervisouniversity: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[0]?.University : '',
                    hodName: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[1]?.Name : '',
                    hodEmail: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[1]?.Email : '',
                    hodDepartment: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[1]?.Department : '',
                    hodouniversity: scholardetails?.HodSupervisorDetails ? scholardetails?.HodSupervisorDetails[1]?.University : '',

                };
            });
        }
    }, [scholardetails])




    const { handleChange, handleBlur, values, } = useFormik({
        initialValues: initialresearchform,
        validationSchema: "",
        enableReinitialize: true,
        onSubmit: (e) => {
        },
    });
    return (
        <>
            {scholardetails && <div className="research-scholar-very-card">
                <div className="r-s-header">
                    Verification of Research Scholar
                </div>
                <div className='group-form col-row'>
                    <label className='form-label-1 tw-mt-6'>Research Scholar Details</label>
                    <div className="supervise-hod-card">
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Research Scholar’s Name<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.scholarName}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='scholarName' className='form-control-t1'
                                        placeholder='Enter Name'

                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Research Scholar’s Email<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.scholaoEmail}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='scholaoEmail' className='form-control-t1'
                                        placeholder='Enter Email'
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Research Scholar’s Mobile<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.scholaomobile}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='scholaomobile' className='form-control-t1'
                                        placeholder='Enter Mobile Number'
                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Research Scholar’s Gender<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.scholaogender}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='scholaogender' className='form-control-t1'
                                        placeholder='Enter Gender'

                                    />

                                </div>
                            </div>

                        </div>
                    </div>



                    <label className='form-label-1 tw-mt-6'>Supervisor Details</label>
                    <div className="supervise-hod-card">
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Supervisor’s Name<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.supervisorName}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='supervisorName' className='form-control-t1'
                                        placeholder='Enter Name'

                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Supervisor’s Email<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.supervisoEmail}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='supervisoEmail' className='form-control-t1'
                                        placeholder='Enter Email'
                                    />

                                </div>
                            </div>



                        </div>
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Supervisor’s Department<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.supervisoDepartment}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='supervisoDepartment' className='form-control-t1'
                                        placeholder='Enter Department'
                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>Supervisor’s University<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.supervisouniversity}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='supervisouniversity' className='form-control-t1'
                                        placeholder='Enter University'

                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                    <label className='form-label-1'>HOD Details</label>
                    <div className="supervise-hod-card">
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>HOD’s Name<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.hodName}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='hodName' className='form-control-t1'
                                        placeholder='Enter Name'

                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>HOD’s Email<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.hodEmail}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='hodEmail' className='form-control-t1'
                                        placeholder='Enter Email'
                                    />

                                </div>
                            </div>



                        </div>
                        <div className='group-form-row'>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>HOD’s Department<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.hodDepartment}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='hodDepartment' className='form-control-t1'
                                        placeholder='Enter Department'
                                    />

                                </div>
                            </div>
                            <div className='group-form-colls'>
                                <label className='form-label-1'>HOD’s University<span className='tw-text-red-500'>*</span> </label>
                                <div>
                                    <InputText
                                        value={values.hodouniversity}
                                        onChange={(e) => { handleChange(e) }}
                                        autoComplete='off'
                                        name='hodouniversity' className='form-control-t1'
                                        placeholder='Enter University'

                                    />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {verifiedsuccess ? <span className="veriy-status">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2101_2997)">
                            <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z" fill="#25C16F" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2101_2997">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    Verification Successful
                </span> : <button type="submit" className="button-primary active " onClick={() => submitpostverifieddetails()}>Verify</button>
                }            </div>}
        </>
    )
}