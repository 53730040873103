import { useEffect, useRef } from "react";
import { Toast } from 'primereact/toast';
import { useAppSelector } from "@Src/app/hooks/hooks";


export const CertificateToast = () => {
    const certificate = useAppSelector(state => state.global.CertificateAllow);

    const toast: any = useRef(null);

    useEffect(() => {
        setTimeout(() => {
          showSuccess()
        }, 100)
    
      }, [certificate === 0])

    const showSuccess = () => {
        toast.current.show({ severity: 'info', summary: <span className='toast-msg'>Message</span>, detail: <span className='toast-msg'>Certificates will be available after conference</span>, life: 3000 });
    }

    return (
        <>
            <Toast ref={toast} position="top-center" />
        </>
    )
}