import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { AppRouteURL } from "@Src/routes/app-route-urls";
import "./new-applicant.scss";
import BannerImge from '@Src/assets/images/bannerimg.png'
import toast from 'react-hot-toast';
import { Dialog } from 'primereact/dialog';



export const NewApplicantLogin = (props: any) => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <div className='content-cols new-applicant-cols'>
                <div className='tw-flex tw-items-center tw-justify-between  '>
                    <div className='content-heading-1'>
                        New Applicant
                    </div>
                </div>

                <div className='tw-hidden md:tw-block'>
                    <div className='content-imgs'>
                        <img className='mx-img' src={BannerImge} />
                    </div>
                    <div className='mt-5'>
                        <button className="button-primary active" onClick={() => setVisible(true)}>Register <i className="pi pi-angle-right ml-1"></i></button>
                    </div>
                </div>

            </div>

            <Dialog className="sm-dialog" draggable={false} header="" visible={visible} onHide={() => setVisible(false)} closable={false}>
                <div className="tw-p-3">
                    <div className="content-heading-1 text-center">Registration has been closed</div>
                    <div className='text-center'>
                        <button className="button-primary active col-4" onClick={() => setVisible(false)}>Ok  </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
