
import './index.scss';

export const PrivacyPolicyIndex = () => {
    return (
        <>
            <div className='privacy-policy-page'>
                <div className='prvy-heading'>Privacy Policy for74th AICC of Indian Commerce Association</div>
                <div className='p-p-date'>Effective Date: 10/09/2023</div>

                <div>
                    This Privacy Policy describes how we collect, use, disclose, and protect the personal information of users of 
                    the ICA Conference Participant Onboarding website (the "[ICA74]( <a href='https://aicc74.commerce.du.ac.in/' className='a-url'>https://aicc74.commerce.du.ac.in/</a>. 
)"). By using our Website, you consent to the practices described in this Privacy Policy.
                </div>

                <div className='p-p-sub-head'>Information We Collect</div>
                <ul className='p-p-ul-card'>
                    <li ><span className='p-p-highligt'>Personal Information:</span> When you register for the conference or use certain features of our Website,
                        we may collect personal information such as your name, email address, mobile number, and payment details</li>
                    <li ><span className='p-p-highligt'>Payment Information:</span>If you make payments through our Website, we may collect payment information,
                        including credit card details or bank transfer information </li>
                    <li ><span className='p-p-highligt'>Travel Details:</span> For conference planning and logistics, we may collect
                        information about your travel arrangements, including arrival and departure dates, mode of travel, and related details. </li>
                    <li ><span className='p-p-highligt'>Paper Submission:</span>If you submit research papers, we may collect details related to your paper, such as the title,
                        abstract, and full paper in PDF format.</li>
                    <li ><span className='p-p-highligt'>Feedback:</span> When you provide feedback, we collect information about your experiences and opinions related to the conference.</li>
                </ul>

                <div className='p-p-sub-head'>How We Use Your Information</div>
                <span className='p-ul-sub-head'>We may use your personal information for the following purposes:</span>
                <ul className='p-p-ul-card'>
                    <li ><span className='p-p-highligt'></span>To process conference registrations and payments. </li>
                    <li ><span className='p-p-highligt'></span>To facilitate your participation in the conference, including paper submission, travel arrangements, and feedback collection. </li>
                    <li ><span className='p-p-highligt'></span>To communicate with you regarding conference updates, changes, and important announcements. </li>
                    <li ><span className='p-p-highligt'></span>To improve our Website and services. </li>
                    <li ><span className='p-p-highligt'></span>To comply with legal obligations and protect our rights. </li>
                </ul>

                <div className='p-p-sub-head'>Disclosure of Your Information</div>
                <span className='p-ul-sub-head'>We may share your personal information with third parties under the following circumstances:</span>
                <ul className='p-p-ul-card'>
                    <li ><span className='p-p-highligt'></span>With service providers and partners who assist in conference logistics and operations. </li>
                    <li ><span className='p-p-highligt'></span>With authorized personnel, such as session chairs, for the purpose of paper evaluation and presentation. </li>
                    <li ><span className='p-p-highligt'></span>With law enforcement or regulatory authorities when required by law or to protect our legal rights. </li>
                </ul>

                <div className='p-p-sub-head'>Data Security</div>
                <ul className='p-p-ul-card'>
                    <li ><span className='p-p-highligt'></span>
                        We take reasonable measures to protect your personal information from unauthorized access and disclosure. However, no online data transmission or storage can be guaranteed to be 100% secure. Therefore, we cannot ensure or warrant the security of any information you transmit to us.
                    </li>

                </ul>


                <div className='p-p-sub-head'>Your Choices</div>
                <span className='p-ul-sub-head'>You may have the following choices regarding your personal information:</span>
                <ul className='p-p-ul-card'>
                    <li ><span className='p-p-highligt'></span>To access or correct your personal information, please contact us. </li>

                </ul>


                <div className='p-p-sub-head'>Children's Privacy </div>
                <ul className='p-p-ul-card'>
                    <li >
                        Our Website is not directed at individuals under the age of 18, and we do not knowingly collect personal information from children.                     </li>

                </ul>

                <div className='p-p-sub-head'>Changes to this Privacy Policy </div>
                <ul className='p-p-ul-card'>
                    <li >
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Updated versions will be posted on our Website
                    </li>

                </ul>

                <div className='p-p-sub-head'>Contact Us </div>
                <ul className='p-p-ul-card'>
                    <li >
                        If you have questions or concerns about this Privacy Policy or our data practices, please contact us at 
                        <a href='https://aicc74.commerce.du.ac.in/' className='a-url'>74aicc@commerce.du.ac.in</a>.                    </li>

                </ul>



            </div>
        </>
    )
}