import React, { useEffect, useRef, useState } from 'react'
import 'primeicons/primeicons.css';
import "./index.scss";
import { useAppSelector } from '@Src/app/hooks/hooks';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { postfeedback, getfeedbackList } from './service/feedback.service';
import { APIResponse, APIStatus } from '@Src/services/bookstore/baseInterfaces';
import { toast } from 'react-hot-toast';
import { useAppDispatch } from '@Src/app/hooks/hooks';
import { setFeedbackstatus } from '@Src/app/reducers/global/globalSlice';
import GreenTickImg from "@Src/assets/images/green-tick-o1.webp"
import { store } from '@Src/app/store';

export const FeedbackIndex = () => {
  const feedback = useAppSelector(state => state.global.CertificateAllow);
  const [problemfaced, setProblemfaced] = useState<any>()
  const [describeides, setDescribeides] = useState<any>()
  const [feedbacklist, setFeedBackList] = useState<any>()
  const [startfeedback, setStartfeedback] = useState(false)
  const dispatch = useAppDispatch()
  const toaster: any = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      showSuccess()
    }, 100)

  }, [feedback === 0])

  useEffect(() => {
    getFeedBackListfromapi()
  }, [])

  const showSuccess = () => {
    toaster.current.show({ severity: 'info', summary: <span className='toast-msg'>Message</span>, detail: feedback === 1 ? <span className='toast-msg'>Feedback Submitted Succesfully</span> : <span className='toast-msg'>Feedback will open after Conference</span>, life: 3000 });
  }

  const feedbackinitialValues: any = {};
  feedbacklist && feedbacklist.forEach((question: any) => {
    feedbackinitialValues[question?.QueId] = ''; // Initialize each question with an empty value
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, validateForm, resetForm } = useFormik({
    initialValues: feedbackinitialValues,
    validationSchema: '',
    onSubmit: (e) => {
      const feedbackdata: any = []
      feedbacklist && feedbacklist.forEach((element: any) => {
        let answerValue = values[element?.QueId];

        if (Array.isArray(answerValue)) {
          // If it's an array (multi-select), join the values with pipe symbol
          answerValue = problemfaced ? answerValue.join('|') + '|' + problemfaced : answerValue.join('|');
        }
        // if(values[element?.QueId] = 39){
        //   element.Answer = describeides;
        // }
        feedbackdata.push({
          'QuestionID': element.QueId,
          "Answer": answerValue ? answerValue : ''
        })
      });
      feedbackdata.forEach((question:any) => {
        if (question.QuestionID == 45) {     
          question.Answer = describeides; 
        }
      })
      console.log(feedbackdata)
      onsubmitfeedbacktoapi(feedbackdata)
    },
  });



  function getFeedBackListfromapi() {
    getfeedbackList().then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        setFeedBackList(resp.data)
      }
    }).catch(error => {
      console.log(error)
    })


  }

  function onsubmitfeedbacktoapi(data: any) {
    let payload = {
      'data': data
    }
    postfeedback(payload).then((resp: APIResponse) => {
      if (resp.ResponseType === APIStatus.SUCCESS) {
        toast.success(resp.StatusMsg)
        dispatch(setFeedbackstatus(1))
      } else {
        toast.error(resp.StatusMsg)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      {feedback === 1 ?
        <div>
          {store.getState().global.feedbackstatus == 0 ? <div>
            <div className='content-heading-1'>
              {startfeedback && <i className="pi pi-arrow-left -back-detail" onClick={() => setStartfeedback(false)}></i>} Event Feedback
            </div>

            {!startfeedback ? <div className='content-form'>
              <div className='ui-text-1'> Dear Guest, please share your valuable feedback with us </div>
              <div className='mt-3'> <a href='javascript:void(0)' className='button-primary active' onClick={() => setStartfeedback(true)}> Start Feedback <i className="pi pi-angle-right ml-1"></i> </a> </div>
            </div>
              :
              <div className='content-form'>
                <form onSubmit={handleSubmit}>
                  <div className='group-form col-row'>
                    {feedbacklist && feedbacklist.map((list: any) => {
                      return (
                        <div>
                          {list?.Type === 1 ?
                            <div className='group-form-row' key={list?.QueId}>
                              <div className='group-form-colls'>
                                <label className='form-label-1'> {list.QueName} </label>
                                <div>
                                  <ul className='ui-radio-lists'>
                                    {list?.Options && list?.Options?.map((opt: any) => {
                                      return (
                                        <li key={opt?.optId}>
                                          <label className='radio-label inline-list-coll'>
                                            <input type='radio'
                                              value={`${opt?.optId}`}
                                              name={`${list?.QueId}`}
                                              onChange={handleChange}
                                            //checked={values.inauguration === Feedback_Response.HIGHLY_SATISFIED}
                                            /> {opt?.optName}
                                          </label>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div> :
                            <div>
                              {(values[31] === '32' && list?.Type === 2) &&
                                <div>
                                  <div className='group-form-row' key={list?.QueId}>
                                    <div className='group-form-colls'>
                                      <label className='form-label-1'> {list.QueName}</label>
                                      <div>
                                        <ul className='ui-checkbox-lists'>
                                          {list?.Options && list?.Options?.map((opt: any) => {
                                            return (
                                              <li key={opt?.optId}>
                                                <label className='checkbox-label inline-list-coll'>
                                                  <input type='checkbox'
                                                    value={`${opt?.optId}`}
                                                    name={`${list?.QueId}`}
                                                    onChange={handleChange}
                                                  />
                                                  {opt?.optName}
                                                </label>
                                              </li>

                                            )
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {(values[34] && values[34].includes('38')) && <div className='group-form-row tw-mb-7'>
                                    <div className='group-form-colls'>
                                      <label className='form-label-1'>Please specify </label>
                                      <div>
                                        <input type='text'
                                          name='problemfaced'
                                          value={problemfaced}
                                          onChange={(e) => setProblemfaced(e.target.value)}
                                          placeholder='Please tell us about the problem you faced'
                                          className='form-control-t1' />
                                      </div>
                                    </div>
                                  </div>}
                                </div>
                              }
                            </div>
                          }
                        </div>
                      )
                    })}
                    {feedbacklist && feedbacklist.map((list: any) => {
                      return (
                        <div>
                          {list?.Type === 3 &&
                            <div className='group-form-row' key={list?.QueId}>
                              <div className='group-form-colls'>
                                <label className='form-label-1'> {list.QueName} </label>
                                <div>
                                  <input type='text'
                                    value={describeides}
                                    name={describeides}
                                    onChange={(e) => setDescribeides(e.target.value)}
                                    // placeholder='Please tell us about the problem you faced'
                                    className='form-control-t1' />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      )
                    })}
                    <div className='group-form-row'>
                      <div className='group-form-footer flex align-items-center mt-4'>
                        <button type='submit' className='button-primary active'> Submit <i className="pi pi-angle-right ml-1"></i> </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>}
          </div>
            :
            <div className="empty-data">
              <div className="empty-data-icon"> <img src={GreenTickImg} /> </div>
              <div className="empty-data-details">
                <h2 className="empty-data-h1"> Your Feedback has been submitted Successful </h2>
                {/* <p className="empty-data-text-2"> Your fees payment has been completed successfully.<br />
                Please proceed to add Travel and Paper Details. </p> */}
                {/* <div> <button className="button-primary active" onClick={() => navigate(AppRouteURL.TRAVEL_DETAILS)}> Continue to Travel Details <i className="pi pi-angle-right ml-1"></i> </button> </div> */}
              </div>
            </div>}
        </div> : <Toast ref={toaster} position="top-center" />}

    </>
  )
}