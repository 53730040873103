import React, {lazy} from "react";
import "./Layout.scss";
import {useAppSelector} from "@App/hooks/hooks";
import AppRoutes from "@Src/routes/route";
import { useParams } from "react-router-dom";


const HeaderIndex = lazy(() =>
    import('@Features/common/header')
        .then(({HeaderIndex}) => ({default: HeaderIndex})),
);
const CustomSidebar = lazy(() =>
    import('@Features/common/sidebar/sidebar')
        .then(({CustomSidebar}) => ({default: CustomSidebar})),
);
const Footer = lazy(() =>
    import('@Src/features/client/footer/footer')
        .then(({Footer}) => ({default: Footer})),
);


const Layout = () => {

    const globalSlice = useAppSelector(state => state.global);
    const params:any = useParams()
    const sidebardisplay = params['*'].includes('auth/register')
     || params['*'].includes('auth/login')|| params['*'].includes('register-success') 
     || params['*'].includes('auth/reset-password') || params['*'].includes('auth/update-password')
     || params['*'].includes('privacy-policy') || params['*'].includes('researchScholarData') 
     || params['*'].includes('donation-sponsorship') || params['*'].includes('donation-payment-success') || params['*'].includes('donationpayment-status')
     
    return (
        <>
                    <div className="layout">
                        <React.Suspense>
                            <HeaderIndex/>
                        </React.Suspense>
                        <div className="layout-container">
                           {!sidebardisplay && <div className="layout-contents-left"> 
                            <React.Suspense>
                                {

                                 <CustomSidebar/>
                                }
                            </React.Suspense>
                            </div>}
                            <div className={globalSlice?.showWebSidebar ? 'layout-contents-right' : 'layout-contents-right collapse-closed'}>
                                <AppRoutes/>
                                <React.Suspense>
                                    {
                                        globalSlice?.showFooter && <Footer/>
                                    }
                                </React.Suspense>
                            </div>
                        </div>
                    </div>

        </>
    );
}

export default Layout;
